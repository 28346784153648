export const STATUS_MAPPING: any = {
    "Processing": "Đang xử lý",
    "Updated": "Đã cập nhật",
    "Completed": "Đã xong"
}

export const bookingStatus = {
    PROCESSING: "Processing",
    CHANGING: "Changing",
    CONFIRM: "Confirmed",
    COMPLETE: "Completed"
}

export const BOOKING_STATUS_MAPPING: any = {
    "Processing": "Đang xử lý",
    "Changing": "Yêu cầu đổi lịch",
    "Confirmed": "Đã xác nhận",
    "Completed": "Đã hoàn thành"
}

export const systemRole = {
    CUSTOMER: "Customer",
    ADMIN: "Admin",
    TECHNICIAN: "Technician",
    COUNSELOR: "Counselor",
    CUSTOMER_CARE: "CustomerCare",
    ACCOUNTANT: "Accountant",
    RECEPTIONIST: "Receptionist",
    BRANCH_ACCOUNTANT: "BranchAccountant",
    BRANCH_MANAGER: "BranchManager",
    MARKETING: "Marketing"
}

export const serviceUserStatus = {
    DOING: {
        code: "Doing",
        name: "Đang làm"
    },
    COMPLETED: {
        code: "Completed",
        name: "Đã hoàn thành"
    },
    CANCELED: {
        code: "Canceled",
        name: "Đã hủy"
    },
    DELETED: {
        code: "Deleted",
        name: "Đã xóa"
    },
}

export const SERVICE_USER_STATUS_MAPPING: any = {
    "Doing": "Đang làm",
    "Completed": "Đã hoàn thành",
    "Canceled": "Đã hủy",
    "Deleted": "Đã xóa"
}

export const roleNameMapping: any = {
    "Customer": "Khách hàng",
    "CustomerCare": "CSKH",
    "Accountant": "Kế toán",
    "Admin": "Admin",
    "Receptionist": "Lễ tân",
    "Doctor": "Bác sĩ",
    "Counselor": "Tư vấn viên",
    "Technician": "Kỹ thuật viên",
    "BranchAccountant": "Kế toán cơ sở",
    "BranchManager": "Giám đốc chi nhánh",
    "Marketing": "Nhân viên marketing"
}

export const GENDER = [
    {
        Id: 0,
        Name: "Nữ"
    },
    {
        Id: 1,
        Name: "Nam"
    }
]

export const MEMBERSHIP_CLASS = [
    {
        Id: "Standard",
        Name: "Standard"
    },
    {
        Id: "VIP",
        Name: "VIP"
    },
    {
        Id: "VVIP",
        Name: "VVIP"
    }
]

export const customerFeedback = {
    HAI_LONG: 1,
    BINH_THUONG: 2,
    KHONG_HAI_LONG: 3
}

export const CUSTOMER_FEEDBACK_DATA = [
    {
        Id: customerFeedback.HAI_LONG,
        Name: "Hài lòng"
    },
    {
        Id: customerFeedback.BINH_THUONG,
        Name: "Bình thường"
    },
    {
        Id: customerFeedback.KHONG_HAI_LONG,
        Name: "Không hài lòng"
    }
]

export const customerFeedbackStatus = {
    CREATED: "Created",
    PROCESSING: "Processing",
    PROCESSED: "Processed"
}

export const CUSTOMER_FEEDBACK_STATUS_MAPPING: any = {
    "Created": "Tạo mới",
    "Processing": "Đang xử lý",
    "Processed": "Đã xử lý"
}

export const CUSTOMER_FEEDBACK_STATUS_DATA = [
    {
        Id: customerFeedbackStatus.CREATED,
        Name: CUSTOMER_FEEDBACK_STATUS_MAPPING[customerFeedbackStatus.CREATED]
    },
    {
        Id: customerFeedbackStatus.PROCESSING,
        Name: CUSTOMER_FEEDBACK_STATUS_MAPPING[customerFeedbackStatus.PROCESSING]
    },
    {
        Id: customerFeedbackStatus.PROCESSED,
        Name: CUSTOMER_FEEDBACK_STATUS_MAPPING[customerFeedbackStatus.PROCESSED]
    }
]

export const MONTH_DATA = [
    {
        Id: 1,
        Name: "Tháng 1",
    },
    {
        Id: 2,
        Name: "Tháng 2",
    },
    {
        Id: 3,
        Name: "Tháng 3",
    },
    {
        Id: 4,
        Name: "Tháng 4",
    },
    {
        Id: 5,
        Name: "Tháng 5",
    },
    {
        Id: 6,
        Name: "Tháng 6",
    },
    {
        Id: 7,
        Name: "Tháng 7",
    },
    {
        Id: 8,
        Name: "Tháng 8",
    },
    {
        Id: 9,
        Name: "Tháng 9",
    },
    {
        Id: 10,
        Name: "Tháng 10",
    },
    {
        Id: 11,
        Name: "Tháng 11",
    },
    {
        Id: 12,
        Name: "Tháng 12",
    },
]

export const NotificationStatus = {
    NEW: 0,
    VIEWED: 1,
    HANDLED: 2
}

export const NotificationType = {
    VIEW: 1,
    INTERACTIVE: 2
}

export const NotificationReferencePage = {
    BOOKING_CREATION: 5,
    BOOKING_CHANGING_REQUEST: 4
}

export const HubChannel = {
    BRANCH_ACCOUNTANT_NOTIFICATION: 'BranchAccountantNotification'
}

export const HubCode = {
    NEW_NOTI: 120
}

export const DASHBOARD_VIEW_MODE = {
    WEEK: 0,
    MONTH: 1,
    QUATER: 2,
    YEAR: 3
}