import { styled } from "@mui/material/styles";
import { COLORS } from "@shared/constants/colors";
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import { ErrorValidation, LabelControl, MenuItemPlaceholder, Title } from "@shared/styledComponents";
import { useNavigate, useParams } from "react-router-dom";
import { HButton } from "@components/Button";
import AddIcon from '@mui/icons-material/Add';
import { apiClient } from "@services/baseAxios";
import { ApiConfig } from "@config/index";
import { useDispatch } from "react-redux";
import { storeOpenSnackbar, storeSetGlobalLoading } from "@store/globalReducer";
import { useEffect, useState } from "react";
import { errorMessages } from "@shared/constants/messages";
import { a11yProps, currencyFormat, generateUUID } from "@shared/helpers/common";
import { ReactComponent as SwitchIcon } from '@assets/icons/icon-switch.svg';
import { Box, FormControl, Grid, MenuItem } from "@mui/material";
import { HTab, HTabs, TabPanel } from "@components/Tab";
import { HAutocomplete } from "@components/Autocomplete";
import { HTextField } from "@components/Input/HTextFiled";
import { ReactComponent as VNDIcon } from '@assets/icons/icon-vnd.svg';
import { HSelect } from "@components/Select";
import { storeSetAllProduct, storeSetAllService, storeSetSpaLocations } from "@store/directoryReducer";
import { useAppSelector } from "@hooks/useSelector/useAppSelector";
import { systemRole } from "@shared/constants/common";
import { sharedStyles } from "@shared/styles/common";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { HDialog } from "@components/Dialog";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const HeaderWrapper = styled('div')(() => ({
    width: '100%',
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: COLORS.White
}));

const BodyWrapper = styled('div')(() => ({
    marginTop: '16px',
    width: '100%',
    overflow: 'auto',
    flexGrow: '1'
}));

const SwitchBlock = styled('div')(() => ({
    padding: '16px',
    width: '100%',
    backgroundColor: COLORS.White,
    borderRadius: '8px',
}));

const PRODUCT_SERVICE_LIST = [
    {
        Id: 1,
        Name: "Sản phẩm"
    },
    {
        Id: 2,
        Name: "Dịch vụ"
    }
]

const CONVERSION_TYPE = {
    NEW_SERVICE_OR_PRODUCT: 0,
    OLD_PURCHASED_SERVICE: 1
}

export const SwtichServiceUser = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { spaLocations, allService, allProduct } = useAppSelector((state: any) => state.directory);
    var { tokenClaims } = useAppSelector(state => state.auth);
    const isBranchAccountant = tokenClaims.role === systemRole.BRANCH_ACCOUNTANT;
    const { id } = useParams();
    const [allPurchasedServiceOfCustomer, setAllPurchasedServiceOfCustomer] = useState([]);
    const [serviceUser, setServiceUser] = useState({} as any);
    const [listConversion, setListConversion] = useState<any>([]);
    const [isOpenConfirmDeleteDialog, setIsOpenConfirmDeleteDialog] = useState(false);
    const [selectedConversionBlock, setSelectedConversionBlock] = useState({ id: '' });
    const [totalConversionMoney, setTotalConversionMoney] = useState(0);

    const handleConversion = async () => {
        if (!listConversion || listConversion.length === 0) {
            dispatch(storeOpenSnackbar({ open: true, message: "Vui lòng chọn ít nhất 1 sản phẩm/ dịch vụ muốn nhận số tiền chuyển đổi", type: "error" }));
            return;
        }

        // Validate phải nhập đủ các thông tin cần thiết
        for (let item of listConversion) {
            // eslint-disable-next-line eqeqeq
            if (item.type == CONVERSION_TYPE.NEW_SERVICE_OR_PRODUCT) {
                if (!item.selectedType || item.selectedType === "-1" || !item.selectedDataByType || !item.newPriceOfProductOrService || !item.conversionMoneyFromOldService || !item.selectedLocationId || item.selectedLocationId === "-1") {
                    dispatch(storeOpenSnackbar({ open: true, message: "Vui lòng nhập đủ thông tin", type: "warning" }));
                    return;
                }

                // Validate số tiền chuyển đôi
                if (parseInt(item.conversionMoneyFromOldService.replace(/\D/g, '')) > parseInt(item.newPriceOfProductOrService.replace(/\D/g, ''))) {
                    dispatch(storeOpenSnackbar({ open: true, message: "Số tiền chuyển đổi không hợp lệ", type: "warning" }));
                    return;
                }
            }
            // eslint-disable-next-line eqeqeq
            else if (item.type == CONVERSION_TYPE.OLD_PURCHASED_SERVICE) {
                if (!item.purchasedService || !item.conversedMoney) {
                    dispatch(storeOpenSnackbar({ open: true, message: "Vui lòng nhập đủ thông tin", type: "warning" }));
                    return;
                }

                // Validate số tiền chuyển đổi
                let purchasedServicePrice = item.purchasedService.Price;
                let purchasedServiceActualPaidAmount = item.purchasedService.ActualPaidAmount;
                let conversedMoney = parseInt(item.conversedMoney.replace(/\D/g, ''));
                if (conversedMoney > purchasedServicePrice - purchasedServiceActualPaidAmount) {
                    dispatch(storeOpenSnackbar({ open: true, message: "Số tiền chuyển đổi không hợp lệ", type: "warning" }));
                    return;
                }
            }
        }

        // Validate tổng số tiền chuyển đổi phải nhỏ hơn Số tiền đã thanh toán của dịch vụ hiện tại
        if (totalConversionMoney > serviceUser.ActualPaidAmount) {
            dispatch(storeOpenSnackbar({ open: true, message: "Số tiền chuyển đổi phải nhỏ hơn hoặc bằng số tiền đã thanh toán", type: "error" }));
            return;
        }


        // Thực hiện lưu vào DB
        let obj = {
            ServiceSenderId: serviceUser.Id,
            ConversionAmount: totalConversionMoney,
            ListCreateNew: [] as any,
            ListExisting: [] as any
        }

        for (const item of listConversion) {
            // eslint-disable-next-line eqeqeq
            if (item.type == CONVERSION_TYPE.NEW_SERVICE_OR_PRODUCT) {
                obj.ListCreateNew.push({
                    Type: item.selectedType === 1 ? 1 : 0,
                    ServiceProductId: item.selectedDataByType.Id,
                    UserId: serviceUser.UserId,
                    Price: parseInt(item.newPriceOfProductOrService.replace(/\D/g, '')),
                    ConversionAmount: parseInt(item.conversionMoneyFromOldService.replace(/\D/g, '')),
                    LocationId: item.selectedLocationId
                });
            }
            // eslint-disable-next-line eqeqeq
            else if (item.type == CONVERSION_TYPE.OLD_PURCHASED_SERVICE) {
                obj.ListExisting.push({
                    ServiceUserId: item.purchasedService.Id,
                    ConversionAmount: parseInt(item.conversedMoney.replace(/\D/g, ''))
                })
            }
        }

        try {
            dispatch(storeSetGlobalLoading(true));
            var { AppCode, Message } = await apiClient.post(`${ApiConfig.PAYMENT}/service-conversion`, obj);
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                // Clear list conversion
                setListConversion([]);

                // Get lại dữ liệu Service User
                getCurrentServiceUser();

                // Thông báo thành công
                dispatch(storeOpenSnackbar({ open: true, message: "Xử lý chuyển đổi thành công", type: "success" }));
            }
            else if (AppCode === 500) {
                dispatch(storeOpenSnackbar({ open: true, message: Message, type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: Message, type: "error" }));
            }
        } catch (error: any) {
            dispatch(storeSetGlobalLoading(false));
            if (error?.response?.data?.AppCode === 500) {
                dispatch(storeOpenSnackbar({ open: true, message: error?.response?.data?.Message, type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        }
    }

    const closeConfirmDeleteDialog = () => {
        setIsOpenConfirmDeleteDialog(false);
    }

    const openConfirmDeleteDialog = (e: any) => {
        setSelectedConversionBlock(e);
        setIsOpenConfirmDeleteDialog(true);
    }

    const setup = async () => {
        await getCurrentServiceUser();
        getSpaLocation();
        getAllProduct();
        getAllService();
    }

    const getAllProduct = async () => {
        if (allProduct?.length > 0) return;

        const res = await apiClient.get(ApiConfig.GET_ALL_PRODUCT);
        if (res?.AppCode === 200) {
            dispatch(storeSetAllProduct(res.Data));
        }
    }

    const getAllService = async () => {
        if (allService?.length > 0) return;

        const res = await apiClient.get(ApiConfig.SERVICE);
        if (res?.AppCode === 200) {
            dispatch(storeSetAllService(res.Data));
        }
    }

    const getSpaLocation = async () => {
        if (spaLocations?.length > 0) return;

        const res = await apiClient.get(ApiConfig.GET_ALL_SPA_LOCATION);
        if (res?.AppCode === 200) {
            dispatch(storeSetSpaLocations(res.Data));
        }
    }

    // Lấy tất cả dịch vụ đã mua và đang làm của khách hàng cho dịch vụ được chọn
    const getAllPurchasedServiceOfCustomer = async () => {
        const { AppCode, Data } = await apiClient.post(ApiConfig.GET_SERVICE_USER_BY_USER, {
            UserId: serviceUser.UserId,
            IsOnlyDoing: true,
            IsPaging: false,
            Keyword: null
        });

        if (AppCode === 200) {
            setAllPurchasedServiceOfCustomer(Data);
        }
    }

    const getCurrentServiceUser = async () => {
        try {
            dispatch(storeSetGlobalLoading(true));
            const { AppCode, Data } = await apiClient.get(`${ApiConfig.SERVICE_USER}/${id}`);
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                setServiceUser(Data);
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        } catch (error) {
            dispatch(storeSetGlobalLoading(false));
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    const handleAddNewConversion = () => {
        let id = generateUUID();
        let selectedLocationId = tokenClaims.location_id || "-1";

        setListConversion([
            ...listConversion,
            { id: id, selectedLocationId }
        ]);
    }

    const onDeleteConversionBlock = () => {
        const newList = listConversion.filter((e: any) => e.id !== selectedConversionBlock.id);
        setListConversion([
            ...newList,
        ]);
        closeConfirmDeleteDialog();
    }

    const onUpdateConversionItem = (item: any) => {
        let newList = listConversion;
        var index = listConversion.findIndex((e: any) => e.id === item.id);
        newList[index] = item;
        setListConversion([
            ...newList
        ]);
    }

    const SwitchBlockCpn = ({ onDelete, conversionItem, onUpdateConversionItem }: any) => {
        const [tabValue, setTabValue] = useState(0);
        const [newPriceOfProductOrService, setNewPriceOfProductOrService] = useState('');
        const [conversedMoney, setConversedMoney] = useState('');
        const [conversionMoneyFromOldService, setConversionMoneyFromOldService] = useState('');
        const [disabledTab, setDisabledTab] = useState(-1);

        const [data, setData] = useState({} as any);

        const handleOnChangeDataByType = (e: React.SyntheticEvent, value: any) => {
            onUpdateConversionItem(
                {
                    ...data,
                    selectedDataByType: value,
                    type: CONVERSION_TYPE.NEW_SERVICE_OR_PRODUCT
                });
        }

        const handleOnChangePurchasedService = (e: React.SyntheticEvent, value: any) => {
            onUpdateConversionItem(
                {
                    ...data,
                    purchasedService: value,
                    type: CONVERSION_TYPE.OLD_PURCHASED_SERVICE
                });
        }

        const handleChangeLocation = (event: { target: { value: string } }) => {
            onUpdateConversionItem(
                {
                    ...data,
                    selectedLocationId: event.target.value,
                    type: CONVERSION_TYPE.NEW_SERVICE_OR_PRODUCT
                });
        };

        const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
            setTabValue(newValue);
        };

        const handleChangeType = (event: { target: { value: string } }) => {
            const type = event.target.value;
            const lstDataByType = type.toString() === "1" ? allProduct : allService;
            onUpdateConversionItem(
                {
                    ...data,
                    selectedType: type,
                    listDataByType: lstDataByType,
                    selectedDataByType: null,
                    type: CONVERSION_TYPE.NEW_SERVICE_OR_PRODUCT
                });
        };

        const handleTextInputChange = (event: any, field: string) => {
            let value = event.target.value;

            if (field === 'new-price' || field === 'conversed-money' || field === 'money-from-old-service') {
                const a = value.replace(/\D/g, "");
                value = currencyFormat(parseInt(a)).toString();
            }

            switch (field) {
                case 'new-price':
                    setNewPriceOfProductOrService(value);
                    break;
                case 'conversed-money':
                    setConversedMoney(value);
                    break;
                case 'money-from-old-service':
                    setConversionMoneyFromOldService(value);
                    break;
            }
        }

        const renderOption = (props: any, option: any) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.Name}
            </Box>
        );

        const renderOptionForPurchasedService = (props: any, option: any) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.ServiceName}
            </Box>
        );

        useEffect(() => {
            // binding();
            setData(conversionItem);

            setNewPriceOfProductOrService(conversionItem.newPriceOfProductOrService);
            setConversionMoneyFromOldService(conversionItem.conversionMoneyFromOldService);
            setConversedMoney(conversionItem.conversedMoney);

            if ((conversionItem.selectedType && conversionItem.selectedType !== "-1") || conversionItem.selectedDataByType || conversionItem.newPriceOfProductOrService || conversionItem.conversionMoneyFromOldService) {
                setDisabledTab(2);
                setTabValue(0);
            }
            else if (conversionItem.purchasedService || conversionItem.conversedMoney) {
                setDisabledTab(1);
                setTabValue(1);
            }
            else {
                setDisabledTab(-1);
                setTabValue(0);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return (
            <SwitchBlock>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <HTabs
                        value={tabValue}
                        onChange={handleChangeTab}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                    >
                        <HTab {...a11yProps(0)} disabled={disabledTab === 1} label="Tạo mới sản phẩm/ dịch vụ" />
                        <HTab {...a11yProps(1)} disabled={disabledTab === 2} label="Dịch vụ đã mua" />
                    </HTabs>
                    <CloseOutlinedIcon onClick={onDelete} className="hover:cursor-pointer" />
                </Box>
                <Box sx={{ width: '100%', marginTop: '16px' }}>
                    <TabPanel value={tabValue} index={0}>
                        <Grid container>
                            {/* Row 1 */}
                            <Grid sx={{ pt: 0, pr: 1 }} item xs={6}>
                                <FormControl fullWidth variant="standard">
                                    <LabelControl required>Sản phẩm/ dịch vụ</LabelControl>
                                    <HSelect fullWidth value={data.selectedType || "-1"}
                                        onChange={handleChangeType}>
                                        <MenuItem key={-1} disabled value="-1">
                                            <MenuItemPlaceholder>Chọn sản phẩm/ dịch vụ</MenuItemPlaceholder>
                                        </MenuItem>
                                        {
                                            PRODUCT_SERVICE_LIST.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                                        }
                                    </HSelect>
                                </FormControl>
                            </Grid>
                            <Grid sx={{ pt: 0, pl: 1 }} item xs={6}>
                                <FormControl fullWidth variant="standard">
                                    <LabelControl required>Tên sản phẩm/ dịch vụ</LabelControl>
                                    <HAutocomplete
                                        value={data.selectedDataByType || null}
                                        placeholder="Tìm kiếm tên sản phẩm/ dịch vụ"
                                        options={data.listDataByType}
                                        getOptionLabel={(option: any) => `${option.Name}`}
                                        renderOption={renderOption}
                                        onChange={handleOnChangeDataByType}
                                    />
                                </FormControl>
                            </Grid>
                            {/* Row 2 */}
                            <Grid sx={{ pt: 2, pr: 1 }} item xs={6}>
                                <FormControl fullWidth variant="standard">
                                    <LabelControl required>Giá bán sản phẩm/ dịch vụ</LabelControl>
                                    <HTextField
                                        fullWidth
                                        value={newPriceOfProductOrService}
                                        onChange={(event: any) => handleTextInputChange(event, 'new-price')}
                                        placeholder="Nhập giá bán sản phẩm/ dịch vụ"
                                        iconEnd={<VNDIcon />}
                                        onBlur={(e: any) => {
                                            onUpdateConversionItem(
                                                {
                                                    ...data,
                                                    newPriceOfProductOrService: newPriceOfProductOrService,
                                                    type: CONVERSION_TYPE.NEW_SERVICE_OR_PRODUCT
                                                });
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid sx={{ pt: 2, pl: 1 }} item xs={6}>
                                <FormControl fullWidth variant="standard">
                                    <LabelControl required>Số tiền chuyển đổi từ dịch vụ cũ</LabelControl>
                                    <HTextField
                                        fullWidth
                                        sx={(conversionMoneyFromOldService && newPriceOfProductOrService && parseInt(conversionMoneyFromOldService.replace(/\D/g, '')) > parseInt(newPriceOfProductOrService.replace(/\D/g, ''))) ? sharedStyles.borderError : {}}
                                        value={conversionMoneyFromOldService}
                                        onChange={(event: any) => handleTextInputChange(event, 'money-from-old-service')}
                                        placeholder="Nhập số tiền chuyển đổi từ dịch vụ cũ"
                                        iconEnd={<VNDIcon />}
                                        onBlur={(e: any) => {
                                            onUpdateConversionItem(
                                                {
                                                    ...data,
                                                    conversionMoneyFromOldService: conversionMoneyFromOldService,
                                                    type: CONVERSION_TYPE.NEW_SERVICE_OR_PRODUCT
                                                });
                                        }}
                                    />
                                    {
                                        conversionMoneyFromOldService && newPriceOfProductOrService &&
                                        (parseInt(conversionMoneyFromOldService.replace(/\D/g, '')) > parseInt(newPriceOfProductOrService.replace(/\D/g, '')))
                                        &&
                                        <ErrorValidation>Số tiền chuyển đổi phải nhỏ hơn hoặc bằng giá bán</ErrorValidation>
                                    }
                                </FormControl>
                            </Grid>
                            {/* Row 3 */}
                            <Grid sx={{ pt: 2, pr: 1 }} item xs={6}>
                                <FormControl fullWidth variant="standard">
                                    <LabelControl required>Cơ sở</LabelControl>
                                    <HSelect disabled={isBranchAccountant} fullWidth value={data.selectedLocationId || "-1"}
                                        onChange={handleChangeLocation}>
                                        <MenuItem key={-1} disabled value="-1">
                                            <MenuItemPlaceholder>Chọn cơ sở</MenuItemPlaceholder>
                                        </MenuItem>
                                        {
                                            spaLocations.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                                        }
                                    </HSelect>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <Grid container>
                            {/* Row 1 */}
                            <Grid sx={{ pt: 0, pr: 1 }} item xs={6}>
                                <FormControl fullWidth variant="standard">
                                    <LabelControl required>Tên dịch vụ</LabelControl>
                                    <HAutocomplete
                                        value={data.purchasedService || null}
                                        placeholder="Tìm kiếm tên dịch vụ"
                                        options={allPurchasedServiceOfCustomer}
                                        getOptionLabel={(option: any) => `${option.ServiceName}`}
                                        renderOption={renderOptionForPurchasedService}
                                        onChange={handleOnChangePurchasedService}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid sx={{ pt: 0, pl: 1 }} item xs={6}>
                                <FormControl fullWidth variant="standard">
                                    <LabelControl required>Giá bán dịch vụ</LabelControl>
                                    <HTextField
                                        disabled
                                        fullWidth
                                        value={data.purchasedService ? currencyFormat(data.purchasedService.Price) : 0}
                                        placeholder="0"
                                        iconEnd={<VNDIcon />}
                                    />
                                </FormControl>
                            </Grid>
                            {/* Row 2 */}
                            <Grid sx={{ pt: 2, pr: 1 }} item xs={6}>
                                <FormControl fullWidth variant="standard">
                                    <LabelControl required>Số tiền đã thanh toán</LabelControl>
                                    <HTextField
                                        disabled
                                        fullWidth
                                        value={data.purchasedService ? currencyFormat(data.purchasedService.ActualPaidAmount) : 0}
                                        placeholder="0"
                                        iconEnd={<VNDIcon />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid sx={{ pt: 2, pl: 1 }} item xs={6}>
                                <FormControl fullWidth variant="standard">
                                    <LabelControl required>Số tiền chuyển sang</LabelControl>
                                    <HTextField
                                        fullWidth
                                        sx={(data.purchasedService && conversedMoney && parseInt(conversedMoney.replace(/\D/g, '')) > (data.purchasedService.Price - data.purchasedService.ActualPaidAmount)) ? sharedStyles.borderError : {}}
                                        value={conversedMoney}
                                        onChange={(event: any) => handleTextInputChange(event, 'conversed-money')}
                                        placeholder="Nhập số tiền chuyển đổi từ dịch vụ cũ"
                                        iconEnd={<VNDIcon />}
                                        onBlur={(e: any) => {
                                            onUpdateConversionItem(
                                                {
                                                    ...data,
                                                    conversedMoney: conversedMoney,
                                                    type: CONVERSION_TYPE.OLD_PURCHASED_SERVICE
                                                });
                                        }}
                                    />
                                    {
                                        (data.purchasedService && conversedMoney && parseInt(conversedMoney.replace(/\D/g, '')) > (data.purchasedService.Price - data.purchasedService.ActualPaidAmount)) &&
                                        <ErrorValidation>Số tiền chuyển sang phải nhỏ hơn hoặc bằng công nợ còn lại</ErrorValidation>
                                    }
                                </FormControl>
                            </Grid>
                        </Grid>
                    </TabPanel>
                </Box>
            </SwitchBlock>
        )
    }

    useEffect(() => {
        setup();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (serviceUser.UserId) {
            getAllPurchasedServiceOfCustomer();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceUser.UserId])

    useEffect(() => {
        let newTotalAmount = 0;
        for (let item of listConversion) {
            if (item.conversionMoneyFromOldService) {
                newTotalAmount += parseInt(item.conversionMoneyFromOldService.replace(/\D/g, ''));
            }
            else if (item.conversedMoney) {
                newTotalAmount += parseInt(item.conversedMoney.replace(/\D/g, ''));
            }
        }
        setTotalConversionMoney(newTotalAmount);
    }, [listConversion])

    return (
        <div className="flex flex-col h-full">
            <HeaderWrapper>
                <div className="w-full flex justify-between items-center">
                    <div className='flex'>
                        <ChevronLeftOutlinedIcon onClick={() => navigate(-1)} className='cursor-pointer' />
                        <Title className='ml-1'>Thông tin chuyển đổi</Title>
                    </div>
                    <HButton btnType="secondary" startIcon={<AddIcon />} onClick={handleAddNewConversion} >Thêm sản phẩm/ dịch vụ</HButton>
                </div>
                <div className="mt-5 text-sm">
                    <div>
                        <span style={styles.textTitle} >Khách hàng: </span><span className="font-bold">{serviceUser.BuyerName}</span>
                        <span className="mx-3">/</span>
                        <span style={styles.textTitle}>Số điện thoại: </span><span>{serviceUser.BuyerPhoneNumber}</span>
                        <span className="mx-3">/</span>
                        <span style={styles.textTitle}>Hạng khách hàng: </span><span style={{ color: COLORS.Tertiary }}>{serviceUser.BuyerMembershipClass || "Standard"}</span>
                    </div>
                    <div className="mt-2">
                        <span style={styles.textTitle}>Dịch vụ: </span><span>{serviceUser.ServiceName}</span>
                        <span className="mx-3">/</span>
                        <span style={styles.textTitle}>Giá bán: </span><span className="font-bold">{currencyFormat(serviceUser.Price) || 0}đ</span>
                        <span className="mx-3">/</span>
                        <span style={styles.textTitle}>Số tiền đã thanh toán: </span><span style={{ color: COLORS.MagicPotion }}>{currencyFormat(serviceUser.ActualPaidAmount) || 0}đ</span>
                        <span className="mx-3">/</span>
                        <span style={styles.textTitle}>Số tiền chuyển đổi: </span><span style={{ color: COLORS.Dandelion }}>{currencyFormat(totalConversionMoney) || 0}đ</span>
                    </div>
                </div>
                <div className="mt-4">
                    <HButton startIcon={<SwitchIcon />} onClick={handleConversion} >Xử lý chuyển đổi</HButton>
                </div>
            </HeaderWrapper>
            <BodyWrapper>
                {listConversion?.length > 0 &&
                    listConversion.map((e: any) => (
                        <div className="mb-4">
                            <SwitchBlockCpn onDelete={() => openConfirmDeleteDialog(e)} conversionItem={e} key={e.id || 1} onUpdateConversionItem={onUpdateConversionItem}></SwitchBlockCpn>
                        </div>
                    ))
                }
            </BodyWrapper>
            {/* Popup confirm xóa user */}
            <HDialog
                PaperProps={{
                    sx: {
                        width: "450px",
                    }
                }}
                title="Xác nhận xóa"
                open={isOpenConfirmDeleteDialog}
                handleClose={closeConfirmDeleteDialog}
                dialogFooter={
                    <>
                        <HButton startIcon={<CloseOutlinedIcon />} btnType="secondary" onClick={closeConfirmDeleteDialog}>Hủy</HButton>
                        <HButton startIcon={<DeleteOutlineIcon />} onClick={onDeleteConversionBlock}>Xóa</HButton>
                    </>
                }
            >
                Bạn chắc chắn muốn xoá thông tin này?
            </HDialog>
        </div>
    )
}

const styles = {
    textTitle: {
        color: COLORS.Gray
    }
}