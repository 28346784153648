import { styled } from '@mui/material/styles';
import { Badge, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar } from "@mui/material";
import { COLORS } from '@shared/constants/colors';
import { images } from '@assets/imgs';
import { ReactComponent as Logo } from '@assets/icons/logo.svg';
import SvgIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useContext, useEffect, useRef, useState } from 'react';
import { ReactComponent as UserIcon } from '@assets/icons/icon-user.svg';
import { ReactComponent as LogoutIcon } from '@assets/icons/icon-logout.svg';
import { routes } from '@shared/constants/routeNames';
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from '@hooks/useDispatch/useAppDispatch';
import { useAppSelector } from "@hooks/useSelector/useAppSelector";
import { logout } from '@store/authReducer';
import { useOutside } from '@hooks/useClickOut';
import { HubChannel, HubCode, roleNameMapping, systemRole } from '@shared/constants/common';
import { HNotification } from '@components/Notification';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { apiClient } from '@services/baseAxios';
import { ApiConfig } from '@config/index';
import { storeOpenSnackbar, storeSetNewNotificationCount } from '@store/globalReducer';
import { SignalRContext } from '@contexts/SignalRContext';

const ToolbarWrapper = styled(Toolbar, { shouldForwardProp: (prop) => prop !== 'open' })(
    () => ({
        backgroundColor: COLORS.White,
        boxShadow: 'none',
        height: '68px',
        justifyContent: 'space-between',
    }),
);

const UserInfor = styled('div')(() => ({
    color: COLORS.Primary,
    float: 'right',
}));

const RightSection = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center'
}));

const ToolbarDropdown = ({ toggleHandler, ...props }: any) => {
    const StyledDropdown = styled('div')(() => ({
        position: 'absolute',
        backgroundColor: COLORS.White,
        top: 40,
        right: 20,
        borderRadius: '12px',
        zIndex: 5,
        boxShadow: '0px 2px 15px rgba(51, 51, 51, 0.15)'
    }));

    const wrapperRef = useRef(null);
    useOutside(wrapperRef, toggleHandler);

    return <StyledDropdown ref={wrapperRef}>{props.children}</StyledDropdown>
}

const NotificationWrapper = ({ toggleHandler, ...props }: any) => {
    const StyledNotificationWrapper = styled('div')(() => ({
        position: 'absolute',
        backgroundColor: COLORS.White,
        color: COLORS.Primary,
        top: '55px',
        right: '188px',
        width: '500px',
        height: '488px',
        zIndex: 5,
        boxShadow: '0px 2px 15px rgba(51, 51, 51, 0.15)'
    }));

    const wrapperRef = useRef(null);
    useOutside(wrapperRef, toggleHandler);

    return <StyledNotificationWrapper ref={wrapperRef}>{props.children}</StyledNotificationWrapper>
}

const NotificationSection = styled('div')(() => ({
    marginRight: '16px'
}));

const AvatarSection = styled('div')(() => ({
}));

const logoutItemIndex = 3;
const menu = [
    {
        index: 1,
        title: "Thông tin tài khoản",
        icon: UserIcon,
        route: routes.USER_INFO
    },
    {
        index: logoutItemIndex,
        title: "Đăng xuất",
        icon: LogoutIcon,
    }
];

export const AppToolbar = (props: any) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const connection = useContext(SignalRContext);

    var { tokenClaims } = useAppSelector(state => state.auth);
    var { newNotificationCount } = useAppSelector(state => state.global);
    const [open, setOpen] = useState(false);
    const [openNotify, setOpenNotify] = useState(false);

    const toggleNotify = () => {
        setOpenNotify(!openNotify)
    }

    const toggleDropdown = () => {
        setOpen(!open)
    }

    const onClickItem = (item: any) => {
        setOpen(!open);

        if (item.index === logoutItemIndex) {
            dispatch(logout());
            localStorage.clear();
            navigate(routes.LOGIN);
        }
        else if (item.route) {
            navigate(item.route);
        }
    }

    const getNewNotificationCountOfUser = async () => {
        try {
            var currentUserId = tokenClaims.user_id;
            var { AppCode, Data } = await apiClient.get(`${ApiConfig.NOTIFICATION}/user/${currentUserId}/new-notification-count`);
            if (AppCode === 200) {
                dispatch(storeSetNewNotificationCount(Data));
            }
            else {
                dispatch(storeSetNewNotificationCount(0));
            }
        } catch (error) {
            dispatch(storeSetNewNotificationCount(0));
            console.log(error);
        }
    }

    useEffect(() => {
        getNewNotificationCountOfUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (connection) {
            connection
                .start()
                .then(() => {
                    connection.on("ReceiveMessage", (message: any) => {
                        console.log("ReceiveMessage", message);

                        var { hubCode } = message;
                        if (hubCode === HubCode.NEW_NOTI) {
                            getNewNotificationCountOfUser();
                            dispatch(storeOpenSnackbar({ open: true, message: `Bạn có thông báo mới`, type: "success" }));
                        }
                    });

                    // Nếu là role Kế toán cơ sở thì join kênh để nhận thông báo từ server
                    if (tokenClaims.role === systemRole.BRANCH_ACCOUNTANT) {
                        connection.send("JoinChannel", {
                            UserId: tokenClaims.user_id,
                            GroupName: `${HubChannel.BRANCH_ACCOUNTANT_NOTIFICATION}_${tokenClaims.location_id}`
                        })
                    }
                })
                .catch((error) => console.log(error));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connection, tokenClaims.role, tokenClaims.user_id]);

    return (
        <ToolbarWrapper {...props}>
            <div className='flex'>
                <SvgIcon sx={{ width: 24, height: 24, marginRight: '8px' }} component={Logo} inheritViewBox />
                <img style={{ height: '24px' }} src={images.brand} alt="brand" />
            </div>
            <RightSection>
                <NotificationSection>
                    <Badge color="secondary" badgeContent={newNotificationCount} max={999}>
                        <NotificationsIcon onClick={() => toggleNotify()} sx={{ cursor: "pointer", color: COLORS.YankeesBlue }} />
                    </Badge>
                    {
                        openNotify &&
                        <NotificationWrapper toggleHandler={() => toggleNotify()}>
                            <HNotification onClose={() => { setOpenNotify(false) }}></HNotification>
                        </NotificationWrapper>
                    }
                </NotificationSection>
                <AvatarSection>
                    <img src={images.hkapp} style={{ marginRight: '8px', width: '44px' }} alt="" />
                </AvatarSection>
                <UserInfor>
                    <div style={styles.contentHorizontalCenter}>
                        <span>{tokenClaims.full_name}</span>
                        <ExpandMoreIcon onClick={() => toggleDropdown()} sx={{ cursor: 'pointer', marginLeft: '4px', color: COLORS.Secondary }} />
                        {open &&
                            <ToolbarDropdown toggleHandler={() => toggleDropdown()}>
                                {menu.map((item) => (
                                    <ListItem key={item.title} disablePadding sx={{ display: 'block' }}>
                                        <ListItemButton
                                            sx={{
                                                minHeight: 48,
                                                px: '24px',
                                                '&:hover': {
                                                    backgroundColor: COLORS.Cultured,
                                                },
                                                borderBottom: item.index < menu.length ? `1px solid pink` : 'none'
                                            }}
                                            onClick={() => onClickItem(item)}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <SvgIcon sx={styles.iconMenu} component={item.icon} inheritViewBox />
                                            </ListItemIcon>
                                            <ListItemText className='pl-3' primary={item.title} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </ToolbarDropdown>
                        }
                    </div>
                    <div style={{ color: COLORS.Gray, fontSize: '12px', display: 'flex', justifyContent: 'flex-start', marginTop: '2px' }}>{tokenClaims.phone_number} - {roleNameMapping[tokenClaims.role]}</div>
                </UserInfor>
            </RightSection>
        </ToolbarWrapper>
    )
}

const styles = {
    contentHorizontalCenter: {
        display: 'flex',
        justifyContent: 'center'
    },
    iconMenu: {
        fontSize: '16px'
    }
}

