import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '@shared/constants/keyStore';
import axios from 'axios';
import { routes } from '@shared/constants/routeNames';
import { history } from '../App'

export const httpClient = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
    timeout: 50000,
    headers: {
        "Access-Control-Allow-Origin": "*",
    },
    withCredentials: true,
});

httpClient.interceptors.request.use(
    async (config: any) => {
        const token = await localStorage.getItem(ACCESS_TOKEN_KEY);

        config.headers.Authorization = `Bearer ${token}`;

        return config;
    },
    error => {
        Promise.reject(error)
    });

httpClient.interceptors.response.use(
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    function (response) {
        return response;
    },
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    async function (error) {
        const originalConfig = error.config;
        if (error?.response?.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;

            // Handle refresh token 
            const token = localStorage.getItem(ACCESS_TOKEN_KEY);
            const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
            if (!refreshToken) {
                logout();
                return Promise.reject(error);
            }

            try {
                const res = await httpClient.post("/auths/refresh-token", { AccessToken: token, RefreshToken: refreshToken });
                if (res?.data?.Success) {
                    localStorage.setItem(ACCESS_TOKEN_KEY, res.data.Data.Token);
                    localStorage.setItem(REFRESH_TOKEN_KEY, res.data.Data.RefreshToken);
                    originalConfig.headers['Content-Type'] = 'application/json';
                    return httpClient(originalConfig);
                }
                else {
                    logout();
                }
            } catch (_error: any) {
                if (_error.response && _error.response.data) {
                    logout();
                }
            }
        }
        if (error?.response?.status === 403 && !originalConfig._retry) {
            originalConfig._retry = true;
            history.replace(routes.FORBIDDEN);
        }

        return Promise.reject(error);
    }
);

const logout = async () => {
    history.replace(routes.LOGIN);
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
}


export const apiClient = {
    get: (url: string, params?: any) => httpClient.get(url, { params }).then((res) => res.data),
    post: (url: string, params?: any, configs?: any) =>
        httpClient.post(url, params, configs).then((res: any) => res.data),
    put: (url: string, params?: any) => httpClient.put(url, params).then((res: any) => res.data),
    patch: (url: string, params?: any) => httpClient.patch(url, params).then((res: any) => res.data),
    delete: (url: string, params?: any) =>
        httpClient.delete(url, { params }).then((res: any) => res.data)
}