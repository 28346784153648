import { HButton } from "@components/Button"
import { HDialog } from "@components/Dialog"
import { FormControl, Grid } from "@mui/material"
import { ErrorValidation, LabelControl } from "@shared/styledComponents";
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useState } from "react";
import { HTextField } from "@components/Input/HTextFiled";
import { sharedStyles } from "@shared/styles/common";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import OtpInput from 'react-otp-input';
import { COLORS } from "@shared/constants/colors";
import { apiClient } from "@services/baseAxios";
import { storeOpenSnackbar, storeSetGlobalLoading } from "@store/globalReducer";
import { ApiConfig } from "@config/index";
import { useDispatch } from "react-redux";

export const CustomerAccountCreationForm = ({ isOpen, handleOnClose, handleOnConfirm }: any) => {
    const dispatch = useDispatch();

    const [isTouch, setIsTouch] = useState(false);
    const [fullNameInput, setFullNameInput] = useState('');
    const [phoneNumberInput, setPhoneNumberInput] = useState('');
    const [passwordInput, setPasswordInput] = useState('');
    const [passwordAgainInput, setPasswordAgainInput] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordAgain, setShowPasswordAgain] = useState(false);
    const [otp, setOtp] = useState('');
    const [isConfirmingOtp, setIsConfirmingOtp] = useState(false);

    const toggleDisplayPasswordAgain = () => {
        setShowPasswordAgain(!showPasswordAgain);
    }

    const toggleDisplayPassword = () => {
        setShowPassword(!showPassword);
    }

    const onRegisterUser = async () => {
        try {
            var httpConfig = {
                headers: {
                    'verificationToken': process.env.REACT_APP_VERIFICATION_TOKEN
                }
            }
            const model = { PhoneNumber: phoneNumberInput, Password: passwordInput, FullName: fullNameInput }
            dispatch(storeSetGlobalLoading(true));
            const res = await apiClient.post(
                ApiConfig.SIGN_UP_USER, model,
                httpConfig);
            dispatch(storeSetGlobalLoading(false));
            const { AppCode } = res;
            if (AppCode === 200) {
                handleOnConfirm({ PhoneNumber: phoneNumberInput });
            }
            else if (AppCode === 409) {
                dispatch(storeOpenSnackbar({ open: true, message: "Số điện thoại này đã được đăng ký, vui lòng dùng số khác", type: "warning" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: "Tạo tài khoản khách hàng thất bại", type: "error" }));
            }
        } catch (error) {
            dispatch(storeSetGlobalLoading(false));
            dispatch(storeOpenSnackbar({ open: true, message: "Có lỗi xảy ra, vui lòng thử lại!", type: "error" }));
        }
    }

    const sendOTPForRegisterUser = async (phoneNumber: string) => {
        try {
            var httpConfig = {
                headers: {
                    'verificationToken': process.env.REACT_APP_VERIFICATION_TOKEN
                }
            }
            dispatch(storeSetGlobalLoading(true));
            const res = await apiClient.post(
                ApiConfig.REGISTER_VERIFICATION,
                {
                    PhoneNumber: phoneNumber
                },
                httpConfig);
            dispatch(storeSetGlobalLoading(false));
            const { AppCode } = res;
            if (AppCode === 200) {
                setIsConfirmingOtp(true);
            }
            else if (AppCode === 409) {
                dispatch(storeOpenSnackbar({ open: true, message: "Số điện thoại này đã được đăng ký, vui lòng dùng số khác", type: "warning" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: "Có lỗi xảy ra, vui lòng thử lại!", type: "error" }));
            }
        } catch (error) {
            dispatch(storeSetGlobalLoading(false));
            dispatch(storeOpenSnackbar({ open: true, message: "Có lỗi xảy ra, vui lòng thử lại!", type: "error" }));
        }
    }

    const onCreateAccount = async (verifiedUserToken: string) => {
        try {
            dispatch(storeSetGlobalLoading(true));
            const model = { PhoneNumber: phoneNumberInput, Password: passwordInput, FullName: fullNameInput, VerifiedUserToken: verifiedUserToken }
            const res = await apiClient.post(ApiConfig.SIGN_UP_VERIFIED_USER, model);
            dispatch(storeSetGlobalLoading(false));
            const { AppCode } = res;
            if (AppCode === 200) {
                handleOnConfirm();
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: "Tạo tài khoản khách hàng thất bại", type: "error" }));
            }
        } catch (error) {
            dispatch(storeSetGlobalLoading(false));
            dispatch(storeOpenSnackbar({ open: true, message: "Có lỗi xảy ra, vui lòng thử lại!", type: "error" }));
        }
    }

    const onConfirmOTPAndCreateAccount = async () => {
        if (otp.length === 7) {
            try {
                dispatch(storeSetGlobalLoading(true));
                const res = await apiClient.post(ApiConfig.OTP_VERIFICATION, {
                    PhoneNumber: phoneNumberInput,
                    OTP: otp,
                });
                dispatch(storeSetGlobalLoading(false));
                const { AppCode, Message, Data } = res;
                if (AppCode === 200) {
                    onCreateAccount(Data.VerifiedUserToken)
                } else if (AppCode === 409) {
                    dispatch(storeOpenSnackbar({ open: true, message: Message, type: "error" }));
                }
                else {
                    dispatch(storeOpenSnackbar({ open: true, message: "Có lỗi xảy ra, vui lòng thử lại!", type: "error" }));
                }
            } catch (error) {
                dispatch(storeSetGlobalLoading(false));
                dispatch(storeOpenSnackbar({ open: true, message: "Có lỗi xảy ra, vui lòng thử lại!", type: "error" }));
            }
        } else {
        }
    };

    const handleSubmit = async () => {
        // if (!isConfirmingOtp) {
        //     setIsTouch(true);

        //     if (!fullNameInput || !phoneNumberInput || !passwordInput || !passwordAgainInput || (passwordInput !== passwordAgainInput)) {
        //         return;
        //     }

        //     // Gửi OTP đến SĐT đăng ký
        //     sendOTPForRegisterUser(phoneNumberInput);
        // }
        // else {
        //     // Xác minh mã OTP và Tạo tài khoản
        //     onConfirmOTPAndCreateAccount();
        // }

        setIsTouch(true);
        if (!fullNameInput || !phoneNumberInput || !passwordInput || !passwordAgainInput || (passwordInput !== passwordAgainInput)) {
            return;
        }

        onRegisterUser();
    }

    const handleTextInputChange = (event: any, field: string) => {
        const value = event.target.value;
        switch (field) {
            case 'fullname':
                setFullNameInput(value);
                break;
            case 'phone':
                setPhoneNumberInput(value);
                break;
            case 'password':
                setPasswordInput(value);
                break;
            case 'passwordAgain':
                setPasswordAgainInput(value);
                break;
        }
    }

    return (
        <HDialog
            PaperProps={{
                sx: {
                    width: "450px",
                }
            }}
            title="Tạo tài khoản khách hàng"
            open={isOpen}
            handleClose={handleOnClose}
            dialogFooter={
                <>
                    <HButton startIcon={<CloseOutlinedIcon />} btnType="secondary" onClick={handleOnClose}>Hủy</HButton>
                    <HButton startIcon={<DoneOutlinedIcon />} onClick={handleSubmit}>Xác nhận</HButton>
                </>
            }
        >
            <Grid container>
                <Grid sx={{ pt: 0, pl: 0 }} item xs={12}>
                    <FormControl sx={{ width: '100%' }} variant="standard">
                        <LabelControl required>Họ và tên</LabelControl>
                        <HTextField disabled={isConfirmingOtp} sx={(!fullNameInput && isTouch) ? sharedStyles.borderError : { width: '100%', marginTop: '4px' }} onChange={(event: any) => handleTextInputChange(event, 'fullname')} value={fullNameInput} placeholder="Nhập họ và tên" />
                        {
                            (!fullNameInput && isTouch) &&
                            <ErrorValidation>Bạn chưa nhập họ và tên</ErrorValidation>
                        }
                    </FormControl>
                </Grid>
                <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={12}>
                    <FormControl sx={{ width: '100%' }} variant="standard">
                        <LabelControl required>Số điện thoại</LabelControl>
                        <HTextField disabled={isConfirmingOtp} onChange={(event: any) => handleTextInputChange(event, 'phone')} value={phoneNumberInput} sx={(!phoneNumberInput && isTouch) ? sharedStyles.borderError : { width: '100%', marginTop: '4px' }} placeholder="Nhập số điện thoại" />
                        {
                            (!phoneNumberInput && isTouch) &&
                            <ErrorValidation>Bạn chưa nhập số điện thoại</ErrorValidation>
                        }
                    </FormControl>
                </Grid>
                <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={12}>
                    <FormControl sx={{ width: '100%' }} variant="standard">
                        <LabelControl required>Mật khẩu</LabelControl>
                        <HTextField
                            disabled={isConfirmingOtp}
                            fullWidth
                            sx={(!passwordInput && isTouch) ? sharedStyles.borderError : {}}
                            value={passwordInput}
                            onChange={(event: any) => handleTextInputChange(event, 'password')}
                            type={showPassword ? "text" : "password"}
                            placeholder="Nhập mật khẩu"
                            iconEnd={showPassword ? <VisibilityIcon onClick={toggleDisplayPassword}
                                className="cursor-pointer" /> : <VisibilityOffIcon
                                onClick={toggleDisplayPassword}
                                className="cursor-pointer" />}
                        />
                        {
                            (!passwordInput && isTouch) &&
                            <ErrorValidation>Bạn chưa nhập mật khẩu</ErrorValidation>
                        }
                        {
                            (passwordInput && passwordInput.length < 8 && isTouch) &&
                            <ErrorValidation>Mật khẩu phải có tối thiểu 8 ký tự</ErrorValidation>
                        }
                    </FormControl>
                </Grid>
                <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={12}>
                    <FormControl sx={{ width: '100%' }} variant="standard">
                        <LabelControl required>Xác nhận lại mật khẩu</LabelControl>
                        <HTextField
                            disabled={isConfirmingOtp}
                            fullWidth
                            value={passwordAgainInput || ''}
                            sx={(passwordInput !== passwordAgainInput && isTouch) ? sharedStyles.borderError : {}}
                            onChange={(event: any) => handleTextInputChange(event, 'passwordAgain')}
                            type={showPasswordAgain ? "text" : "password"}
                            placeholder="Xác nhận lại mật khẩu"
                            iconEnd={showPasswordAgain ? <VisibilityIcon onClick={toggleDisplayPasswordAgain}
                                className="cursor-pointer" /> : <VisibilityOffIcon
                                onClick={toggleDisplayPasswordAgain}
                                className="cursor-pointer" />}
                        />
                        {
                            (passwordInput !== passwordAgainInput && isTouch) &&
                            <ErrorValidation>Mật khẩu không giống</ErrorValidation>
                        }
                    </FormControl>
                </Grid>
                {
                    isConfirmingOtp &&
                    <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={12}>
                        <LabelControl required>Nhập OTP xác nhận</LabelControl>
                        <OtpInput
                            shouldAutoFocus={true}
                            containerStyle={styles.otpContainerStyle}
                            value={otp}
                            onChange={setOtp}
                            numInputs={7}
                            renderSeparator={<span>&nbsp; &nbsp; </span>}
                            inputStyle={styles.otpInputStyle}
                            renderInput={(props) => <input {...props} />}
                        />
                    </Grid>
                }
            </Grid>
        </HDialog>
    )
}

const styles = {
    otpContainerStyle: {
        width: "100%",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    otpInputStyle: {
        border: `1px solid ${COLORS.LightSilver}`,
        width: '40px',
        height: '40px',
        borderRadius: '8px'
    }
}