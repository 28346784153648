import { HCheckbox } from "@components/Checkbox";
import { HDatePicker } from "@components/DateTimePicker";
import { ApiConfig } from "@config/index";
import { useAppSelector } from "@hooks/useSelector/useAppSelector";
import { FormControl, FormControlLabel, FormGroup, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { apiClient } from "@services/baseAxios";
import { COLORS } from "@shared/constants/colors";
import { systemRole } from "@shared/constants/common";
import { errorMessages } from "@shared/constants/messages";
import { currencyFormat, getLocationNameById, stringToColour } from "@shared/helpers/common";
import { LabelControl, Title } from "@shared/styledComponents";
import { storeSetSpaLocations } from "@store/directoryReducer";
import { storeOpenSnackbar, storeSetGlobalLoading } from "@store/globalReducer";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const HeaderWrapper = styled('div')(() => ({
    width: '100%',
    backgroundColor: COLORS.GhostWhite,
    padding: '16px',
    display: 'flex',
    flexDirection: 'column'
}));

const DashboardWrapper = styled('div')(() => ({
    width: '100%',
    backgroundColor: COLORS.White,
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '100%'
}));

const ToolbarWrapper = styled('div')(() => ({
    padding: "0px 0px 16px 0px"
}));

export const DebtDashboardAdministration = () => {
    const dispatch = useDispatch();

    const { spaLocations } = useSelector((state: any) => state.directory);
    var { tokenClaims } = useAppSelector((state: any) => state.auth);
    const isBranchManager = tokenClaims.role === systemRole.BRANCH_MANAGER;

    const [fromDate, setFromDate] = useState<Dayjs | null>(dayjs(new Date()).subtract(6, 'month'));
    const [toDate, setToDate] = useState<Dayjs | null>(dayjs(new Date()));
    const [listChecked, setListChecked] = useState([] as any);
    const [dashboardData, setDashboardData] = useState({} as any);

    const handleSetListLocation = () => {
        let listTemp = spaLocations.map((e: any) => {
            return {
                Id: e.Id,
                Name: e.Name,
                Checked: isBranchManager ? (e.Id === tokenClaims.location_id ? true : false) : true
            }
        })
        setListChecked([
            ...listTemp
        ])
    }

    const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        let listTemp = listChecked;
        let index = listTemp.findIndex((x: any) => x.Id === id);

        if (index > -1) {
            listTemp[index].Checked = event.target.checked;
        }

        setListChecked([
            ...listTemp
        ])
    };

    const getSpaLocation = async () => {
        if (spaLocations?.length > 0) {
            handleSetListLocation();
            return;
        }

        const res = await apiClient.get(ApiConfig.GET_ALL_SPA_LOCATION);
        if (res?.AppCode === 200) {
            dispatch(storeSetSpaLocations(res.Data));
        }
    }

    const getObjectFilter = () => {
        let listLocationId = listChecked.filter((x: any) => x.Checked).map((x: any) => x.Id);

        return {
            StartDate: fromDate,
            EndDate: toDate,
            ListLocationId: listLocationId
        }
    }

    const getDashboarData = async () => {
        try {
            dispatch(storeSetGlobalLoading(true));
            var { AppCode, Data, Message } = await apiClient.post(`${ApiConfig.DASHBOARD}/debt`, getObjectFilter());
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                var dashboardDataTemp: any = {};
                dashboardDataTemp.totalDebt = Data.AssumptionDebt;

                var listDebtByLocation = [];

                for (let item of Data.ListLocationDebtInfo) {
                    listDebtByLocation.push({
                        name: getLocationNameById(item.LocationId, spaLocations),
                        "Giá trị": item.DebtValue,
                        hexColor: stringToColour(item.LocationId)
                    })
                }

                dashboardDataTemp.listDebtByLocation = listDebtByLocation;

                setDashboardData(dashboardDataTemp);
            }
            else if (AppCode === 500) {
                dispatch(storeOpenSnackbar({ open: true, message: Message, type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: Message, type: "error" }));
            }
        } catch (error: any) {
            dispatch(storeSetGlobalLoading(false));
            if (error?.response?.data?.AppCode === 500) {
                dispatch(storeOpenSnackbar({ open: true, message: error?.response?.data?.Message, type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        }
    }

    useEffect(() => {
        getSpaLocation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (fromDate && toDate && listChecked?.length > 0) {
            getDashboarData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromDate, toDate, listChecked])

    useEffect(() => {
        handleSetListLocation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [spaLocations])

    return (
        <div className="h-full w-full flex flex-col">
            <HeaderWrapper>
                <ToolbarWrapper>
                    <Grid container>
                        <Grid sx={{ paddingRight: '16px' }} item xs={6}>
                            <LabelControl>Cơ sở</LabelControl>
                            <FormGroup row={true}>
                                {
                                    listChecked?.length > 0 &&
                                    listChecked.map((e: any) => (
                                        <FormControlLabel
                                            key={e.Id}
                                            control={<HCheckbox
                                                checked={listChecked.find((x: any) => x.Id === e.Id)?.Checked}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeCheckbox(event, e.Id)}
                                                disabled={isBranchManager}
                                            />}
                                            label={e.Name}
                                        />
                                    ))
                                }
                            </FormGroup>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl sx={{ width: '100%', paddingRight: '8px' }} variant="standard">
                                <LabelControl>Từ ngày</LabelControl>
                                <HDatePicker
                                    value={fromDate}
                                    ampm={false}
                                    maxDate={new Date()}
                                    inputFormat="DD/MM/YYYY"
                                    onChange={(newValue: any) => {
                                        setFromDate(newValue);
                                    }}>
                                </HDatePicker>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl sx={{ width: '100%', paddingLeft: '8px' }} variant="standard">
                                <LabelControl>Đến ngày</LabelControl>
                                <HDatePicker
                                    value={toDate}
                                    placeholder="Đến ngày"
                                    inputFormat="DD/MM/YYYY"
                                    ampm={false}
                                    maxDate={new Date()}
                                    onChange={(newValue: any) => {
                                        setToDate(newValue);
                                    }}>
                                </HDatePicker>
                            </FormControl>
                        </Grid>
                    </Grid>
                </ToolbarWrapper>
            </HeaderWrapper>
            <div className="flex-grow overflow-auto">
                <DashboardWrapper>
                    <div className="text-center"><Title>Thống Kê Công Nợ</Title></div>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            width={500}
                            height={300}
                            data={dashboardData.listDebtByLocation}
                            margin={{
                                top: 30,
                                right: 0,
                                left: 50,
                                bottom: 0,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis
                                label={{ value: "Đồng", position: 'top', offset: 15, angle: 0 }}
                                tick={{ fontSize: 14 }}
                                tickFormatter={tick => {
                                    return currencyFormat(tick);
                                }}
                            />
                            <Tooltip
                                formatter={((value, name) => `${currencyFormat(value as number) || 0}đ`)}
                            />
                            {/* <Legend verticalAlign='top' /> */}
                            <Bar dataKey={'Giá trị'} fill="#8884d8" />
                        </BarChart>
                    </ResponsiveContainer>
                    <div className="flex items-center mt-1 flex-col">
                        <div>
                            Tổng <span style={{ color: COLORS.MagicPotion }}>CÔNG NỢ</span>: <span className="font-bold">{currencyFormat(dashboardData.totalDebt)}đ</span>
                            <span className="ml-3">Từ {fromDate?.format('DD/MM/YYYY')} Đến {toDate?.format('DD/MM/YYYY')}</span>
                        </div>
                        <div className="flex">
                            {
                                dashboardData?.assumptionInformation &&
                                dashboardData.assumptionInformation.map((e: any) => (
                                    <div key={e.LocationId} className="mr-5">
                                        <span style={{ color: stringToColour(e.LocationId) }}>{e.LocationName}: </span>{currencyFormat(e.RevenueValue) || 0}đ
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </DashboardWrapper>
            </div>
        </div>
    )
}