export const COLORS = {
    Primary: '#1E2329',
    Secondary: '#1A3B70',
    MainBackground: '#F5F5F5',
    CatalinaBlue: "#143376",
    White: "#FFF",
    Gray: "#7D7E7E",
    LightSilver: "#D2D6DA",
    PhilippineSilver: "#B1B1B1",
    YankeesBlue: "#17203F",
    Yellow: "#FFC547",
    JapaneseIndigo: "#2c3e50",
    Lavender: '#E6EEF8',
    Grayscale: '#EFEFEF',
    Tertiary: '#D19E6A',
    Eucalyptus: '#4DD69C',
    OldSilver: "#828282",
    AzureishWhite: "#D5E3F3",
    DarkCornflowerBlue: "#22408D",
    Cultured: "#F8F8F8",
    GhostWhite: "#F7F9FD",
    Independence: "#454D65",
    Green: "#55B938",
    DarkCoral: "#D65745",
    Greyscale: "#F6F6F6",
    BrilliantAzure: "#359EFF",
    Water: "#D7ECFF",
    Bubbles: "#E9FCFA",
    MagicPotion: "#FF4559",
    LavenderBlush: "#FFEBED",
    BlanchedAlmond: "#FFF0CF",
    Maize: "#FFC547",
    DavyGrey: '#5A5A5A',
    AntiFlashWhite: '#F1F1F1',
    Dandelion: '#FFD26F'
};
