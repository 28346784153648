export const routes = {
    LOGIN: '/login',
    MAIN: '/main',
    BOOKING: '/main/booking',
    BOOKING_MANAGEMENT: '/main/booking-management',
    TREATMENT_MANAGEMENT: '/main/treatment-management',
    BOOKING_CREATION: '/main/booking/create',
    BOOKING_DETAIL: '/main/booking/:id',
    CUSTOMER_ACCOUNT_CREATION: '/main/customer/create',
    FEEDBACK: '/main/feedback',
    COMPLAIN: '/main/complain',
    TREATMENT_SESSION: '/main/feedback/:id',
    PAYMENT_UPDATE: '/main/payment/update',
    PAYMENT_LIST: '/main/payment/list',
    PAYMENT_DETAIL: '/main/payment/detail/:id',
    PAYMENT_BUYING_PRODUCT: '/main/payment/buying-product',
    PAYMENT_BUYING_SERVICE: '/main/payment/buying-service',
    PAYMENT_SWITCH_BUYING_SERVICE_USER: '/main/payment/switch-buying-service-user/:id',
    PAYMENT_BILL_CREATION: '/main/payment/bill-creation',

    REVENUE_DASHBOARD_ADMINISTRATION: '/main/administration/dashboard/revenue',
    DEBT_DASHBOARD_ADMINISTRATION: '/main/administration/dashboard/debt',
    USER_STATISTIC_DASHBOARD_ADMINISTRATION: '/main/administration/dashboard/user-amount',

    ALL_USER_ADMINISTRATION: '/main/administration/account/all',
    CUSTOMER_ADMINISTRATION: '/main/administration/account/customer',
    RESTORE_ACCOUNT_ADMINISTRATION: '/main/administration/account/restore',
    CUSTOMER_DETAIL_ADMINISTRATION: '/main/administration/account/customer/:id',
    LOCATION_ADMINISTRATION: '/main/administration/location',
    SERVICE_ADMINISTRATION: '/main/administration/service',
    SERVICE_TYPE_ADMINISTRATION: '/main/administration/service-type',
    PRODUCT_ADMINISTRATION: '/main/administration/product',
    PRODUCT_TYPE_ADMINISTRATION: '/main/administration/product-type',
    BUYING_SERVICE_ADMINISTRATION: '/main/administration/buying-service',
    BUYING_PRODUCT_ADMINISTRATION: '/main/administration/buying-product',
    PAYMENT_ADMINISTRATION: '/main/administration/payment',
    EMAIL_ADMINISTRATION: '/main/administration/email',
    ARTICLE_ADMINISTRATION: '/main/administration/article',
    SMS_ADMINISTRATION: '/main/administration/sms',
    NOTIFICATION_ADMINISTRATION: '/main/administration/notification',
    // Phản hồi khách hàng
    FEEDBACK_ADMINISTRATION: '/main/administration/feedback',
    // Khiếu nại
    COMPLAIN_ADMINISTRATION: '/main/administration/complain',
    USER_INFO: '/user-info',
    FORBIDDEN: '/forbidden'
}