import { HButton } from "@components/Button"
import { HDialog } from "@components/Dialog"
import { Box, FormControl, Grid, MenuItem } from "@mui/material"
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AddIcon from '@mui/icons-material/Add';
import { LabelControl, MenuItemPlaceholder } from "@shared/styledComponents";
import { HSelect } from "@components/Select";
import { useEffect, useState } from "react";
import { apiClient } from "@services/baseAxios";
import { ApiConfig } from "@config/index";
import { storeSetAllService, storeSetSpaLocations } from "@store/directoryReducer";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@hooks/useSelector/useAppSelector";
import { HAutocomplete } from "@components/Autocomplete";
import { HTextField } from "@components/Input/HTextFiled";
import { ReactComponent as VNDIcon } from '@assets/icons/icon-vnd.svg';
import { currencyFormat, generateUUID } from "@shared/helpers/common";
import { storeOpenSnackbar } from "@store/globalReducer";
import { systemRole } from "@shared/constants/common";

export const BuyingServiceWithPaymentForm = ({ isOpen, isCreateNew, data, handleOnClose, handleOnConfirm }: any) => {
    const dispatch = useDispatch();
    const { spaLocations, allService } = useAppSelector((state: any) => state.directory);
    var { tokenClaims } = useAppSelector(state => state.auth);
    const isBranchAccountant = tokenClaims.role === systemRole.BRANCH_ACCOUNTANT;
    const [selectedLocationId, setSelectedLocationId] = useState(tokenClaims.location_id || "-1");
    const [selectedService, setSelectedService] = useState({ Id: null, Code: '', Name: '' });
    const [moneyInput, setMoneyInput] = useState('');
    const [paymentInput, setPaymentInput] = useState('0');
    const [remarkInput, setRemarkInput] = useState('');

    const handleChangeLocation = (event: { target: { value: string } }) => {
        setSelectedLocationId(event.target.value);
    };

    const handleOnChangeService = (e: React.SyntheticEvent, value: any) => {
        setSelectedService(value);
    }

    const getAllService = async () => {
        if (allService?.length > 0) return;

        const res = await apiClient.get(ApiConfig.SERVICE);
        if (res?.AppCode === 200) {
            dispatch(storeSetAllService(res.Data));
        }
    }

    const getSpaLocation = async () => {
        if (spaLocations?.length > 0) return;

        const res = await apiClient.get(ApiConfig.GET_ALL_SPA_LOCATION);
        if (res?.AppCode === 200) {
            dispatch(storeSetSpaLocations(res.Data));
        }
    }

    const generateBuyingProductUserObject = () => {
        return {
            Id: isCreateNew ? generateUUID() : data.Id,
            ServiceId: selectedService?.Id,
            ServiceName: selectedService?.Name,
            LocationId: selectedLocationId,
            Price: parseInt(moneyInput.replace(/\D/g, '')),
            Payment: parseInt(paymentInput.replace(/\D/g, '')),
            Note: remarkInput
        };
    }

    const handleSubmit = () => {
        if (!selectedService?.Id || !selectedLocationId || selectedLocationId === "-1" || !moneyInput) {
            dispatch(storeOpenSnackbar({ open: true, message: "Vui lòng nhập đủ thông tin", type: "warning" }));
            return;
        }

        if (parseInt(moneyInput.replace(/\D/g, '')) < parseInt(paymentInput.replace(/\D/g, ''))) {
            dispatch(storeOpenSnackbar({ open: true, message: "Giá tiền phải lớn hơn hoặc bằng thanh toán", type: "warning" }));
            return;
        }

        var emittedData = generateBuyingProductUserObject();
        handleOnConfirm(emittedData);
    }

    const handleTextInputChange = (event: any, field: string) => {
        const value = event.target.value;
        switch (field) {
            case 'money':
            case 'payment':
                var a = value.replace(/\D/g, "");
                var data = currencyFormat(parseInt(a)).toString();

                if (field === 'money') {
                    setMoneyInput(data);
                }
                else {
                    setPaymentInput(data);
                }
                break;
            case 'remark':
                setRemarkInput(value);
                break;
        }
    }

    useEffect(() => {
        getSpaLocation();
        getAllService();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isCreateNew) {
            setMoneyInput(currencyFormat(parseInt(data.Price)).toString());
            setPaymentInput(currencyFormat(parseInt(data.Payment)).toString());
            if (spaLocations?.length > 0) {
                setSelectedLocationId(data.LocationId);
            }
            if (allService?.length > 0) {
                let service = allService.find((x: any) => x.Id === data.ServiceId);
                if (service) {
                    setSelectedService(service)
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [spaLocations, allService])

    const renderOptionService = (props: any, option: any) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {option.Name}
        </Box>
    );

    return (
        <HDialog
            PaperProps={{
                sx: {
                    width: "450px",
                }
            }}
            title={isCreateNew ? "Thêm mới dịch vụ" : "Chỉnh sửa dịch vụ"}
            open={isOpen}
            handleClose={handleOnClose}
            dialogFooter={
                <>
                    <HButton startIcon={<CloseOutlinedIcon />} btnType="secondary" onClick={handleOnClose}>Hủy</HButton>
                    {
                        isCreateNew ?
                            <HButton startIcon={<AddIcon />} onClick={handleSubmit}>Thêm dịch vụ</HButton>
                            :
                            <HButton startIcon={<DoneOutlinedIcon />} onClick={handleSubmit}>Cập nhật</HButton>
                    }

                </>
            }
        >
            <Grid container>
                <Grid sx={{ pt: 0, pl: 0 }} item xs={12}>
                    <FormControl fullWidth variant="standard">
                        <LabelControl required>Dịch vụ</LabelControl>
                        <HAutocomplete
                            disabled={!isCreateNew}
                            placeholder="Chọn dịch vụ"
                            value={selectedService}
                            options={allService}
                            getOptionLabel={(option: any) => option.Name}
                            renderOption={renderOptionService}
                            onChange={handleOnChangeService}
                        />
                    </FormControl>
                </Grid>
                <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={12}>
                    <FormControl fullWidth variant="standard">
                        <LabelControl required>Cơ sở</LabelControl>
                        <HSelect disabled={!isCreateNew || isBranchAccountant} fullWidth value={selectedLocationId}
                            onChange={handleChangeLocation}>
                            <MenuItem key={-1} disabled value="-1">
                                <MenuItemPlaceholder>Chọn cơ sở</MenuItemPlaceholder>
                            </MenuItem>
                            {
                                spaLocations.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                            }
                        </HSelect>
                    </FormControl>
                </Grid>
                <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={12}>
                    <FormControl fullWidth variant="standard">
                        <LabelControl required>Giá bán dịch vụ</LabelControl>
                        <HTextField
                            fullWidth
                            value={moneyInput}
                            onChange={(event: any) => handleTextInputChange(event, 'money')}
                            placeholder="Nhập giá bán"
                            iconEnd={<VNDIcon />}
                        />
                    </FormControl>
                </Grid>
                <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={12}>
                    <FormControl fullWidth variant="standard">
                        <LabelControl>Thanh toán</LabelControl>
                        <HTextField
                            fullWidth
                            value={paymentInput}
                            onChange={(event: any) => handleTextInputChange(event, 'payment')}
                            placeholder="Nhập số tiền thanh toán"
                            iconEnd={<VNDIcon />}
                        />
                    </FormControl>
                </Grid>
                <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={12}>
                    <FormControl fullWidth variant="standard">
                        <LabelControl>Ghi chú</LabelControl>
                        <HTextField
                            fullWidth
                            value={remarkInput}
                            onChange={(event: any) => handleTextInputChange(event, 'remark')}
                            placeholder="Nhập ghi chú"
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </HDialog>
    )
}