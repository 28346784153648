import { styled } from "@mui/material/styles";
import { COLORS } from "@shared/constants/colors";
import { LabelControl, Title } from "@shared/styledComponents";
import { FormControl, Grid, MenuItem, Tooltip } from "@mui/material";
import { Input } from "@components/Input";
import { useEffect, useState } from "react";
import { HDatePicker } from "@components/DateTimePicker";
import dayjs, { Dayjs } from "dayjs";
import { apiClient } from "@services/baseAxios";
import { Column, PaginatedResult } from "types/common";
import { ApiConfig } from "@config/index";
import { storeOpenSnackbar } from "@store/globalReducer";
import { errorMessages } from "@shared/constants/messages";
import { useDispatch } from "react-redux";
import { HTable } from "@components/Table";
import { currencyFormat } from "@shared/helpers/common";
import moment from "moment";
import { HSelect } from "@components/Select";
import { useAppSelector } from "@hooks/useSelector/useAppSelector";
import { storeSetSpaLocations } from "@store/directoryReducer";
import { HButton } from "@components/Button";
import AddIcon from '@mui/icons-material/Add';
import { systemRole } from "@shared/constants/common";
import { BuyingServiceForm } from "./buyingServiceForm";
import { rowCustomHandlerType } from "@components/Table/constant";
import SvgIcon from '@mui/icons-material/Menu';
import { ReactComponent as EditIcon } from '@assets/icons/icon-edit.svg';
import { ReactComponent as SwitchIcon } from '@assets/icons/icon-switch.svg';
import { useNavigate } from "react-router-dom";

const Wrapper = styled('div')(() => ({
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.GhostWhite,
    padding: '16px',
    display: 'flex',
    flexDirection: 'column'
}));

const ToolbarWrapper = styled('div')(() => ({
    padding: "16px 0px"
}));

const columns: readonly Column[] = [
    {
        id: 'STT',
        label: 'STT',
        align: 'left',
        minWidth: 30,
        transform: (data: any) => (data.index + 1)
    },
    { id: 'BuyerName', label: 'Tên khách hàng', align: 'left', minWidth: 150, transform: (data: any) => (<div style={{ fontWeight: 'bold' }}>{data.value}</div>) },
    {
        id: 'BuyerPhoneNumber',
        label: 'Số điện thoại',
        align: 'left',
        minWidth: 120,
    },
    {
        id: 'ServiceName',
        label: 'Tên dịch vụ',
        minWidth: 150,
        align: 'left',
    },
    {
        id: 'TimeStart',
        label: 'Ngày thanh toán',
        minWidth: 140,
        align: 'center',
        transform: (data: any) => (data.value ? moment(data.value).format("DD/MM/YYYY") : '--/--/----')
    },
    {
        id: 'ActualPaidAmount',
        label: 'Đã thanh toán',
        minWidth: 140,
        align: 'right',
        transform: (data: any) => (<span style={{ color: COLORS.MagicPotion }}>{currencyFormat(data.value)}đ</span>)
    },
    {
        id: 'Price',
        label: 'Giá dịch vụ',
        minWidth: 100,
        align: 'right',
        transform: (data: any) => (<span style={{ color: COLORS.Eucalyptus }}>{currencyFormat(data.value)}đ</span>)
    },
    {
        id: 'Actions',
        align: 'center',
        label: 'Tác vụ',
        minWidth: 80,
        transform: (value: any) => (
            <div className="flex justify-center items-center">
                <Tooltip title="Chỉnh sửa" placement="left">
                    <div style={styles.tooltipWrapper} onClick={() => value.onCustomHandler(rowCustomHandlerType.EDIT)} className='flex justify-center items-center cursor-pointer'>
                        <SvgIcon sx={{ width: 16, height: 16, color: COLORS.Green }} component={EditIcon} inheritViewBox />
                    </div>
                </Tooltip>
                <Tooltip className="ml-2" title="Chuyển đổi" placement="top">
                    <div style={styles.tooltipWrapper} onClick={() => value.onCustomHandler(rowCustomHandlerType.SWITCH)} className='flex justify-center items-center cursor-pointer bg-[#17203F]'>
                        <SvgIcon sx={{ width: 16, height: 16, color: COLORS.Green }} component={SwitchIcon} inheritViewBox />
                    </div>
                </Tooltip>
            </div>
        )
    },
];

export const PaymentBuyingService = () => {
    const dispatch = useDispatch();
    var { tokenClaims } = useAppSelector(state => state.auth);
    const { spaLocations } = useAppSelector((state: any) => state.directory);
    const isBranchAccountant = tokenClaims.role === systemRole.BRANCH_ACCOUNTANT;

    const navigate = useNavigate();
    const [currentSelectedRow, setCurrentSelectedRow] = useState({ Id: null });
    const [keyword, setKeyword] = useState('');
    const [fromDate, setFromDate] = useState<Dayjs | null>(null);
    const [toDate, setToDate] = useState<Dayjs | null>(null);
    const [tableLoading, setTableLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [paginatedData, setPaginatedData] = useState<PaginatedResult>({
        ListOut: [],
        TotalCount: 0,
        PageStart: 1,
        PageEnd: 1,
        TotalPage: 0
    } as PaginatedResult);
    const [selectedLocation, setSelectedLocation] = useState(tokenClaims.location_id || "-1");
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [isCreateNewBuyingService, setIsCreateNewBuyingService] = useState(true);

    const handleCloseForm = () => {
        setIsOpenForm(false);
    }

    const handleSubmitSuccess = () => {
        setIsOpenForm(false);
        dispatch(storeOpenSnackbar({ open: true, message: `${isCreateNewBuyingService ? "Thêm" : "Sửa"} dịch vụ mua thành công`, type: "success" }));
        getListPayment();
    }

    const handleChangeKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(event.target.value);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getObjectFilter = () => {
        var fromDateVNTime = dayjs(fromDate).add(7, 'hours') || null;
        var toDateVNTime = dayjs(toDate).add(7, 'hours') || null;

        return {
            Keyword: keyword,
            PageIndex: page + 1,
            PageSize: rowsPerPage,
            DateFrom: fromDateVNTime,
            DateTo: toDateVNTime,
            LocationId: selectedLocation === "-1" ? null : selectedLocation
        };
    }

    const getListPayment = async () => {
        try {
            setTableLoading(true);
            const { AppCode, Data } = await apiClient.post(`${ApiConfig.GET_SERVICE_USER_PAGING}`, getObjectFilter());
            setTableLoading(false);
            if (AppCode === 200) {
                setPaginatedData((prevState: PaginatedResult) => ({
                    ...prevState,
                    ...Data
                }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        } catch (error) {
            setTableLoading(false);
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    const handleChangeLocation = (event: { target: { value: string } }) => {
        setSelectedLocation(event.target.value);
    };

    const getSpaLocation = async () => {
        if (spaLocations?.length > 0) return;

        const res = await apiClient.get(ApiConfig.GET_ALL_SPA_LOCATION);
        if (res?.AppCode === 200) {
            dispatch(storeSetSpaLocations(res.Data));
        }
    }

    const onRowCustomHandler = (row: any, handlerType: any) => {
        setCurrentSelectedRow(row);
        if (handlerType === rowCustomHandlerType.EDIT) {
            setIsCreateNewBuyingService(false);
            setIsOpenForm(true);
        }
        else if (handlerType === rowCustomHandlerType.SWITCH) {
            navigate(`/main/payment/switch-buying-service-user/${row.Id}`);
        }
    }

    useEffect(() => {
        getSpaLocation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getListPayment();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, keyword, fromDate, toDate, selectedLocation]);

    return (
        <Wrapper>
            <div className="w-full flex justify-between">
                <Title>Danh sách dịch vụ mua</Title>
                <HButton startIcon={<AddIcon />} onClick={() => { setIsCreateNewBuyingService(true); setIsOpenForm(true) }} >Thêm mới dịch vụ</HButton>
            </div>
            <ToolbarWrapper>
                <Grid container>
                    <Grid sx={{ paddingRight: '8px' }} item xs={3}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Số điện thoại hoặc tên</LabelControl>
                            <Input onChange={handleChangeKeyword} value={keyword} fullWidth placeholder="Tìm kiếm theo số điện thoại hoặc tên" />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ paddingRight: '8px' }} item xs={3}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Từ ngày</LabelControl>
                            <HDatePicker
                                value={fromDate}
                                ampm={false}
                                inputFormat="DD/MM/YYYY"
                                onChange={(newValue: any) => {
                                    setFromDate(newValue);
                                }}>
                            </HDatePicker>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Đến ngày</LabelControl>
                            <HDatePicker
                                value={toDate}
                                placeholder="Từ ngày"
                                inputFormat="DD/MM/YYYY"
                                ampm={false}
                                onChange={(newValue: any) => {
                                    setToDate(newValue);
                                }}>
                            </HDatePicker>
                        </FormControl>
                    </Grid>
                    <Grid sx={{ paddingLeft: '8px' }} item xs={3}>
                        <FormControl fullWidth variant="standard">
                            <LabelControl>Cơ sở</LabelControl>
                            <HSelect disabled={isBranchAccountant} fullWidth value={selectedLocation}
                                onChange={handleChangeLocation}>
                                <MenuItem key={-1} value={"-1"}>Tất cả</MenuItem>
                                {
                                    spaLocations.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                                }
                            </HSelect>
                        </FormControl>
                    </Grid>
                </Grid>
            </ToolbarWrapper>
            <HTable
                columns={columns}
                pagingInfo={{
                    rowsPerPage,
                    page,
                    totalCount: paginatedData.TotalCount,
                    handleChangePage,
                    handleChangeRowsPerPage
                }}
                loading={tableLoading}
                listData={paginatedData.ListOut}
                onRowCustomHandler={onRowCustomHandler}
            ></HTable>
            {
                isOpenForm &&
                <BuyingServiceForm
                    isOpen={isOpenForm}
                    data={currentSelectedRow}
                    isCreateNew={isCreateNewBuyingService}
                    handleOnClose={handleCloseForm}
                    handleOnConfirm={handleSubmitSuccess}
                >
                </BuyingServiceForm>
            }
        </Wrapper>
    )
}

const styles = {
    tooltipWrapper: {
        backgroundColor: COLORS.YankeesBlue,
        width: 32,
        height: 32,
        borderRadius: '6px'
    }
}