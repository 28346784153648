import { ApiConfig } from "@config/index";
import { useAppSelector } from "@hooks/useSelector/useAppSelector";
import { CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { apiClient } from "@services/baseAxios";
import { COLORS } from "@shared/constants/colors";
import { NotificationReferencePage, NotificationStatus, NotificationType } from "@shared/constants/common";
import { routes } from "@shared/constants/routeNames";
import { storeSetNewNotificationCount } from "@store/globalReducer";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Wrapper = styled('div')(() => ({
    width: '100%',
    height: '100%',
    overflow: 'auto'
}));

const Header = styled('div')(() => ({
    padding: '12px 16px'
}));
const Content = styled('div')(() => ({

}));

const Tab = styled('div', { shouldForwardProp: (prop) => prop !== 'isActive' })(({ isActive }: any) => ({
    padding: '2px 16px',
    borderRadius: '16px',
    backgroundColor: COLORS.Greyscale,
    color: COLORS.DavyGrey,
    cursor: "pointer",
    ...(isActive && {
        backgroundColor: COLORS.YankeesBlue,
        color: COLORS.White,
    })
}));


export const HNotification = ({ onClose, ...props }: any) => {
    const dispatch = useDispatch();
    var { tokenClaims } = useAppSelector(state => state.auth);
    const navigate = useNavigate();
    const [tabActive, setTabActive] = useState(1);
    const [showLoading, setShowLoading] = useState(false);
    const [showLoadMoreLoading, setShowLoadMoreLoading] = useState(false);
    const [listNotification, setListNotification] = useState([]);
    const [pageIndex, setPageIndex] = useState(1);
    const [currentUserId, setCurrentUserId] = useState(tokenClaims.user_id);

    const onChangeTab = (tabIndex: number) => {
        setTabActive(tabIndex);
        setPageIndex(1);
    }

    const NotificationItem = ({ item, onClick, ...props }: any) => {
        return (
            <div onClick={onClick} style={item.Status !== NotificationStatus.HANDLED ? { ...styles.notificationItemWrapper, backgroundColor: COLORS.GhostWhite } : styles.notificationItemWrapper}>
                <p style={styles.notificationItemTitle}>{item.Title}</p>
                <p style={styles.notificationItemBody}>{item.Content}</p>
                <p style={styles.notificationItemTime}>{moment(item.Time).format("HH:mm DD/MM/YYYY")} </p>
            </div>
        )
    }

    const getAllNotificationsOfCurrentUser = async () => {
        try {
            if (pageIndex > 1) {
                setShowLoadMoreLoading(true)
            }
            else {
                setShowLoading(true);
            }
            var { AppCode, Data } = await apiClient.get(`${ApiConfig.NOTIFICATION}/user/${currentUserId}?pageIndex=${pageIndex}&pageSize=${10}&isGetOnlyUnread=${tabActive === 2}`);
            if (pageIndex > 1) {
                setShowLoadMoreLoading(false)
            }
            else {
                setShowLoading(false);
            }
            if (AppCode === 200) {
                if (pageIndex > 1) {
                    var newData = listNotification.concat(Data.Notifications);
                    setListNotification(newData);
                }
                else {
                    setListNotification(Data.Notifications);
                }
            }
            else if (AppCode === 500) {
                setListNotification([]);
            }
            else {
                setListNotification([]);
            }
        } catch (error: any) {
            setShowLoading(false);
            setShowLoadMoreLoading(false)
            setListNotification([]);
        }
    }

    const reloadDataForUnreadTab = () => {
        setPageIndex(1);
        getAllNotificationsOfCurrentUser();
    }

    const markAllAsReaded = async () => {
        var newList = JSON.parse(JSON.stringify(listNotification));
        if (newList?.length > 0) {
            if (newList.every((x: any) => x.Status === NotificationStatus.HANDLED)) {
                return;
            }

            for (let item of newList) {
                item.Status = NotificationStatus.HANDLED;
            }

            // Gọi api đánh là đã đọc thông báo
            var { AppCode } = await apiClient.post(`${ApiConfig.NOTIFICATION}/user/${currentUserId}/handled`);
            // Nếu là tab Chưa đọc thì load lại thông tin
            if (AppCode === 200 && tabActive === 2) {
                reloadDataForUnreadTab();
            }
            else {
                setListNotification(newList);
            }
        }
    }

    //  Hàm thực thi khi bấm vào quả chuông
    const acknowledgeNotificationsOfUser = async () => {
        var { AppCode } = await apiClient.post(`${ApiConfig.NOTIFICATION}/user/${currentUserId}/ack`);
        if (AppCode === 200) {
            dispatch(storeSetNewNotificationCount(0));
        }
    }

    const acknowledgeNotificationInMem = (notiId: number) => {
        var newList = JSON.parse(JSON.stringify(listNotification));
        for (let item of newList) {
            if (item.Id === notiId) {
                item.Status = NotificationStatus.HANDLED;
            }
        }
        setListNotification(newList);
    }

    const onClickNotificationItem = (item: any) => {
        if (item.Status !== NotificationStatus.HANDLED) {
            acknowledgeNotificationInMem(item.Id);
            acknowledgeNotification(item.Id);
        }
        // Nếu là kiểu tương tác thì sẽ điều hướng đi đâu đó
        if (item.Type === NotificationType.INTERACTIVE) {
            switch (item.NotificationReference?.Page) {
                case NotificationReferencePage.BOOKING_CREATION:
                    navigate(routes.BOOKING_CREATION);
                    onClose();
                    break;
                case NotificationReferencePage.BOOKING_CHANGING_REQUEST:
                    navigate(routes.BOOKING_MANAGEMENT, { state: { activeTab: 3 } });
                    onClose();
                    break;
                default:
                    break;
            }
        }
    }

    const acknowledgeNotification = async (notificationId: number) => {
        var { AppCode } = await apiClient.post(`${ApiConfig.NOTIFICATION}/${notificationId}/ack`);
        if (AppCode === 200 && tabActive === 2) {
            reloadDataForUnreadTab();
        }
    }

    const handleScroll = (e: any) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        // Nếu scroll xuống bên dưới thì load thêm dữ liệu
        if (scrollTop + clientHeight >= scrollHeight) {
            setPageIndex(pageIndex + 1);
        }
    }

    useEffect(() => {
        getAllNotificationsOfCurrentUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabActive, pageIndex])

    useEffect(() => {
        acknowledgeNotificationsOfUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setCurrentUserId(tokenClaims.user_id);
    }, [tokenClaims.user_id])

    return (
        <Wrapper onScroll={handleScroll}>
            <Header>
                <div className="flex justify-between items-center">
                    <div style={styles.mainTitle}>Thông báo</div>
                    <div onClick={markAllAsReaded} style={styles.markAsUnRead} className="hover:cursor-pointer">Đánh dấu toàn bộ là đã đọc</div>
                </div>
                <div className="flex mt-3">
                    <Tab onClick={() => onChangeTab(1)} style={tabActive === 1 ? styles.tabActive : {}}>Tất cả</Tab>
                    <Tab onClick={() => onChangeTab(2)} style={tabActive === 2 ? styles.tabActive : {}} className="ml-2">Chưa đọc</Tab>
                </div>
            </Header>
            <Content>
                {
                    showLoading ? <div className="flex justify-center items-center h-full mt-12"><CircularProgress sx={{ color: COLORS.YankeesBlue }} /></div> :
                        <>
                            {
                                (listNotification.length === 0 || !listNotification) ?
                                    <div className="flex justify-center items-center h-full">Không có thông báo!</div> :
                                    <div>
                                        {
                                            listNotification.map((item: any) => (
                                                <NotificationItem onClick={() => onClickNotificationItem(item)} key={item.Id} item={item}></NotificationItem>
                                            ))
                                        }
                                        {
                                            showLoadMoreLoading ?
                                                <div className="flex justify-center items-center">
                                                    <CircularProgress sx={{ color: COLORS.YankeesBlue }} />
                                                </div> :
                                                <></>
                                        }
                                    </div>
                            }
                        </>
                }
            </Content>
        </Wrapper>
    )
}

const styles = {
    mainTitle: {
        fontSize: '18px',
        fontWeight: 'bold'
    },
    markAsUnRead: {
        fontSize: '12px',
        color: COLORS.BrilliantAzure,
    },
    tabActive: {
        backgroundColor: COLORS.YankeesBlue,
        color: COLORS.White,
    },
    notificationItemWrapper: {
        padding: '12px 16px',
        cursor: "pointer",
        borderBottom: `1px solid ${COLORS.AntiFlashWhite}`,
        // backgroundColor: COLORS.BrilliantAzure,
        "&:hover": {
            backgroundColor: COLORS.BrilliantAzure
        },
    },
    notificationItemTitle: {
        fontSize: '14px',
        fontWeight: 'bold'
    },
    notificationItemBody: {
        fontSize: '14px',
    },
    notificationItemTime: {
        fontSize: '12px',
        color: COLORS.PhilippineSilver
    }
}