import { HAutocomplete } from "@components/Autocomplete";
import { HButton } from "@components/Button";
import { ApiConfig } from "@config/index";
import { Box, FormControl, Grid, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { CustomerAccountCreationForm } from "@pages/user/customerAccountCreationForm";
import { apiClient } from "@services/baseAxios";
import { COLORS } from "@shared/constants/colors";
import { errorMessages } from "@shared/constants/messages";
import { LabelControl, Title } from "@shared/styledComponents";
import { storeOpenSnackbar, storeSetGlobalLoading } from "@store/globalReducer";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { User } from "types/user";
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import { Column } from "types/common";
import { ReactComponent as EditIcon } from '@assets/icons/icon-edit.svg';
import { ReactComponent as TrashIcon } from '@assets/icons/icon-trash.svg';
import SvgIcon from '@mui/icons-material/Menu';
import { rowCustomHandlerType } from "@components/Table/constant";
import { HTable } from "@components/Table";
import { BuyingProductWithPaymentForm } from "./buyingProductWithPaymentForm";
import { BuyingServiceWithPaymentForm } from "./buyingServiceWithPaymentForm";
import { currencyFormat } from "@shared/helpers/common";
import { routes } from "@shared/constants/routeNames";
import { useNavigate } from "react-router-dom";

const TopWrapper = styled('div')(() => ({
    width: '100%',
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: COLORS.White
}));

const ContentWrapper = styled('div')(() => ({
    width: '100%',
    padding: '16px',
    marginTop: '16px',
    borderRadius: '8px',
    backgroundColor: COLORS.White
}));

const serviceUserColumns: readonly Column[] = [
    {
        id: 'STT',
        label: 'STT',
        align: 'left',
        minWidth: 30,
        transform: (data: any) => (data.index + 1)
    },
    {
        id: 'ServiceName',
        label: 'Tên dịch vụ',
        align: 'left',
        minWidth: 140,
    },
    {
        id: 'Price',
        label: 'Giá bán',
        minWidth: 120,
        align: 'right',
        transform: (data: any) => (<span style={{ color: COLORS.Eucalyptus }}>{currencyFormat(data.value)}đ</span>)
    },
    {
        id: 'Payment',
        label: 'Thanh toán',
        minWidth: 120,
        align: 'right',
        transform: (data: any) => (<span style={{ color: COLORS.MagicPotion }}>{currencyFormat(data.value) || 0}đ</span>)
    },
    {
        id: 'Note',
        label: 'Ghi chú',
        minWidth: 140,
        align: 'left'
    },
    {
        id: 'Actions',
        align: 'center',
        label: 'Tác vụ',
        minWidth: 80,
        transform: (value: any) => (
            <div className="flex justify-center items-center">
                <Tooltip title="Chỉnh sửa" placement="left">
                    <div style={styles.tooltipWrapper} onClick={() => value.onCustomHandler(rowCustomHandlerType.EDIT)} className='flex justify-center items-center cursor-pointer'>
                        <SvgIcon sx={{ width: 16, height: 16, color: COLORS.Green }} component={EditIcon} inheritViewBox />
                    </div>
                </Tooltip>
                <Tooltip className="ml-2" title="Xóa" placement="top">
                    <div style={styles.tooltipWrapper} onClick={() => value.onCustomHandler(rowCustomHandlerType.DELETE)} className='flex justify-center items-center cursor-pointer bg-[#17203F]'>
                        <SvgIcon sx={{ width: 16, height: 16, color: COLORS.Green }} component={TrashIcon} inheritViewBox />
                    </div>
                </Tooltip>
            </div>
        )
    },
];

const buyingProductColumns: readonly Column[] = [
    {
        id: 'STT',
        label: 'STT',
        align: 'left',
        minWidth: 30,
        transform: (data: any) => (data.index + 1)
    },
    {
        id: 'ProductName',
        label: 'Tên sản phẩm',
        align: 'left',
        minWidth: 140,
    },
    {
        id: 'Price',
        label: 'Giá bán',
        minWidth: 120,
        align: 'right',
        transform: (data: any) => (<span style={{ color: COLORS.Eucalyptus }}>{currencyFormat(data.value)}đ</span>)
    },
    {
        id: 'Price',
        label: 'Thanh toán',
        minWidth: 120,
        align: 'right',
        transform: (data: any) => (<span style={{ color: COLORS.MagicPotion }}>{currencyFormat(data.value)}đ</span>)
    },
    {
        id: 'Actions',
        align: 'center',
        label: 'Tác vụ',
        minWidth: 80,
        transform: (value: any) => (
            <div className="flex justify-center items-center">
                <Tooltip title="Chỉnh sửa" placement="left">
                    <div style={styles.tooltipWrapper} onClick={() => value.onCustomHandler(rowCustomHandlerType.EDIT)} className='flex justify-center items-center cursor-pointer'>
                        <SvgIcon sx={{ width: 16, height: 16, color: COLORS.Green }} component={EditIcon} inheritViewBox />
                    </div>
                </Tooltip>
                <Tooltip className="ml-2" title="Xóa" placement="top">
                    <div style={styles.tooltipWrapper} onClick={() => value.onCustomHandler(rowCustomHandlerType.DELETE)} className='flex justify-center items-center cursor-pointer bg-[#17203F]'>
                        <SvgIcon sx={{ width: 16, height: 16, color: COLORS.Green }} component={TrashIcon} inheritViewBox />
                    </div>
                </Tooltip>
            </div>
        )
    },
];

export const BillCreation = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedCustomer, setSelectedCustomer] = useState<User | null>(null);
    const [listCustomer, setListCustomer] = useState([]);
    const [isFoundUser, setIsFoundUser] = useState(false);
    const [isOpenAccountCreationForm, setIsOpenAccountCreationForm] = useState(false);
    const [isOpenBuyingServiceCreationForm, setIsOpenBuyingServiceCreationForm] = useState(false);
    const [isOpenBuyingProductCreationForm, setIsOpenBuyingProductCreationForm] = useState(false);
    const [isCreateNewBuyingProduct, setIsCreateNewBuyingProduct] = useState(true);
    const [isCreateNewBuyingService, setIsCreateNewBuyingService] = useState(true);
    const [listBuyingServiceUser, setListBuyingServiceUser] = useState([] as any);
    const [listBuyingProduct, setListBuyingProduct] = useState([] as any);
    const [currentBuyingServiceSelectedRow, setCurrentBuyingServiceSelectedRow] = useState({ Id: null });
    const [currentBuyingProductSelectedRow, setCurrentBuyingProductSelectedRow] = useState({ Id: null });
    const [totalBill, setTotalBill] = useState(0);
    const [totalPayment, setTotalPayment] = useState(0);
    const [justCreatedAccountPhoneNumber, setJustCreatedAccountPhoneNumber] = useState('');

    const getCustomer = async (phoneNumber: String) => {
        var res = await apiClient.get(`${ApiConfig.GET_CUSTOMER}?phoneNumber=${phoneNumber}`);
        if (res?.AppCode === 200) {
            setListCustomer(res.Data);
        }
        else {
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    const openBuyingServiceCreationForm = () => {
        setIsCreateNewBuyingService(true);
        setIsOpenBuyingServiceCreationForm(true);
    }

    const openBuyingProductCreationForm = () => {
        setIsCreateNewBuyingProduct(true);
        setIsOpenBuyingProductCreationForm(true);
    }

    const handleOnChange = (e: React.SyntheticEvent, value: any) => {
        setSelectedCustomer(value);
        handleOnRefresh();
    }

    const ignoreCaseAndRemoveAccents = (str: String) => {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
    }

    const renderOption = (props: any, option: any) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {option.PhoneNumber} {option.Fullname}
        </Box>
    );

    const openUserCreationForm = () => {
        setIsOpenAccountCreationForm(true);
    }

    const handleCloseAccountCreationForm = () => {
        setIsOpenAccountCreationForm(false);
    }

    const handleAccountCreationSuccess = (emittedData: any) => {
        handleCloseAccountCreationForm();
        dispatch(storeOpenSnackbar({ open: true, message: "Tạo tài khoản khách hàng thành công", type: "success" }));
        getCustomer("");

        if (emittedData) {
            setJustCreatedAccountPhoneNumber(emittedData.PhoneNumber)
        }
    }

    const handleInputChange = (event: any, newInputValue: any) => {
        let formattedValue = ignoreCaseAndRemoveAccents(newInputValue);

        var isHave = false;

        if (!formattedValue) {
            isHave = false
        }
        else {
            isHave = listCustomer.some(
                (x: any) => ignoreCaseAndRemoveAccents(`${x.PhoneNumber} ${x.Fullname}`).includes(formattedValue)
            );
        }

        setIsFoundUser(isHave);
    };

    const handleCloseBuyingServiceCreationForm = () => {
        setIsOpenBuyingServiceCreationForm(false);
    }

    const handleCloseBuyingProductCreationForm = () => {
        setIsOpenBuyingProductCreationForm(false);
    }

    const handleBuyingServiceCreationSuccess = (data: any) => {
        if (isCreateNewBuyingService) {
            setListBuyingServiceUser([...listBuyingServiceUser, data])
        }
        else {
            let index = listBuyingServiceUser.findIndex((x: any) => x.Id === data.Id);

            let newArr = listBuyingServiceUser;
            if (index !== -1) {
                newArr[index] = data;
            }
            setListBuyingServiceUser([...newArr])
        }
        handleCloseBuyingServiceCreationForm();
    }

    const handleBuyingProductCreationSuccess = (data: any) => {
        if (isCreateNewBuyingProduct) {
            setListBuyingProduct([...listBuyingProduct, data])
        }
        else {
            let index = listBuyingProduct.findIndex((x: any) => x.Id === data.Id);

            let newArr = listBuyingProduct;
            if (index !== -1) {
                newArr[index] = data;
            }
            setListBuyingProduct([...newArr])
        }
        handleCloseBuyingProductCreationForm();
    }

    const handleOnRefresh = () => {
        setListBuyingServiceUser([]);
        setListBuyingProduct([]);
    }

    const handleOnSaveBill = async () => {
        if (listBuyingProduct.length === 0 && listBuyingServiceUser.length === 0) {
            dispatch(storeOpenSnackbar({ open: true, message: "Vui lòng thêm ít nhất 1 sản phẩm hoặc dịch vụ mua", type: "warning" }));
            return;
        }

        var obj = {
            CustomerId: selectedCustomer?.UserId,
            ListServiceUserBill: listBuyingServiceUser.map((x: any) => {
                return {
                    UserId: selectedCustomer?.UserId,
                    ServiceId: x.ServiceId,
                    Price: x.Price,
                    FirstPayment: x.Payment || 0,
                    LocationId: x.LocationId,
                    Note: x.Note
                }
            }),
            ListProductUser: listBuyingProduct.map((x: any) => {
                return {
                    UserId: selectedCustomer?.UserId,
                    ProductId: x.ProductId,
                    Price: x.Price,
                    LocationId: x.LocationId
                }
            }),
        };

        try {
            dispatch(storeSetGlobalLoading(true));
            var { AppCode } = await apiClient.post(`${ApiConfig.BILL}`, obj);
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                // Thông báo thành công
                dispatch(storeOpenSnackbar({ open: true, message: "Tạo bill thành công!", type: "success" }));

                // Chuyển về trang Quản lý dịch vụ mua
                navigate(routes.PAYMENT_BUYING_SERVICE);
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: "Tạo bill thất bại", type: "error" }));
            }
        } catch (error: any) {
            dispatch(storeSetGlobalLoading(false));
            if (error?.response?.data?.AppCode === 500) {
                dispatch(storeOpenSnackbar({ open: true, message: error?.response?.data?.Message, type: "error" }));
            }
            else if (error?.response?.data?.AppCode === 400) {
                dispatch(storeOpenSnackbar({ open: true, message: "Thông tin đẩy lên sai định dạng", type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        }
    }

    const onRowBuyingServiceUserCustomHandler = (row: any, handlerType: any) => {
        setCurrentBuyingServiceSelectedRow(row);
        if (handlerType === rowCustomHandlerType.EDIT) {
            setIsCreateNewBuyingService(false);
            setIsOpenBuyingServiceCreationForm(true);
        }
        else if (handlerType === rowCustomHandlerType.DELETE) {
            let newArr = listBuyingServiceUser;
            let index = listBuyingServiceUser.findIndex((x: any) => x.Id === row.Id);
            if (index !== -1) {
                newArr.splice(index, 1);
                setListBuyingServiceUser([...newArr])
            }
        }
    }

    const onRowBuyingProductCustomHandler = (row: any, handlerType: any) => {
        setCurrentBuyingProductSelectedRow(row);
        if (handlerType === rowCustomHandlerType.EDIT) {
            setIsCreateNewBuyingProduct(false);
            setIsOpenBuyingProductCreationForm(true);
        }
        else if (handlerType === rowCustomHandlerType.DELETE) {
            let newArr = listBuyingProduct;
            let index = listBuyingProduct.findIndex((x: any) => x.Id === row.Id);
            if (index !== -1) {
                newArr.splice(index, 1);
                setListBuyingProduct([...newArr])
            }
        }
    }

    useEffect(() => {
        if (listCustomer.length > 0 && justCreatedAccountPhoneNumber) {
            let item = listCustomer.find((x: any) => x.PhoneNumber === justCreatedAccountPhoneNumber)
            if (item) {
                setSelectedCustomer(item);
            }
        }
    }, [listCustomer, justCreatedAccountPhoneNumber]);

    useEffect(() => {
        let thisTotalBill = 0;
        let thisTotalPayment = 0;

        for (let item of listBuyingServiceUser) {
            thisTotalBill += item.Price;
            thisTotalPayment += item.Payment;
        }

        for (let item of listBuyingProduct) {
            thisTotalBill += item.Price;
            thisTotalPayment += item.Price;
        }

        setTotalBill(thisTotalBill);
        setTotalPayment(thisTotalPayment);
    }, [listBuyingServiceUser, listBuyingProduct]);

    useEffect(() => {
        getCustomer("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <TopWrapper>
                <Title className="mb-4">Thông tin khách hàng</Title>
                <div className="flex items-center">
                    <FormControl sx={{ width: '450px' }} variant="standard">
                        <LabelControl>Khách hàng</LabelControl>
                        <HAutocomplete
                            placeholder="Chọn số điện thoại khách hàng để tạo lịch hẹn"
                            options={listCustomer}
                            value={selectedCustomer}
                            getOptionLabel={(option: any) => `${option.PhoneNumber} ${option.Fullname}`}
                            renderOption={renderOption}
                            onChange={handleOnChange}
                            onInputChange={handleInputChange}
                        />
                    </FormControl>
                    {
                        !isFoundUser
                        &&
                        <div className="ml-8 mt-3 italic">Không tìm thấy khách hàng, vui lòng <span onClick={openUserCreationForm} className="cursor-pointer underline font-bold text-[#1e40af]">tạo mới</span></div>
                    }
                </div>
                <div className="flex justify-between mt-4 items-center">
                    <div className="flex">
                        <div>Tổng bill: <span className="text-[#facc15]">{currencyFormat(totalBill) || 0}đ</span></div>
                        <div className="ml-5">Tổng thanh toán: <span className="text-[#16a34a]">{currencyFormat(totalPayment) || 0}đ</span></div>
                    </div>
                    <div>
                        <HButton startIcon={<RefreshIcon />} btnType="secondary" onClick={handleOnRefresh}>Làm mới</HButton>
                        <HButton sx={{ marginLeft: '16px' }} startIcon={<SaveIcon />} onClick={handleOnSaveBill}>Lưu toàn bộ thông tin</HButton>
                    </div>
                </div>
            </TopWrapper >
            {
                selectedCustomer &&
                <ContentWrapper>
                    <Grid container>
                        <Grid sx={{ paddingRight: '16px' }} item xs={6}>
                            <div className="flex justify-between items-center">
                                <b>Các dịch vụ mua</b>
                                <HButton startIcon={<AddIcon />} btnType="secondary" onClick={openBuyingServiceCreationForm} >Thêm dịch vụ</HButton>
                            </div>
                            <HTable
                                columns={serviceUserColumns}
                                listData={listBuyingServiceUser}
                                onRowCustomHandler={onRowBuyingServiceUserCustomHandler}
                            ></HTable>
                        </Grid>
                        <Grid sx={{ paddingRight: '0', paddingLeft: '16px' }} item xs={6}>
                            <div className="flex justify-between items-center">
                                <b>Các sản phẩm mua</b>
                                <HButton startIcon={<AddIcon />} btnType="secondary" onClick={openBuyingProductCreationForm} >Thêm sản phẩm</HButton>
                            </div>
                            <HTable
                                columns={buyingProductColumns}
                                listData={listBuyingProduct}
                                onRowCustomHandler={onRowBuyingProductCustomHandler}
                            ></HTable>
                        </Grid>
                    </Grid>
                </ContentWrapper>
            }
            {
                isOpenAccountCreationForm &&
                <CustomerAccountCreationForm
                    isOpen={isOpenAccountCreationForm}
                    handleOnClose={handleCloseAccountCreationForm}
                    handleOnConfirm={handleAccountCreationSuccess}
                >
                </CustomerAccountCreationForm>
            }
            {
                isOpenBuyingServiceCreationForm &&
                <BuyingServiceWithPaymentForm
                    isOpen={isOpenBuyingServiceCreationForm}
                    data={currentBuyingServiceSelectedRow}
                    isCreateNew={isCreateNewBuyingService}
                    handleOnClose={handleCloseBuyingServiceCreationForm}
                    handleOnConfirm={handleBuyingServiceCreationSuccess}
                >
                </BuyingServiceWithPaymentForm>
            }
            {
                isOpenBuyingProductCreationForm &&
                <BuyingProductWithPaymentForm
                    isOpen={isOpenBuyingProductCreationForm}
                    data={currentBuyingProductSelectedRow}
                    isCreateNew={isCreateNewBuyingProduct}
                    handleOnClose={handleCloseBuyingProductCreationForm}
                    handleOnConfirm={handleBuyingProductCreationSuccess}
                >
                </BuyingProductWithPaymentForm>
            }
        </>
    )
}

const styles = {
    tooltipWrapper: {
        backgroundColor: COLORS.YankeesBlue,
        width: 32,
        height: 32,
        borderRadius: '6px'
    }
}