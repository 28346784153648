import { HButton } from "@components/Button";
import { HFullScreenDialog } from "@components/Dialog";
import { styled } from "@mui/material/styles";
import { COLORS } from "@shared/constants/colors";
import { Title } from "@shared/styledComponents";
import { useEffect, useState } from "react";
import { BookingCreation } from "./bookingCreation";
import { Box } from "@mui/material";
import { HTab, HTabs, TabPanel } from "@components/Tab";
import { storeOpenSnackbar } from "@store/globalReducer";
import { useDispatch } from "react-redux";
import { AllBookingTab } from "./allBookingTab";
import { NotYetBookingTab } from "./notYetBookingTab";
import { RescheduleBookingTab } from "./rescheduleBookingTab";
import { a11yProps } from "@shared/helpers/common";
import { useLocation } from "react-router-dom";

const Wrapper = styled('div')(() => ({
    width: '100%',
    height: '100%',
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: COLORS.GhostWhite
}));

export const BookingManagement = () => {
    const dispatch = useDispatch();
    const { state } = useLocation();

    const [open, setOpen] = useState(false);
    const [isReload, setIsReload] = useState(false);

    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const handleOnCloseDialog = () => {
        setOpen(false);
        setTimeout(() => {
            setIsReload(true);
        }, 500)
    }

    const handleCreateBookingSuccess = () => {
        handleOnCloseDialog();
        dispatch(storeOpenSnackbar({ open: true, message: "Tạo lịch hẹn thành công", type: "success" }));
    }

    useEffect(() => {
        // Nếu activeTab được truyền vào là 3 thì điều hướng về Tab Yêu cầu đổi lịch
        if (state?.activeTab === 3) {
            setValue(2);
        }
    }, [state])

    return (
        <Wrapper>
            <div className="w-full flex justify-between">
                <Title className="mb-4">Quản lý lịch hẹn</Title>
                {/* <HButton onClick={() => { setOpen(true) }}>Tạo mới lịch hẹn</HButton>  */}
                <HFullScreenDialog
                    title="Tạo mới lịch hẹn"
                    fullScreen
                    open={open}
                    handleClose={handleOnCloseDialog}
                    dialogFooter={
                        <div className="w-full h-full items-center justify-end flex px-6">
                            <HButton>abc</HButton>
                        </div>
                    }
                >
                    <BookingCreation handleCreateSuccess={handleCreateBookingSuccess}></BookingCreation>
                </HFullScreenDialog>
            </div>
            <Box sx={{ width: '100%' }}>
                <HTabs
                    value={value}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                >
                    <HTab {...a11yProps(0)} label="Lịch hẹn" />
                    <HTab {...a11yProps(1)} label="Chưa có lịch hẹn - Dịch vụ tạo mới" />
                    <HTab {...a11yProps(2)} label="Yêu cầu đổi lịch" />
                </HTabs>
            </Box>
            <TabPanel value={value} index={0}>
                <AllBookingTab isReload={isReload} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <NotYetBookingTab />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <RescheduleBookingTab />
            </TabPanel>
        </Wrapper>
    )
}