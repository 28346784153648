import { AppToolbar } from '@layout/toolbar';
import { styled } from '@mui/material/styles';
import { COLORS } from '@shared/constants/colors';
import { ErrorValidation, HDialogTitle, LabelControl, Title } from "@shared/styledComponents";
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid } from '@mui/material';
import { Input } from '@components/Input';
import { HButton } from "@components/Button";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { HTextField } from '@components/Input/HTextFiled';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { ApiConfig } from '@config/index';
import { apiClient } from '@services/baseAxios';
import { selectAuth } from "@store/authReducer";
import { useSelector, useDispatch } from "react-redux";
import { storeSetGlobalLoading, storeOpenSnackbar } from "@store/globalReducer";
import moment from 'moment';

const UserInfoWrapper = styled('div')(() => ({
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: COLORS.MainBackground
}));

const Content = styled('div')(() => ({
    flexGrow: 1,
    padding: '16px',
    margin: '16px',
    backgroundColor: COLORS.White
}));

export const UserInfo = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { tokenClaims } = useSelector(selectAuth);

    const [open, setOpen] = useState(false);
    const [userInfo, setUserInfo] = useState({} as any);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showNewPasswordAgain, setShowNewPasswordAgain] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordAgain, setNewPasswordAgain] = useState('');
    const [isTouch, setIsTouch] = useState(false);

    useEffect(() => {
        // Get user info
        getUserInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUserInfo = async () => {
        const res = await apiClient.get(`${ApiConfig.USER}/${tokenClaims.user_id}`);
        if (res?.AppCode === 200) {
            setUserInfo(res.Data);
        }
    }

    const handleOpenDialog = () => {
        setOpen(true);
    };

    const handleChangePassword = async () => {
        setIsTouch(true);

        if (!currentPassword || !newPassword || !newPasswordAgain) return;

        dispatch(storeSetGlobalLoading(true));
        const res = await apiClient.post(`${ApiConfig.UPDATE_PASSWORD}`, {
            PhoneNumber: tokenClaims.phone_number,
            CurrentPassword: currentPassword,
            NewPassword: newPassword
        });
        dispatch(storeSetGlobalLoading(false));
        if (res?.AppCode === 200) {
            handleCloseDialog();
            dispatch(storeOpenSnackbar({ open: true, message: "Đổi mật khẩu thành công!", type: "success" }));
        }
        else {
            dispatch(storeOpenSnackbar({ open: true, message: "Đổi mật khẩu thất bại!", type: "error" }));
        }
    }

    const handleCloseDialog = () => {
        setOpen(false);
    };

    const toggleDisplayPassword = (type: string) => {
        switch (type) {
            case "current":
                setShowCurrentPassword(!showCurrentPassword);
                break;
            case "new":
                setShowNewPassword(!showNewPassword);
                break;
            case "new-again":
                setShowNewPasswordAgain(!showNewPasswordAgain);
                break;
        }
    };

    const handleTextInputChange = (event: any, field: string) => {
        const value = event.target.value;
        switch (field) {
            case 'password':
                setCurrentPassword(value);
                break;
            case 'new-password':
                setNewPassword(value);
                break;
            case 'new-password-again':
                setNewPasswordAgain(value);
                break;
        }
    }

    return (
        <UserInfoWrapper>
            <AppToolbar className='w-full' />
            <Content>
                <div className='flex'>
                    <ChevronLeftOutlinedIcon onClick={() => navigate(-1)} className='cursor-pointer' />
                    <Title className='ml-1'>Thông Tin Cá Nhân</Title>
                </div>
                <div className='mt-4'>
                    <Grid container>
                        <Grid sx={{ pt: 0, pl: 0 }} item xs={6}>
                            <FormControl disabled sx={{ width: '100%', paddingRight: '12px' }} variant="standard">
                                <LabelControl>Họ và tên</LabelControl>
                                <Input value={userInfo.Fullname || ""} sx={{ width: '100%' }} placeholder="Họ và tên" />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl disabled sx={{ width: '100%', paddingLeft: '12px' }} variant="standard">
                                <LabelControl>Ngày sinh</LabelControl>
                                <Input value={userInfo.DateOfBirth ? moment(userInfo.DateOfBirth).format("DD/MM/YYYY") : ""} sx={{ width: '100%' }} />
                            </FormControl>
                        </Grid>
                        <Grid sx={{ pt: 0, pl: 0, mt: 2 }} item xs={6}>
                            <FormControl disabled sx={{ width: '100%', paddingRight: '12px' }} variant="standard">
                                <LabelControl>Số điện thoại</LabelControl>
                                <Input value={userInfo.PhoneNumber || ""} sx={{ width: '100%' }} placeholder="Số điện thoại" />
                            </FormControl>
                        </Grid>
                        <Grid sx={{ mt: 2 }} item xs={6}>
                            <FormControl disabled sx={{ width: '100%', paddingLeft: '12px' }} variant="standard">
                                <LabelControl>Email</LabelControl>
                                <Input value={userInfo.Email || ""} sx={{ width: '100%' }} />
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
                <div className='mt-8'>
                    <Title>Thông Tin Tài Khoản</Title>
                    <Grid className='mt-4' container>
                        <Grid sx={{ pt: 0, pl: 0 }} item xs={12}>
                            <FormControl disabled sx={{ width: '100%' }} variant="standard">
                                <LabelControl>Thông tin đăng nhập</LabelControl>
                                <Input value={userInfo.PhoneNumber || ""} sx={{ width: '100%' }} placeholder="Thông tin đăng nhập" />
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
                <div className='mt-4 flex justify-end'>
                    <HButton onClick={handleOpenDialog}>Đổi Mật Khẩu</HButton>
                    <Dialog PaperProps={{
                        sx: {
                            width: "400px",
                        }
                    }} open={open} onClose={handleCloseDialog}>
                        <DialogTitle>
                            <HDialogTitle>
                                Đổi mật khẩu
                            </HDialogTitle>
                        </DialogTitle>
                        <DialogContent>
                            <FormControl disabled sx={{ width: '100%' }} variant="standard">
                                <LabelControl>Số điện thoại</LabelControl>
                                <Input value={userInfo.PhoneNumber} sx={{ width: '100%' }} placeholder="Thông tin đăng nhập" />
                            </FormControl>
                            <FormControl sx={{ width: '100%', mt: 2 }} variant="standard">
                                <LabelControl required>Mật khẩu hiện tại</LabelControl>
                                <HTextField
                                    fullWidth
                                    value={currentPassword}
                                    onChange={(event: any) => handleTextInputChange(event, 'password')}
                                    type={showCurrentPassword ? "text" : "password"}
                                    placeholder="Nhập mật khẩu hiện tại"
                                    iconEnd={showCurrentPassword ? <VisibilityIcon onClick={() => toggleDisplayPassword("current")}
                                        className="cursor-pointer" /> : <VisibilityOffIcon
                                        onClick={() => toggleDisplayPassword("current")}
                                        className="cursor-pointer" />}
                                />
                            </FormControl>
                            <FormControl sx={{ width: '100%', mt: 2 }} variant="standard">
                                <LabelControl required>Mật khẩu mới</LabelControl>
                                <HTextField
                                    fullWidth
                                    value={newPassword}
                                    onChange={(event: any) => handleTextInputChange(event, 'new-password')}
                                    type={showNewPassword ? "text" : "password"}
                                    placeholder="Nhập mật khẩu mới"
                                    iconEnd={showNewPassword ? <VisibilityIcon onClick={() => toggleDisplayPassword("new")}
                                        className="cursor-pointer" /> : <VisibilityOffIcon
                                        onClick={() => toggleDisplayPassword("new")}
                                        className="cursor-pointer" />}
                                />
                            </FormControl>
                            <FormControl required sx={{ width: '100%', mt: 2 }} variant="standard">
                                <LabelControl required>Nhập lại mật khẩu mới</LabelControl>
                                <HTextField
                                    fullWidth
                                    value={newPasswordAgain}
                                    onChange={(event: any) => handleTextInputChange(event, 'new-password-again')}
                                    type={showNewPasswordAgain ? "text" : "password"}
                                    placeholder="Nhập lại mật khẩu mới"
                                    iconEnd={showNewPasswordAgain ? <VisibilityIcon onClick={() => toggleDisplayPassword("new-again")}
                                        className="cursor-pointer" /> : <VisibilityOffIcon
                                        onClick={() => toggleDisplayPassword("new-again")}
                                        className="cursor-pointer" />}
                                />
                                {
                                    (newPassword !== newPasswordAgain && isTouch) &&
                                    <ErrorValidation>Mật khẩu không giống.</ErrorValidation>
                                }
                            </FormControl>
                        </DialogContent>
                        <DialogActions sx={{ px: 3, pb: 2 }} >
                            <HButton startIcon={<CloseOutlinedIcon />} btnType="secondary" onClick={handleCloseDialog}>Hủy</HButton>
                            <HButton startIcon={<DoneOutlinedIcon />} onClick={handleChangePassword}>Đổi Mật Khẩu</HButton>
                        </DialogActions>
                    </Dialog>
                </div>

            </Content>
        </UserInfoWrapper >
    )
}