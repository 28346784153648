export const DISABLE_SERIES = true;

export const ENV_NAME = {
    DEVELOP: 0,
    TEST: 1,
    STAGING: 2,
    PRODUCT: 3,
};

export const ENVIRONMENT = ENV_NAME.DEVELOP;

const ApiURLs = [
    'https://acms-wiki-presentation.dev4.amelacorp.com/api/', // dev
    'https://acms-wiki-presentation.dev4.amelacorp.com/api/', // test
    'https://acms-wiki-presentation.dev4.amelacorp.com/api/', // staging
    'https://acms-wiki-presentation.dev4.amelacorp.com/api/', // product
];

const configs = {
    APP_ENV: ENVIRONMENT,
    API_DOMAIN: ApiURLs[ENVIRONMENT],
    API_DOMAIN_LOGIN: process.env.PAGE_LOGIN,
    systemAdminId: process.env.REACT_APP_SYSTEM_ADMIN_ID,
    DOMAIN_AMS_ME: process.env.REACT_APP_DOMAIN_AMS_ME,
    DOMAIN_AMS_WORKFLOW: process.env.REACT_APP_DOMAIN_AMS_WORKFLOW,
    DOMAIN_AMS_RECRUIT: process.env.REACT_APP_DOMAIN_AMS_RECRUIT,
    DOMAIN_AMS_BO: process.env.REACT_APP_DOMAIN_AMS_BO,
    DOMAIN_AMS_WIKI: process.env.REACT_APP_DOMAIN_AMS_Wiki,
};

export const ApiConfig = {
    LOGIN: `/auths/authenticate`,
    UPDATE_PASSWORD: `/auths/password-changing`,
    USER: `/users`,
    SERVICE_USER_PROCESS: `/service-user-processes`,
    GET_SERVICE_USER_PROCESS_PAGING: `/service-user-processes/paging`,
    LOCAION: `/locations`,
    GET_ALL_SPA_LOCATION: `/locations/my-spas`,
    GET_SPA_LOCATION_PAGING: `/locations/paging`,
    GET_ALL_ROLE: `/auths/roles`,
    GET_ALL_GROUP_ARTICLE: `/articles/group/all`,
    GET_ALL_PROVINCE: `/locations/provinces`,
    GET_DISTRICT: `/locations/districts`,
    GET_WARD: `/locations/wards`,
    BOOKING: `/bookings`,
    GET_BOOKING_PAGING: `/bookings/paging`,
    GET_USER_PAGING: `/users/paging`,
    GET_DELETED_USER_PAGING: `/users/deleted/paging`,
    GET_CUSTOMER_PAGING: `/users/customers/paging`,
    GET_CUSTOMER: `users/customers`,
    SERVICE_USER: `/service-users`,
    SEARCH_SERVICE_USER: `/service-users/search`,
    GET_SERVICE_USER_PAGING: `/service-users/paging`,
    GET_SERVICE_USER_BY_USER: `/service-users/user`,
    PAYMENT: `/payments`,
    PRODUCT: `/products`,
    PRODUCT_TYPE: `/products/types`,
    SERVICE: `/spa-services`,
    PRODUCT_USER: `/product-users`,
    CUSTOMER_FEEDBACK: `/customer-feedback`,
    ARTICLE: `/articles`,
    FILE: `/files`,
    NOTIFICATION: `notifications`,
    NOTIFICATION_ADMINISTRATION: `notification-administrations`,
    REGISTER_VERIFICATION: '/auths/register-verification',
    OTP_VERIFICATION: '/auths/otp-verification',
    SIGN_UP_USER: `/auths/register`,
    SIGN_UP_VERIFIED_USER: `/auths/register-verified-user`,
    GET_ALL_PRODUCT: `/products`,
    DASHBOARD: `/dashboard`,
    BILL: `/bill`
}

export default configs;
