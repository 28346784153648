import { FormControl, Grid, MenuItem, SvgIcon } from "@mui/material";
import { TableWrapper, Wrapper } from "./common";
import { LabelControl } from "@shared/styledComponents";
import { HSelect } from "@components/Select";
import { HTable } from "@components/Table";
import { ColumnAction } from "@components/Table/ColumnAction";
import { Column, PaginatedResult } from "types/common";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { storeSetSpaLocations } from "@store/directoryReducer";
import { apiClient } from "@services/baseAxios";
import { ApiConfig } from "@config/index";
import { COLORS } from "@shared/constants/colors";
import { ReactComponent as EditIcon } from '@assets/icons/icon-edit.svg';
import { Input } from "@components/Input";
import { BOOKING_STATUS_MAPPING, bookingStatus, systemRole } from "@shared/constants/common";
import moment from "moment";
import { HDialog } from "@components/Dialog";
import { HButton } from "@components/Button";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { HDatePicker } from "@components/DateTimePicker";
import dayjs, { Dayjs } from "dayjs";
import { storeOpenSnackbar, storeSetGlobalLoading } from "@store/globalReducer";
import { errorMessages } from "@shared/constants/messages";
import { useAppSelector } from "@hooks/useSelector/useAppSelector";

const columns: readonly Column[] = [
    {
        id: 'ServiceName',
        label: 'Tên liệu trình',
        align: 'left',
        minWidth: 180
    },
    {
        id: 'CustomerName',
        label: 'Họ tên khách hàng',
        minWidth: 160,
        transform: (data: any) => (<div style={{ fontWeight: 'bold' }}>{data.value}</div>)
    },
    {
        id: 'CustomerPhone',
        label: 'Số điện thoại',
        minWidth: 120,
        align: 'left',
    },
    {
        id: 'MemberShipClass',
        label: 'Hạng khách hàng',
        minWidth: 140,
        align: 'left',
        transform: (data: any) => (data.value ? <span style={{ color: COLORS.Tertiary }}>{data.value}</span> : 'Standard')
    },
    {
        id: 'LocationAddress',
        label: 'Cơ sở',
        align: 'left',
        minWidth: 180
    },
    {
        id: 'BookingTime',
        label: 'Ngày hẹn hiện tại',
        align: 'center',
        minWidth: 180,
        transform: (data: any) => (data.value ? moment(data.value).format("DD/MM/YYYY") : '--/--/----')
    },
    {
        id: 'BookingTimeProposal',
        label: 'Ngày mong muốn đổi',
        align: 'center',
        minWidth: 180,
        transform: (data: any) => (data.value ? moment(data.value).format("DD/MM/YYYY") : '--/--/----')
    },
    {
        id: 'BookingStatusName',
        label: 'Trạng thái',
        minWidth: 120,
        align: 'left',
        transform: (data: any) => (BOOKING_STATUS_MAPPING[data.value])
    },
    {
        id: 'Actions',
        align: 'center',
        label: 'Tác vụ',
        minWidth: 80,
        transform: (value: any) => (<ColumnAction tooltip="Sửa lịch hẹn" onHandler={value.onHandler}><SvgIcon sx={{ width: 16, height: 16 }} component={EditIcon} inheritViewBox /></ColumnAction>)
    },
];

export const RescheduleBookingTab = () => {
    const dispatch = useDispatch();
    const { spaLocations } = useSelector((state: any) => state.directory);
    var { tokenClaims } = useAppSelector(state => state.auth);
    const isBranchAccountant = tokenClaims.role === systemRole.BRANCH_ACCOUNTANT;

    const [updatedBookingDate, setUpdatedBookingDate] = useState<Dayjs | null>(null);
    const [tableLoading, setTableLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [selectedLocation, setSelectedLocation] = useState(tokenClaims.location_id || "-1");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isOpenDialog, setisOpenDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState({} as any);
    const [paginatedData, setPaginatedData] = useState<PaginatedResult>({
        ListOut: [],
        TotalCount: 0,
        PageStart: 1,
        PageEnd: 1,
        TotalPage: 0
    } as PaginatedResult);

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangeLocation = (event: { target: { value: string } }) => {
        setSelectedLocation(event.target.value);
        setPage(0);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const closeDialog = () => {
        setisOpenDialog(false);
    }

    const getSpaLocation = async () => {
        if (spaLocations?.length > 0) return;

        const res = await apiClient.get(ApiConfig.GET_ALL_SPA_LOCATION);
        if (res?.AppCode === 200) {
            dispatch(storeSetSpaLocations(res.Data));
        }
        else {
            // setSpaLocations([])
        }
    }

    const handleChangePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(event.target.value);
        setPage(0);
    };

    const getData = async () => {
        setTableLoading(true);
        const res = await apiClient.post(ApiConfig.GET_BOOKING_PAGING, getObjectFilter());
        setTableLoading(false);
        if (res?.AppCode === 200) {
            setPaginatedData((prevState: PaginatedResult) => ({
                ...prevState,
                ...res.Data
            }));
        }
    }

    const getObjectFilter = () => {
        return {
            Keyword: phoneNumber,
            PageIndex: page + 1,
            PageSize: rowsPerPage,
            LocationId: selectedLocation === "-1" ? null : selectedLocation,
            Status: bookingStatus.CHANGING
        };
    }

    const updateChangingBooking = async () => {
        if (!updatedBookingDate) {
            dispatch(storeOpenSnackbar({ open: true, message: "Vui lòng chọn ngày", type: "warning" }));
            return;
        }

        var updatedBookingDateVNTime = dayjs(updatedBookingDate).add(7, 'hours') || null;

        try {
            dispatch(storeSetGlobalLoading(true));
            var { AppCode } = await apiClient.put(`${ApiConfig.BOOKING}/save-changing/${selectedRow.Id}`, { BookingTime: updatedBookingDateVNTime });
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                dispatch(storeOpenSnackbar({
                    open: true, message: "Cập nhật lịch hẹn thành công", type: "success"
                }));
                closeDialog();
                getData();
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: "Cập nhật lịch hẹn thất bại, vui lòng thử lại sau", type: "error" }));
            }
        }
        catch (e) {
            dispatch(storeSetGlobalLoading(false));
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    useEffect(() => {
        getSpaLocation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onHandler = (item: any) => {
        setSelectedRow(item);
        setUpdatedBookingDate(item.BookingTime);
        setisOpenDialog(true)
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, phoneNumber, selectedLocation]);

    useEffect(() => {
        setSelectedLocation(tokenClaims.location_id || "-1");
    }, [tokenClaims.location_id])

    return (
        <Wrapper>
            {/* Toolbar */}
            <Grid container>
                {/* Row 1 */}
                <Grid item xs={6}>
                    <FormControl sx={{ width: '100%', paddingRight: '12px' }} variant="standard">
                        <LabelControl>Số điện thoại</LabelControl>
                        <Input onChange={handleChangePhoneNumber} value={phoneNumber} sx={{ width: '100%' }} placeholder="Tìm kiếm theo số điện thoại" />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth variant="standard">
                        <LabelControl>Cơ sở</LabelControl>
                        <HSelect disabled={isBranchAccountant} fullWidth value={selectedLocation}
                            onChange={handleChangeLocation}>
                            <MenuItem key={-1} value={"-1"}>Tất cả</MenuItem>
                            {
                                spaLocations.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                            }
                        </HSelect>
                    </FormControl>
                </Grid>
            </Grid>
            {/* Table */}
            <TableWrapper>
                <HTable
                    columns={columns}
                    pagingInfo={{
                        rowsPerPage,
                        page,
                        totalCount: paginatedData.TotalCount || 0,
                        handleChangePage,
                        handleChangeRowsPerPage
                    }}
                    loading={tableLoading}
                    listData={paginatedData.ListOut}
                    onRowHandler={onHandler}
                ></HTable>
            </TableWrapper>
            {/* Dialog */}
            <HDialog
                PaperProps={{
                    sx: {
                        width: "1000px",
                    }
                }}
                title="Sửa lịch hẹn"
                open={isOpenDialog}
                handleClose={closeDialog}
                dialogFooter={
                    <>
                        <HButton startIcon={< CloseOutlinedIcon />} btnType="secondary" onClick={closeDialog}>Hủy</HButton >
                        <HButton startIcon={<DoneOutlinedIcon />} onClick={updateChangingBooking}>Xác nhận</HButton>
                    </>
                }
            >
                <Grid container>
                    {/* Row 1 */}
                    <Grid item xs={6}>
                        <FormControl disabled sx={{ width: '100%', paddingRight: '12px' }} variant="standard">
                            <LabelControl>Tên khách hàng</LabelControl>
                            <Input value={selectedRow.CustomerName} sx={{ width: '100%' }} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl disabled sx={{ width: '100%', paddingRight: '12px' }} variant="standard">
                            <LabelControl>Số điện thoại</LabelControl>
                            <Input value={selectedRow.CustomerPhone} sx={{ width: '100%' }} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl disabled sx={{ width: '100%', paddingRight: '8px' }} variant="standard">
                            <LabelControl>Hạng khách hàng</LabelControl>
                            <Input value={selectedRow.MembershipClass || "Standard"} sx={{ width: '100%' }} />
                        </FormControl>
                    </Grid>
                    {/* Row 2 */}
                    <Grid sx={{ marginTop: '16px' }} item xs={6}>
                        <FormControl disabled sx={{ width: '100%', paddingRight: '12px' }} variant="standard">
                            <LabelControl>Tên liệu trình</LabelControl>
                            <Input value={selectedRow.ServiceName} sx={{ width: '100%' }} />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ marginTop: '16px' }} item xs={6}>
                        <FormControl disabled sx={{ width: '100%', paddingRight: '8px' }} variant="standard">
                            <LabelControl>Cơ sở</LabelControl>
                            <Input value={selectedRow.LocationAddress} sx={{ width: '100%' }} />
                        </FormControl>
                    </Grid>
                    {/* Row 3 */}
                    <Grid sx={{ marginTop: '16px' }} item xs={6}>
                        <FormControl sx={{ width: '100%', paddingRight: '12px' }} variant="standard">
                            <LabelControl required>Ngày hẹn thực tế</LabelControl>
                            <HDatePicker
                                value={updatedBookingDate}
                                ampm={false}
                                disablePast
                                inputFormat="DD/MM/YYYY"
                                sx={{ width: '100%' }}
                                onChange={(newValue: any) => {
                                    setUpdatedBookingDate(newValue);
                                }}>
                            </HDatePicker>
                        </FormControl>
                    </Grid>
                    <Grid sx={{ marginTop: '16px' }} item xs={6}>
                        <FormControl disabled sx={{ width: '100%', paddingRight: '8px' }} variant="standard">
                            <LabelControl>Ngày hẹn mong muốn đổi</LabelControl>
                            <Input value={moment(selectedRow.BookingTimeProposal).format("DD/MM/YYYY")} sx={{ width: '100%' }} />
                        </FormControl>
                    </Grid>
                </Grid>
            </HDialog>
        </Wrapper>
    )
}