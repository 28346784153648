import { HButton } from "@components/Button"
import { HDialog } from "@components/Dialog"
import { Box, FormControl, Grid, MenuItem } from "@mui/material"
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AddIcon from '@mui/icons-material/Add';
import { LabelControl, MenuItemPlaceholder } from "@shared/styledComponents";
import { HSelect } from "@components/Select";
import { useEffect, useState } from "react";
import { apiClient } from "@services/baseAxios";
import { ApiConfig } from "@config/index";
import { storeSetAllProduct, storeSetSpaLocations } from "@store/directoryReducer";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@hooks/useSelector/useAppSelector";
import { HAutocomplete } from "@components/Autocomplete";
import { HTextField } from "@components/Input/HTextFiled";
import { ReactComponent as VNDIcon } from '@assets/icons/icon-vnd.svg';
import { currencyFormat, generateUUID } from "@shared/helpers/common";
import { storeOpenSnackbar } from "@store/globalReducer";
import { systemRole } from "@shared/constants/common";

export const BuyingProductWithPaymentForm = ({ isOpen, isCreateNew, data, handleOnClose, handleOnConfirm }: any) => {
    const dispatch = useDispatch();
    const { spaLocations, allProduct } = useAppSelector((state: any) => state.directory);
    var { tokenClaims } = useAppSelector(state => state.auth);
    const isBranchAccountant = tokenClaims.role === systemRole.BRANCH_ACCOUNTANT;
    const [selectedLocationId, setSelectedLocationId] = useState(tokenClaims.location_id || "-1");
    const [selectedProduct, setSelectedProduct] = useState({ Id: null, Code: '', Name: '' });
    const [moneyInput, setMoneyInput] = useState('');

    const handleChangeLocation = (event: { target: { value: string } }) => {
        setSelectedLocationId(event.target.value);
    };

    const getAllProduct = async () => {
        if (allProduct?.length > 0) return;

        const res = await apiClient.get(ApiConfig.GET_ALL_PRODUCT);
        if (res?.AppCode === 200) {
            dispatch(storeSetAllProduct(res.Data));
        }
    }

    const handleOnChangeProduct = (e: React.SyntheticEvent, value: any) => {
        setSelectedProduct(value);
    }

    const getSpaLocation = async () => {
        if (spaLocations?.length > 0) return;

        const res = await apiClient.get(ApiConfig.GET_ALL_SPA_LOCATION);
        if (res?.AppCode === 200) {
            dispatch(storeSetSpaLocations(res.Data));
        }
    }

    const generateBuyingProductUserObject = () => {
        return {
            Id: isCreateNew ? generateUUID() : data.Id,
            ProductId: selectedProduct?.Id,
            ProductName: selectedProduct?.Name,
            LocationId: selectedLocationId,
            Price: parseInt(moneyInput.replace(/\D/g, ''))
        };
    }

    const handleSubmit = () => {
        if (!selectedProduct?.Id || !selectedLocationId || selectedLocationId === "-1" || !moneyInput) {
            dispatch(storeOpenSnackbar({ open: true, message: "Vui lòng nhập đủ thông tin", type: "warning" }));
            return;
        }

        // createOrUpdateBuyingProductUser();
        let emittedData = generateBuyingProductUserObject();
        handleOnConfirm(emittedData);
    }

    const handleTextInputChange = (event: any, field: string) => {
        const value = event.target.value;
        switch (field) {
            case 'money':
                var a = value.replace(/\D/g, "");
                var data = currencyFormat(parseInt(a)).toString();

                setMoneyInput(data);
                break;
        }
    }

    useEffect(() => {
        getSpaLocation();
        getAllProduct();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isCreateNew) {
            setMoneyInput(currencyFormat(parseInt(data.Price)).toString());
            if (spaLocations?.length > 0) {
                setSelectedLocationId(data.LocationId);
            }
            if (allProduct?.length > 0) {
                let product = allProduct.find((x: any) => x.Id === data.ProductId);
                if (product) {
                    setSelectedProduct(product)
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [spaLocations, allProduct])

    const renderOptionProduct = (props: any, option: any) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {option.Name}
        </Box>
    );

    return (
        <HDialog
            PaperProps={{
                sx: {
                    width: "450px",
                }
            }}
            title={isCreateNew ? "Thêm mới sản phẩm" : "Chỉnh sửa sản phẩm"}
            open={isOpen}
            handleClose={handleOnClose}
            dialogFooter={
                <>
                    <HButton startIcon={<CloseOutlinedIcon />} btnType="secondary" onClick={handleOnClose}>Hủy</HButton>
                    {
                        isCreateNew ?
                            <HButton startIcon={<AddIcon />} onClick={handleSubmit}>Thêm sản phẩm</HButton>
                            :
                            <HButton startIcon={<DoneOutlinedIcon />} onClick={handleSubmit}>Cập nhật</HButton>
                    }

                </>
            }
        >
            <Grid container>
                <Grid sx={{ pt: 0, pl: 0 }} item xs={12}>
                    <FormControl fullWidth variant="standard">
                        <LabelControl required>Sản phẩm</LabelControl>
                        <HAutocomplete
                            disabled={!isCreateNew}
                            value={selectedProduct}
                            placeholder="Chọn sản phẩm"
                            options={allProduct}
                            getOptionLabel={(option: any) => option.Name}
                            renderOption={renderOptionProduct}
                            onChange={handleOnChangeProduct}
                        />
                    </FormControl>
                </Grid>
                <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={12}>
                    <FormControl fullWidth variant="standard">
                        <LabelControl required>Cơ sở</LabelControl>
                        <HSelect disabled={!isCreateNew || isBranchAccountant} fullWidth value={selectedLocationId}
                            onChange={handleChangeLocation}>
                            <MenuItem key={-1} disabled value="-1">
                                <MenuItemPlaceholder>Chọn cơ sở</MenuItemPlaceholder>
                            </MenuItem>
                            {
                                spaLocations.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                            }
                        </HSelect>
                    </FormControl>
                </Grid>
                <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={12}>
                    <FormControl fullWidth variant="standard">
                        <LabelControl required>Giá bán</LabelControl>
                        <HTextField
                            fullWidth
                            value={moneyInput}
                            onChange={(event: any) => handleTextInputChange(event, 'money')}
                            placeholder="Nhập giá bán"
                            iconEnd={<VNDIcon />}
                        />
                    </FormControl>
                </Grid>
                <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={12}>
                    <FormControl disabled fullWidth variant="standard">
                        <LabelControl required>Thanh toán</LabelControl>
                        <HTextField
                            fullWidth
                            disabled
                            value={moneyInput}
                            placeholder="Nhập số tiền thanh toán"
                            iconEnd={<VNDIcon />}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </HDialog>
    )
}