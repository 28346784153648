import { HButton } from "@components/Button";
import { styled } from "@mui/material/styles";
import { COLORS } from "@shared/constants/colors";
import { LabelControl, Title } from "@shared/styledComponents";
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import { FormControl, Grid } from "@mui/material";
import { Input } from "@components/Input";
import { HDatePicker } from "@components/DateTimePicker";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { Column, PaginatedResult } from "types/common";
import { HTable } from "@components/Table";
import { apiClient } from "@services/baseAxios";
import { ApiConfig } from "@config/index";
import { storeOpenSnackbar, storeSetGlobalLoading } from "@store/globalReducer";
import { errorMessages } from "@shared/constants/messages";
import { useDispatch } from "react-redux";
import { currencyFormat, downloadFile } from "@shared/helpers/common";
import { useAppSelector } from "@hooks/useSelector/useAppSelector";
import { systemRole } from "@shared/constants/common";

const Wrapper = styled('div')(() => ({
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.GhostWhite,
    padding: '16px',
    display: 'flex',
    flexDirection: 'column'
}));

const ToolbarWrapper = styled('div')(() => ({
    padding: "16px 0px"
}));

const columns: readonly Column[] = [
    {
        id: 'STT',
        label: 'STT',
        align: 'left',
        minWidth: 30,
        transform: (data: any) => (data.index + 1)
    },
    { id: 'CustomerName', label: 'Tên khách hàng', align: 'left', minWidth: 150, transform: (data: any) => (<div style={{ fontWeight: 'bold' }}>{data.value}</div>) },
    {
        id: 'CustomerPhone',
        label: 'Số điện thoại',
        align: 'left',
        minWidth: 120,
    },
    {
        id: 'ProductName',
        label: 'Tên sản phẩm',
        minWidth: 150,
        align: 'left',
    },
    {
        id: 'Price',
        label: 'Giá bán',
        minWidth: 140,
        align: 'right',
        transform: (data: any) => (<span style={{ color: COLORS.MagicPotion }}>{currencyFormat(data.value)}đ</span>)
    },
];

export const BuyingProductAdministration = () => {
    const dispatch = useDispatch();
    var { tokenClaims } = useAppSelector((state: any) => state.auth);
    const isBranchManager = tokenClaims.role === systemRole.BRANCH_MANAGER;

    const [keyword, setKeyword] = useState('');
    const [fromDate, setFromDate] = useState<Dayjs | null>(null);
    const [toDate, setToDate] = useState<Dayjs | null>(null);
    const [tableLoading, setTableLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [paginatedData, setPaginatedData] = useState<PaginatedResult>({
        ListOut: [],
        TotalCount: 0,
        PageStart: 1,
        PageEnd: 1,
        TotalPage: 0
    } as PaginatedResult);

    const exportBuyingProduct = async () => {
        try {
            dispatch(storeSetGlobalLoading(true));
            var { AppCode, Data, Message } = await apiClient.post(`${ApiConfig.PRODUCT_USER}/export`, getObjectFilterForExporting());
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                downloadFile(Data.FileContents, Data.ContentType, Data.FileDownloadName);
                dispatch(storeOpenSnackbar({ open: true, message: "Xuất file excel thành công", type: "success" }));
            }
            else if (AppCode === 500) {
                dispatch(storeOpenSnackbar({ open: true, message: "Xuất file excel thất bại", type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: Message, type: "error" }));
            }
        } catch (error: any) {
            dispatch(storeSetGlobalLoading(false));
            if (error?.response?.data?.AppCode === 500) {
                dispatch(storeOpenSnackbar({ open: true, message: error?.response?.data?.Message, type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        }
    }

    const handleChangeKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(event.target.value);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getObjectFilter = () => {
        var fromDateVNTime = dayjs(fromDate).add(7, 'hours') || null;
        var toDateVNTime = dayjs(toDate).add(7, 'hours') || null;

        return {
            Keyword: keyword,
            PageIndex: page + 1,
            PageSize: rowsPerPage,
            DateFrom: fromDateVNTime,
            DateTo: toDateVNTime,
            LocationId: isBranchManager ? tokenClaims.location_id : null
        };
    };

    const getObjectFilterForExporting = () => {
        var fromDateVNTime = dayjs(fromDate).add(7, 'hours') || null;
        var toDateVNTime = dayjs(toDate).add(7, 'hours') || null;

        return {
            Keyword: keyword,
            DateFrom: fromDateVNTime,
            DateTo: toDateVNTime,
        };
    };


    const getProductUserPaging = async () => {
        try {
            setTableLoading(true);
            const { AppCode, Data } = await apiClient.post(`${ApiConfig.PRODUCT_USER}/search`, getObjectFilter());
            setTableLoading(false);
            if (AppCode === 200) {
                setPaginatedData((prevState: PaginatedResult) => ({
                    ...prevState,
                    ...Data
                }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        } catch (error) {
            setTableLoading(false);
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    useEffect(() => {
        getProductUserPaging();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, keyword, fromDate, toDate]);

    return (
        <Wrapper>
            <div className="w-full flex justify-between">
                <Title>Danh sách sản phẩm mua</Title>
                <HButton startIcon={<SendToMobileIcon />} onClick={exportBuyingProduct}>Xuất file excel</HButton>
            </div>
            <ToolbarWrapper>
                <Grid container>
                    <Grid sx={{ paddingRight: '8px' }} item xs={4}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Số điện thoại hoặc tên sản phẩm</LabelControl>
                            <Input onChange={handleChangeKeyword} value={keyword} fullWidth placeholder="Tìm kiếm theo số điện thoại hoặc tên sản phẩm" />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ paddingRight: '8px' }} item xs={4}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Từ ngày</LabelControl>
                            <HDatePicker
                                value={fromDate}
                                ampm={false}
                                inputFormat="DD/MM/YYYY"
                                onChange={(newValue: any) => {
                                    setFromDate(newValue);
                                }}>
                            </HDatePicker>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Đến ngày</LabelControl>
                            <HDatePicker
                                value={toDate}
                                placeholder="Từ ngày"
                                inputFormat="DD/MM/YYYY"
                                ampm={false}
                                onChange={(newValue: any) => {
                                    setToDate(newValue);
                                }}>
                            </HDatePicker>
                        </FormControl>
                    </Grid>
                </Grid>
            </ToolbarWrapper>
            <HTable
                columns={columns}
                pagingInfo={{
                    rowsPerPage,
                    page,
                    totalCount: paginatedData.TotalCount,
                    handleChangePage,
                    handleChangeRowsPerPage
                }}
                loading={tableLoading}
                listData={paginatedData.ListOut}
            ></HTable>
        </Wrapper>
    )
}