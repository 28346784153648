import { HButton } from "@components/Button";
import { styled } from "@mui/material/styles";
import { COLORS } from "@shared/constants/colors";
import { LabelControl, Title } from "@shared/styledComponents";
import AddIcon from '@mui/icons-material/Add';
import moment from "moment";
import { currencyFormat } from "@shared/helpers/common";
import { Column, PaginatedResult } from "types/common";
import { HTable } from "@components/Table";
import { FormControl, Grid } from "@mui/material";
import { Input } from "@components/Input";
import { useEffect, useState } from "react";
import { apiClient } from "@services/baseAxios";
import { ApiConfig } from "@config/index";
import { errorMessages } from "@shared/constants/messages";
import { storeOpenSnackbar } from "@store/globalReducer";
import { useDispatch } from "react-redux";
import { CustomerAccountCreationForm } from "./customerAccountCreationForm";

const CustomerAccountCreationnWrapper = styled('div')(() => ({
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.GhostWhite,
    padding: '16px',
    display: 'flex',
    flexDirection: 'column'
}));

const columns: readonly Column[] = [
    {
        id: 'STT',
        label: 'STT',
        align: 'left',
        minWidth: 30,
        transform: (data: any) => (data.index + 1)
    },
    {
        id: 'Fullname',
        label: 'Họ và tên',
        minWidth: 130,
        transform: (data: any) => (<div style={{ fontWeight: 'bold' }}>{data.value}</div>)
    },
    {
        id: 'PhoneNumber',
        label: 'Số điện thoại',
        minWidth: 120,
        align: 'left',
    },
    {
        id: 'MembershipClass',
        label: 'Hạng khách hàng',
        align: 'left',
        minWidth: 150,
        transform: (data: any) => (data.value ? <span style={{ color: COLORS.Tertiary }}>{data.value}</span> : 'Standard')
    },
    {
        id: 'ServiceRecentlyUsedDate',
        label: 'Ngày gần nhất dùng dịch vụ',
        align: 'center',
        minWidth: 200,
        transform: (data: any) => (data.value ? moment(data.value).format("DD/MM/YYYY") : '--/--/----')
    },
    {
        id: 'TotalValueOfBills',
        label: 'Tổng giá trị các bill',
        align: 'right',
        minWidth: 150,
        transform: (data: any) => (data.value ? <span>{currencyFormat(data.value)}đ</span> : '0đ')
    },
];

export const CustomerAccountCreation = () => {
    const dispatch = useDispatch();

    const [keyword, setKeyword] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [tableLoading, setTableLoading] = useState(false);
    const [paginatedData, setPaginatedData] = useState<PaginatedResult>({
        ListOut: [],
        TotalCount: 0,
        PageStart: 1,
        PageEnd: 1,
        TotalPage: 0
    } as PaginatedResult);
    const [isOpenForm, setIsOpenForm] = useState(false);

    const handleChangeKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(event.target.value);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getObjectFilter = () => {
        return {
            Keyword: keyword,
            PageIndex: page + 1,
            PageSize: rowsPerPage,
            RoleId: null,
            // Lấy theo cơ sở hiện tại của Kế toán
            LocationId: null,
        };
    }

    const getListUserPaging = async () => {
        try {
            setTableLoading(true);
            var { AppCode, Data } = await apiClient.post(`${ApiConfig.GET_CUSTOMER_PAGING}`, getObjectFilter());
            setTableLoading(false);
            if (AppCode === 200) {
                setPaginatedData((prevState: PaginatedResult) => ({
                    ...prevState,
                    ...Data
                }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        }
        catch (e) {
            setTableLoading(false);
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    const onRowCustomHandler = (row: any, handlerType: any) => {
        // To do
    }

    const handleCloseForm = () => {
        setIsOpenForm(false)
    }

    const handleSubmitSuccess = () => {
        setIsOpenForm(false);
        dispatch(storeOpenSnackbar({ open: true, message: "Tạo tài khoản khách hàng thành công", type: "success" }));
        getListUserPaging();
    }

    useEffect(() => {
        getListUserPaging();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, keyword]);

    return (
        <CustomerAccountCreationnWrapper>
            <div className="w-full flex justify-between">
                <Title>Danh sách khách hàng</Title>
                <HButton startIcon={<AddIcon />} onClick={() => { setIsOpenForm(true) }} >Tạo tài khoản khách</HButton>
            </div>
            <div className="py-4 px-0">
                <Grid container>
                    <Grid sx={{ paddingRight: '8px' }} item xs={6}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Số điện thoại hoặc tên</LabelControl>
                            <Input onChange={handleChangeKeyword} value={keyword} fullWidth placeholder="Tìm kiếm theo số điện thoại hoặc tên" />
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
            <HTable
                keyProperty="UserId"
                columns={columns}
                pagingInfo={{
                    rowsPerPage,
                    page,
                    totalCount: paginatedData.TotalCount,
                    handleChangePage,
                    handleChangeRowsPerPage
                }}
                loading={tableLoading}
                listData={paginatedData.ListOut}
                onRowCustomHandler={onRowCustomHandler}
            ></HTable>
            {
                isOpenForm &&
                <CustomerAccountCreationForm
                    isOpen={isOpenForm}
                    handleOnClose={handleCloseForm}
                    handleOnConfirm={handleSubmitSuccess}
                >
                </CustomerAccountCreationForm>
            }
        </CustomerAccountCreationnWrapper>
    );
}