import { Input } from "@components/Input";
import { HSelect } from "@components/Select";
import { HTable } from "@components/Table";
import { ApiConfig } from "@config/index";
import { FormControl, Grid, MenuItem, SvgIcon, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { apiClient } from "@services/baseAxios";
import { COLORS } from "@shared/constants/colors";
import { LabelControl, Title } from "@shared/styledComponents";
import { storeSetAllRole, storeSetSpaLocations } from "@store/directoryReducer";
import { Column, PaginatedResult } from "types/common";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { storeOpenSnackbar, storeSetGlobalLoading } from "@store/globalReducer";
import { errorMessages } from "@shared/constants/messages";
import { rowCustomHandlerType } from "@components/Table/constant";
import { roleNameMapping, systemRole } from "@shared/constants/common";
import { HDialog } from "@components/Dialog";
import { HButton } from "@components/Button";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useAppSelector } from "@hooks/useSelector/useAppSelector";
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';


const Wrapper = styled('div')(() => ({
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.GhostWhite,
    padding: '16px',
    display: 'flex',
    flexDirection: 'column'
}));

const ToolbarWrapper = styled('div')(() => ({
    padding: "16px 0px"
}));

const columns: readonly Column[] = [
    {
        id: 'STT',
        label: 'STT',
        align: 'left',
        minWidth: 30,
        transform: (data: any) => (data.index + 1)
    },
    {
        id: 'Fullname',
        label: 'Họ và tên',
        minWidth: 130,
        transform: (data: any) => (<div style={{ fontWeight: 'bold' }}>{data.value}</div>)
    },
    {
        id: 'PhoneNumber',
        label: 'Số điện thoại',
        minWidth: 120,
        align: 'left',
    },
    {
        id: 'Roles',
        label: 'Vai trò',
        minWidth: 100,
        align: 'left',
        transform: (data: any) => (data.value[0] ? roleNameMapping[data.value[0]] : '')
    },
    {
        id: 'LocationName',
        label: 'Cơ sở',
        align: 'left',
        minWidth: 150
    },
    {
        id: 'Actions',
        align: 'center',
        label: 'Tác vụ',
        minWidth: 80,
        transform: (value: any) => (
            <div className="flex justify-center items-center">
                <Tooltip title="Khôi phục" placement="left">
                    <div onClick={() => value.onCustomHandler(rowCustomHandlerType.RESTORE)} className='flex justify-center items-center cursor-pointer'>
                        <SvgIcon sx={{ width: 24, height: 24 }} component={RestoreFromTrashIcon} inheritViewBox />
                    </div>
                </Tooltip>
            </div>
        )
    },
];

export const RestoreAccountAdministration = () => {
    const dispatch = useDispatch();
    const { spaLocations, allRole } = useSelector((state: any) => state.directory);
    var { tokenClaims } = useAppSelector((state: any) => state.auth);
    const isBranchManager = tokenClaims.role === systemRole.BRANCH_MANAGER;

    const [tableLoading, setTableLoading] = useState(false);
    const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [keyword, setKeyword] = useState('');
    const [selectedLocation, setSelectedLocation] = useState(isBranchManager ? tokenClaims.location_id : "-1");
    const [selectedRole, setSelectedRole] = useState("-1");
    const [selectedRow, setSelectedRow] = useState({} as any);
    const [paginatedData, setPaginatedData] = useState<PaginatedResult>({
        ListOut: [],
        TotalCount: 0,
        PageStart: 1,
        PageEnd: 1,
        TotalPage: 0
    } as PaginatedResult);

    const handleChangeKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(event.target.value);
    };

    const getSpaLocation = async () => {
        if (spaLocations?.length > 0) return;

        const res = await apiClient.get(ApiConfig.GET_ALL_SPA_LOCATION);
        if (res?.AppCode === 200) {
            dispatch(storeSetSpaLocations(res.Data));
        }
        else {
            dispatch(storeSetSpaLocations([]));
        }
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getAllRole = async () => {
        if (allRole?.length > 0) return;

        const { AppCode, Data } = await apiClient.get(ApiConfig.GET_ALL_ROLE);
        if (AppCode === 200) {
            dispatch(storeSetAllRole(Data));
        }
        else {
            dispatch(storeSetAllRole([]));
        }
    }

    const handleChangeLocation = (event: { target: { value: string } }) => {
        setSelectedLocation(event.target.value);
    };

    const handleChangeRole = (event: { target: { value: string } }) => {
        setSelectedRole(event.target.value);
    };

    const getObjectFilter = () => {
        return {
            Keyword: keyword,
            PageIndex: page + 1,
            PageSize: rowsPerPage,
            RoleId: selectedRole === "-1" ? null : selectedRole,
            LocationId: selectedLocation === "-1" ? null : selectedLocation,
        };
    }

    const onRowCustomHandler = (row: any, handlerType: any) => {
        setSelectedRow(row);
        if (handlerType === rowCustomHandlerType.RESTORE) {
            setIsOpenConfirmDialog(true);
        }
    }

    const closeConfirmDialog = () => {
        setIsOpenConfirmDialog(false);
    }

    const restoreUserAccount = async () => {
        try {
            dispatch(storeSetGlobalLoading(true));
            var { AppCode, Message } = await apiClient.post(`${ApiConfig.USER}/${selectedRow.UserId}/restoration`);
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                closeConfirmDialog();
                dispatch(storeOpenSnackbar({ open: true, message: "Khôi phục tài khoản thành công", type: "success" }));
                getListUserPaging();
            }
            else if (AppCode === 500) {
                dispatch(storeOpenSnackbar({ open: true, message: "Khôi phục tài khoản thất bại", type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: Message, type: "error" }));
            }
        } catch (error: any) {
            dispatch(storeSetGlobalLoading(false));
            if (error?.response?.data?.AppCode === 500) {
                dispatch(storeOpenSnackbar({ open: true, message: error?.response?.data?.Message, type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        }
    }

    const getListUserPaging = async () => {
        try {
            setTableLoading(true);
            var { AppCode, Data } = await apiClient.post(`${ApiConfig.GET_DELETED_USER_PAGING}`, getObjectFilter());
            setTableLoading(false);
            if (AppCode === 200) {
                setPaginatedData((prevState: PaginatedResult) => ({
                    ...prevState,
                    ...Data
                }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        }
        catch (e) {
            setTableLoading(false);
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    useEffect(() => {
        getSpaLocation();
        getAllRole();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getListUserPaging();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, keyword, selectedLocation, selectedRole]);

    return (
        <Wrapper>
            <Title>Khôi phục tài khoản</Title>
            <ToolbarWrapper>
                <Grid container>
                    <Grid sx={{ paddingRight: '8px' }} item xs={4}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Số điện thoại hoặc tên</LabelControl>
                            <Input onChange={handleChangeKeyword} value={keyword} fullWidth placeholder="Tìm kiếm theo số điện thoại hoặc tên" />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ paddingRight: '8px', paddingLeft: '8px' }} item xs={4}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Vai trò</LabelControl>
                            <HSelect value={selectedRole}
                                onChange={handleChangeRole}>
                                <MenuItem key={-1} value={"-1"}>Tất cả</MenuItem>
                                {
                                    allRole.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                                }
                            </HSelect>
                        </FormControl>
                    </Grid>
                    <Grid sx={{ paddingLeft: '8px' }} item xs={4}>
                        <FormControl disabled={isBranchManager} sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Cơ sở</LabelControl>
                            <HSelect value={selectedLocation}
                                onChange={handleChangeLocation}>
                                <MenuItem key={-1} value={"-1"}>Tất cả</MenuItem>
                                {
                                    spaLocations.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                                }
                            </HSelect>
                        </FormControl>
                    </Grid>
                </Grid>
            </ToolbarWrapper>
            <HTable
                keyProperty="UserId"
                columns={columns}
                pagingInfo={{
                    rowsPerPage,
                    page,
                    totalCount: paginatedData.TotalCount,
                    handleChangePage,
                    handleChangeRowsPerPage
                }}
                loading={tableLoading}
                listData={paginatedData.ListOut}
                onRowCustomHandler={onRowCustomHandler}
            ></HTable>
            {/* Popup confirm xóa user */}
            <HDialog
                PaperProps={{
                    sx: {
                        width: "450px",
                    }
                }}
                title="Khôi phục tài khoản"
                open={isOpenConfirmDialog}
                handleClose={closeConfirmDialog}
                dialogFooter={
                    <>
                        <HButton startIcon={<CloseOutlinedIcon />} btnType="secondary" onClick={closeConfirmDialog}>Hủy</HButton>
                        <HButton startIcon={<RestoreFromTrashIcon />} onClick={restoreUserAccount}>Khôi phục</HButton>
                    </>
                }
            >
                Bạn chắc chắn muốn khôi phục tài khoản này chứ?
            </HDialog>
        </Wrapper >
    )
}