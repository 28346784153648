export const REGEX_ACCOUNT_NUMBER = /^\d{7,8}$/;
export const REGEX_PHONE_NUMBER = /^\+?[\d-]+$/;
export const REGEX_EMAIL = /^[a-z][a-z0-9_=\-+.]{5,32}@[a-z0-9]{2,}(\.[a-z0-9]{2,4}){1,2}$/;
export const REGEX_URL = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;
export const REGEX_HAFTWIDTH = /^[ｧ-ﾝﾞﾟ ]|[a-zA-Z ]/;
export const REGEX_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d[$&+,:;=?@#|'<>.^*()%!-\]{}'"/?_]{8,30}$/;
export const REGEX_PASSWORD_HKAPP = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
export const REGEX_PASSWORD_CMS = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
export const REGEX_NUMBER = /\B(?=(\d{3})+(?!\d))/g;
export const REGEX_IMAGE = /\.(heic|jpe?g|jfif|png)$/i;
export const REGEX_URL_NAME = /^([a-zA-Z0-9]|-|_)+$/;
export const REGEX_HTML_TAG = /<[^>]+>/g;
export const REGEX_NUMBER_HAFLWIDTH = /^[0-9]+$/;
export const REGEX_IMG_TAG = /<img([\w\W]+?)\/>/;
export const REGEX_IFRAME_TAG = /<iframe([\w\W]+?)>/;
export const REGEX_NUMBER_AND_DOT = /^[0-9]*\.?[0-9]*$/;
export const REGEX_SPECIAL_STRING = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
export const REGEX_CONTAIN_MINUS = /^-+[^-]*$/;
