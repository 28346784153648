import { styled } from "@mui/material/styles";
import { COLORS } from "@shared/constants/colors";
import { sharedStyles } from "@shared/styles/common";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { HTextField } from "@components/Input/HTextFiled";
import { ErrorValidation, LabelControl } from "@shared/styledComponents";
import { images } from "@assets/imgs";
import { HCheckbox } from "@components/Checkbox";
import { HButton } from "@components/Button"
import { apiClient } from "@services/baseAxios";
import { ApiConfig } from "@config/index";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { storeSetToken, storeSetRefreshToken, storeSetTokenClaim } from "@store/authReducer";
import { storeOpenSnackbar } from "@store/globalReducer"
import { useNavigate } from "react-router-dom";
import { storeSetGlobalLoading } from "@store/globalReducer";
import jwt_decode from "jwt-decode";
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "@shared/constants/keyStore";

const LoginWrapper = styled('div')(() => ({
    width: '100vw',
    height: '100vh',
    display: 'flex'
}));

const LeftSection = styled('div')(() => ({
    ...styles.halfSection,
    ...styles.leftSection,
    ...sharedStyles.contentCenter
}));

const RightSection = styled('div')(() => ({
    ...styles.halfSection,
    ...sharedStyles.contentCenter,
    flexDirection: 'column',
    paddingLeft: '64px',
    paddingRight: '64px'
}));

export const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [checked, setChecked] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const [phoneNumberInput, setPhoneNumberInput] = useState('');
    const [passwordInput, setPasswordInput] = useState('');

    const [isTouch, setIsTouch] = useState(false);

    const handleTextInputChange = (event: any, field: string) => {
        const value = event.target.value;
        switch (field) {
            case 'phone':
                setPhoneNumberInput(value);
                break;
            case 'password':
                setPasswordInput(value);
                break;
        }
    }

    const phoneErrorMsg = "Bạn chưa nhập số điện thoại";
    const passwordErrorMsg = "Bạn chưa nhập mật khẩu";

    const toggleDisplayPassword = () => {
        setShowPassword(!showPassword);
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const handleSubmit = async () => {
        setIsTouch(true);
        const phone = phoneNumberInput
        const password = passwordInput;

        if (!phone || !password) return;

        dispatch(storeSetGlobalLoading(true));
        var res;
        try {
            res = await apiClient.post(ApiConfig.LOGIN, { PhoneNumber: phone, Password: password });
        } catch (error: any) {
            dispatch(storeSetGlobalLoading(false));

            if (error?.response?.status === 500) {
                if (error?.response?.data?.AppCode === 400) {
                    dispatch(storeOpenSnackbar({ open: true, message: "Tài khoản hoặc mật khẩu không đúng!", type: "error" }));
                }
                else {
                    dispatch(storeOpenSnackbar({ open: true, message: "Có lỗi xảy ra, vui lòng thử lại!", type: "error" }));
                }
            }
        }
        dispatch(storeSetGlobalLoading(false));
        if (res?.AppCode === 200) {
            const token = res.Data.Token;
            localStorage.setItem(ACCESS_TOKEN_KEY, token);
            dispatch(storeSetToken(token));
            dispatch(storeSetTokenClaim(jwt_decode(token)));
            // Người dùng bấm nhớ mật khẩu thì lưu thêm refresh token
            if (checked) {
                dispatch(storeSetRefreshToken(res.Data.RefreshToken))
                localStorage.setItem(REFRESH_TOKEN_KEY, res.Data.RefreshToken);
            }
            navigate('/');
        }
    }

    return (
        <LoginWrapper>
            <LeftSection>
                <div style={styles.offsetBottom}>
                    <img style={{ maxWidth: '350px' }} className="px-5" src={images.bgLogo} alt="amico" />
                </div>
            </LeftSection>
            <RightSection>
                <div className="w-full" style={styles.offsetBottom}>
                    <div style={styles.loginTitle}>ĐĂNG NHẬP</div>
                    <div className="w-full mt-16">
                        <div>
                            <LabelControl className="mb-2">Số điện thoại</LabelControl>
                            <HTextField
                                value={phoneNumberInput}
                                sx={(!phoneNumberInput && isTouch) ? sharedStyles.borderError : {}}
                                onChange={(event: any) => handleTextInputChange(event, 'phone')}
                                fullWidth
                                placeholder="Nhập số điện thoại"
                                iconStart={<PersonOutlineOutlinedIcon />}
                            />
                            {
                                (!phoneNumberInput && isTouch) &&
                                <ErrorValidation>{phoneErrorMsg}</ErrorValidation>
                            }
                        </div>
                        <div className="mt-[32px]">
                            <LabelControl className="mb-2">Mật khẩu</LabelControl>
                            <HTextField
                                fullWidth
                                sx={(!passwordInput && isTouch) ? sharedStyles.borderError : {}}
                                value={passwordInput}
                                onChange={(event: any) => handleTextInputChange(event, 'password')}
                                type={showPassword ? "text" : "password"}
                                placeholder="Nhập mật khẩu"
                                iconStart={<LockOutlinedIcon />}
                                iconEnd={showPassword ? <VisibilityIcon onClick={toggleDisplayPassword}
                                    className="cursor-pointer" /> : <VisibilityOffIcon
                                    onClick={toggleDisplayPassword}
                                    className="cursor-pointer" />}
                            />
                            {
                                (!passwordInput && isTouch) &&
                                <ErrorValidation>{passwordErrorMsg}</ErrorValidation>
                            }
                        </div>
                        <div className="mt-[32px]">
                            <div className="flex justify-between">
                                <div className="ml-[-11px]">
                                    <HCheckbox
                                        checked={checked}
                                        onChange={handleChange}
                                    />
                                    Lưu mật khẩu
                                </div>
                                {/* <div className="flex items-center cursor-pointer hover:underline">Quên mật khẩu?</div> */}
                                <div></div>
                            </div>
                        </div>
                    </div>
                    <HButton onClick={handleSubmit} sx={{ marginTop: '64px' }} fullWidth>Đăng nhập</HButton>
                    <p className="mt-3">Công ty TNHH Zei Group Tầng 3, Toà nhà GIM, ngõ 460 Khương Đình, Phường Hà Đình, Quận Thanh Xuân, Tp Hà Nội, VN.</p>
                </div>
            </RightSection>
        </LoginWrapper >
    )
}

const styles = {
    halfSection: {
        width: '50vw',
        height: '100vh',
    },
    leftSection: {
        backgroundColor: COLORS.MainBackground,
    },
    loginTitle: {
        fontSize: '32px',
        lineHeight: '38px',
        fontWeight: '500',
        color: COLORS.Primary,
        justifyContent: 'center',
        display: 'flex'
    },
    offsetBottom: {
        marginBottom: '80px'
    }
}