import { HButton } from "@components/Button";
import { HDatePicker } from "@components/DateTimePicker";
import { HSelect } from "@components/Select";
import { ApiConfig } from "@config/index";
import { Box, FormControl, Grid, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { apiClient } from "@services/baseAxios";
import { COLORS } from "@shared/constants/colors";
import { MONTH_DATA } from "@shared/constants/common";
import { LabelControl, Title } from "@shared/styledComponents";
import { storeSetAllRole } from "@store/directoryReducer";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RefreshIcon from '@mui/icons-material/Refresh';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { HTextArea } from "@components/Input/HTextArea";
import { storeOpenSnackbar, storeSetGlobalLoading } from "@store/globalReducer";
import { errorMessages } from "@shared/constants/messages";
import { HDialog } from "@components/Dialog";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { HTab, HTabs, TabPanel } from "@components/Tab";
import { a11yProps } from "@shared/helpers/common";
import FileUploadIcon from '@mui/icons-material/FileUpload';

const TopSection = styled('div')(() => ({
    width: '100%',
    backgroundColor: COLORS.GhostWhite,
    padding: '16px',
    display: 'flex',
    flexDirection: 'column'
}));

const BottomSection = styled('div')(() => ({
    width: '100%',
    backgroundColor: COLORS.White,
    padding: '16px',
    paddingTop: '32px',
    display: 'flex',
    flexDirection: 'column'
}));

const ToolbarWrapper = styled('div')(() => ({
    paddingTop: "16px"
}));

export const SmsAdministration = () => {
    const dispatch = useDispatch();
    const { allRole } = useSelector((state: any) => state.directory);

    const [tabValue, setTabValue] = useState(0);
    const [content, setContent] = useState('');
    const [fromDate, setFromDate] = useState<Dayjs | null>(null);
    const [toDate, setToDate] = useState<Dayjs | null>(null);
    const [selectedBirthMonth, setSelectedBirthMonth] = useState('-1');
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [numberSendedCustomer, setNumberSendedCustomer] = useState(0);
    const [hasUploadFile, setHasUploadFile] = useState(false);
    const [listSendedPhoneNumber, setListSendedPhoneNumber] = useState([]);

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
        handleOnRefresh();
    };

    const getAllRole = async () => {
        if (allRole?.length > 0) return;

        const { AppCode, Data } = await apiClient.get(ApiConfig.GET_ALL_ROLE);
        if (AppCode === 200) {
            dispatch(storeSetAllRole(Data));
        }
        else {
            dispatch(storeSetAllRole([]));
        }
    }

    const openDialog = () => {
        setIsOpenDialog(true);
    }

    const closeDialog = () => {
        setIsOpenDialog(false);
    }

    const handleChangeBirthMonth = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedBirthMonth(event.target.value);
    };

    const getCustomerFilterObject = () => {
        var fromDateVNTime = dayjs(fromDate).add(7, 'hours') || null;
        var toDateVNTime = dayjs(toDate).add(7, 'hours') || null;

        return {
            MonthOfBirth: selectedBirthMonth,
            FromDate: fromDateVNTime,
            ToDate: toDateVNTime,
        }
    }

    const checkCustomerNumberBeforeSending = async () => {
        if (!content) {
            dispatch(storeOpenSnackbar({ open: true, message: "Vui lòng nhập nội dung", type: "warning" }));
            return;
        }

        // Nếu là tab Nhập từ Excel
        if (tabValue === 1) {
            if (!hasUploadFile) {
                dispatch(storeOpenSnackbar({ open: true, message: "Vui lòng chọn file", type: "warning" }));
                return;
            }

            openDialog();
        }
        // Nếu là tab Khách hàng đã lưu trong hệ thống
        else {
            var customerFilterObj = getCustomerFilterObject();
            try {
                dispatch(storeSetGlobalLoading(true));
                var { AppCode, Data } = await apiClient.post(`${ApiConfig.NOTIFICATION_ADMINISTRATION}/customer-count-by-filter`, customerFilterObj);
                dispatch(storeSetGlobalLoading(false));
                if (AppCode === 200) {
                    setNumberSendedCustomer(Data);
                    openDialog();
                }
                else {
                    dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
                }
            } catch (error) {
                dispatch(storeSetGlobalLoading(false));
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        }
    }

    const handleFileUpload = async (event: any) => {
        const file = event.target.files[0];

        let formData = new FormData();
        formData.append("file", file);
        try {
            dispatch(storeSetGlobalLoading(true));
            var { AppCode, Data } = await apiClient.post(`${ApiConfig.FILE}/upload-phone-numbers`, formData, { headers: { "Content-Type": "multipart/form-data" } });
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                setHasUploadFile(true);
                setListSendedPhoneNumber(Data);
                setNumberSendedCustomer(Data.length);
                event.target.value = null;
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        } catch (error: any) {
            dispatch(storeSetGlobalLoading(false));
            if (error?.response?.data?.AppCode === 500) {
                dispatch(storeOpenSnackbar({ open: true, message: error?.response?.data?.Message, type: "error" }));
            }
            else if (error?.response?.data?.AppCode === 400) {
                dispatch(storeOpenSnackbar({ open: true, message: "Thông tin đẩy lên sai định dạng", type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        };
    }

    const handleOnRefresh = () => {
        setContent('');
        setHasUploadFile(false);
        setListSendedPhoneNumber([]);
        setNumberSendedCustomer(0);
    }

    const sendSMSToSystemCustomer = async () => {
        var obj = {
            CustomerFilter: getCustomerFilterObject(),
            SmsContent: content
        }

        try {
            dispatch(storeSetGlobalLoading(true));
            var { AppCode } = await apiClient.post(`${ApiConfig.NOTIFICATION_ADMINISTRATION}/sms`, obj);
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                dispatch(storeOpenSnackbar({ open: true, message: "Gửi SMS thành công", type: "success" }));
                closeDialog();
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: "Gửi SMS thất bại", type: "error" }));
            }
        } catch (error) {
            dispatch(storeSetGlobalLoading(false));
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    const sendSMSToListPhoneNumber = async () => {
        try {
            dispatch(storeSetGlobalLoading(true));
            let obj = {
                PhoneNumbers: listSendedPhoneNumber,
                SmsContent: content
            }
            var { AppCode } = await apiClient.post(`${ApiConfig.NOTIFICATION_ADMINISTRATION}/sms/phone-numbers`, obj);
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                dispatch(storeOpenSnackbar({ open: true, message: "Gửi SMS thành công", type: "success" }));
                closeDialog();
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: "Gửi SMS thất bại", type: "error" }));
            }
        } catch (error) {
            dispatch(storeSetGlobalLoading(false));
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    const handleSendSMS = async () => {
        if (!content) {
            dispatch(storeOpenSnackbar({ open: true, message: "Vui lòng nhập nội dung", type: "warning" }));
            return;
        }

        if (tabValue === 0) {
            sendSMSToSystemCustomer();
        }
        else {
            sendSMSToListPhoneNumber();
        }
    }

    const changeContent = (event: any) => {
        setContent(event.target.value);
    }

    useEffect(() => {
        getAllRole();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <HTabs
                    value={tabValue}
                    onChange={handleChangeTab}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                >
                    <HTab {...a11yProps(0)} label="Khách hàng đã lưu trong hệ thống" />
                    <HTab {...a11yProps(1)} label="Nhập số điện thoại từ file excel" />
                </HTabs>
            </Box>
            <Box sx={{ width: '100%', marginTop: '16px', backgroundColor: COLORS.White }}>
                <TopSection>
                    <Title>Tạo SMS khuyến mãi cho khách hàng</Title>
                    <TabPanel value={tabValue} index={0}>
                        <ToolbarWrapper>
                            <Grid container>
                                <Grid sx={{ paddingRight: '8px' }} item xs={4}>
                                    <FormControl sx={{ width: '100%' }} variant="standard">
                                        <LabelControl>Tháng sinh nhật</LabelControl>
                                        <HSelect value={selectedBirthMonth || "-1"}
                                            onChange={handleChangeBirthMonth}>
                                            <MenuItem key={-1} value={"-1"}>Tất cả</MenuItem>
                                            {
                                                MONTH_DATA.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                                            }
                                        </HSelect>
                                    </FormControl>
                                </Grid>
                                <Grid sx={{ paddingRight: '8px' }} item xs={4}>
                                    <FormControl sx={{ width: '100%' }} variant="standard">
                                        <LabelControl>Từ ngày</LabelControl>
                                        <HDatePicker
                                            value={fromDate}
                                            ampm={false}
                                            inputFormat="DD/MM/YYYY"
                                            onChange={(newValue: any) => {
                                                setFromDate(newValue);
                                            }}>
                                        </HDatePicker>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl sx={{ width: '100%' }} variant="standard">
                                        <LabelControl>Đến ngày</LabelControl>
                                        <HDatePicker
                                            value={toDate}
                                            minDate={fromDate}
                                            placeholder="Từ ngày"
                                            inputFormat="DD/MM/YYYY"
                                            ampm={false}
                                            onChange={(newValue: any) => {
                                                setToDate(newValue);
                                            }}>
                                        </HDatePicker>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </ToolbarWrapper>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <div className="flex items-center mt-4">
                            <HButton startIcon={<FileUploadIcon />} component="label">
                                Chọn file excel
                                <input hidden accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" multiple type="file" onChange={handleFileUpload} />
                            </HButton>
                            {
                                hasUploadFile &&
                                <div className="ml-3">Số lượng số điện thoại hợp lệ đã được nhập là: <b>{numberSendedCustomer}</b></div>
                            }
                        </div>
                    </TabPanel>
                </TopSection>
                <BottomSection>
                    <Grid container>
                        <Grid item xs={12}>
                            <LabelControl required>Nội dung</LabelControl>
                            <HTextArea
                                sx={{ width: '100%' }}
                                value={content || ''}
                                onChange={changeContent}
                                // maxRows={3}
                                aria-label="maximum height"
                                placeholder="Nhập nội dung"
                            />
                            <div style={{ fontSize: '14px', color: COLORS.Gray }}>Số ký tự: {content.length || 0}</div>
                        </Grid>
                        <Grid sx={{ marginTop: '16px' }} item xs={12}>
                            <div className="flex justify-end">
                                <HButton startIcon={<RefreshIcon />} btnType="secondary" onClick={handleOnRefresh}>Làm mới</HButton>
                                <HButton sx={{ marginLeft: '16px' }} startIcon={<SendOutlinedIcon />} onClick={checkCustomerNumberBeforeSending}>Gửi</HButton>
                            </div>
                        </Grid>
                    </Grid>
                </BottomSection>
            </Box>

            {/* Dialog */}
            <HDialog
                PaperProps={{
                    sx: {
                        width: "450px",
                    }
                }}
                title="Thông báo"
                open={isOpenDialog}
                handleClose={closeDialog}
                dialogFooter={
                    <>
                        {
                            numberSendedCustomer >= 10 ?
                                <>
                                    <HButton startIcon={<CloseOutlinedIcon />} btnType="secondary" onClick={closeDialog}>Hủy</HButton>
                                    <HButton startIcon={<DoneOutlinedIcon />} onClick={handleSendSMS}>Xác nhận</HButton>
                                </>
                                :
                                <HButton startIcon={<CloseOutlinedIcon />} btnType="secondary" onClick={closeDialog}>Đóng</HButton>
                        }
                    </>
                }
            >
                {
                    numberSendedCustomer >= 30 ?
                        <div>Số lượng số điện thoại được chọn là <b>{numberSendedCustomer}</b>, đây là các tin nhắn tính phí và được kiểm duyệt bởi nhà mạng, pháp luật Việt Nam, cần cân nhắc và kiểm duyệt kỹ trước khi gửi</div> :
                        <div>Danh sách người nhận không hỗ trợ dưới <b>30</b> người, vui lòng thực hiện lại</div>
                }
            </HDialog>
        </>
    )
}