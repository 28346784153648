import { HButton } from "@components/Button"
import { HDialog } from "@components/Dialog"
import { Input } from "@components/Input";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { FormControl, Grid, MenuItem } from "@mui/material";
import { LabelControl } from "@shared/styledComponents";
import { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { storeOpenSnackbar, storeSetGlobalLoading } from "@store/globalReducer";
import { useDispatch, useSelector } from "react-redux";
import { ApiConfig } from "@config/index";
import { apiClient } from "@services/baseAxios";
import { errorMessages } from "@shared/constants/messages";
import { HSelect } from "@components/Select";
import { storeSetAllServiceType } from "@store/directoryReducer";

export const ServiceForm = ({ title, isOpen, serviceData, handleOnClose, handleOnConfirm, isCreateNew }: any) => {
    const dispatch = useDispatch();
    const { allServiceType } = useSelector((state: any) => state.directory);
    const [service, setService] = useState({} as any);

    const handleTextInputChange = (event: any, field: string) => {
        const value = event.target.value;
        switch (field) {
            case 'name':
                setService((prevState: any) => ({
                    ...prevState,
                    ...{ Name: value }
                }));
                break;
            case 'code':
                setService((prevState: any) => ({
                    ...prevState,
                    ...{ Code: value }
                }));
                break;
            case 'description':
                setService((prevState: any) => ({
                    ...prevState,
                    ...{ Description: value }
                }));
                break;
        }
    }

    const handleSubmit = () => {
        createOrUpdateService();
    }

    const handleChangeServiceType = (event: { target: { value: string } }) => {
        setService((prevState: any) => ({
            ...prevState,
            ...{ ServiceTypeId: event.target.value }
        }));
    };

    const getAllServiceType = async () => {
        if (allServiceType?.length > 0) return;

        const { AppCode, Data } = await apiClient.get(`${ApiConfig.SERVICE}/types/all`);
        if (AppCode === 200) {
            dispatch(storeSetAllServiceType(Data));
        }
        else {
            dispatch(storeSetAllServiceType([]));
        }
    }

    const createOrUpdateService = async () => {
        if (!service.Name || !service.ServiceTypeId) {
            dispatch(storeOpenSnackbar({ open: true, message: "Vùi lòng nhập đủ thông tin", type: "warning" }));
            return;
        }

        try {
            dispatch(storeSetGlobalLoading(true));
            var obj = service;
            obj.Code = "SYSTEM";
            var { AppCode } = await apiClient.post(`${ApiConfig.SERVICE}`, obj);
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                handleOnConfirm();
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        } catch (error: any) {
            dispatch(storeSetGlobalLoading(false));
            if (error?.response?.data?.AppCode === 500) {
                dispatch(storeOpenSnackbar({ open: true, message: error?.response?.data?.Message, type: "error" }));
            }
            else if (error?.response?.data?.AppCode === 400) {
                dispatch(storeOpenSnackbar({ open: true, message: "Thông tin đẩy lên sai định dạng", type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        }
    }

    useEffect(() => {
        setService(serviceData || {});
        getAllServiceType();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <HDialog
            PaperProps={{
                sx: {
                    width: "450px",
                }
            }}
            title={title}
            open={isOpen}
            handleClose={handleOnClose}
            dialogFooter={
                <>
                    <HButton startIcon={<CloseOutlinedIcon />} btnType="secondary" onClick={handleOnClose}>Hủy</HButton>
                    {
                        isCreateNew ?
                            <HButton startIcon={<AddIcon />} onClick={handleSubmit}>Tạo dịch vụ</HButton>
                            :
                            <HButton startIcon={<DoneOutlinedIcon />} onClick={handleSubmit}>Lưu</HButton>
                    }
                </>
            }
        >
            <Grid container>
                <Grid sx={{ pt: 0, pl: 0 }} item xs={12}>
                    <FormControl sx={{ width: '100%' }} variant="standard">
                        <LabelControl required>Tên</LabelControl>
                        <Input onChange={(event: any) => handleTextInputChange(event, 'name')} value={service.Name || ''} sx={{ width: '100%', marginTop: '4px' }} placeholder="Nhập tên dịch vụ" />
                    </FormControl>
                </Grid>
                <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={12}>
                    <FormControl sx={{ width: '100%' }} variant="standard">
                        <LabelControl required>Loại dịch vụ</LabelControl>
                        <HSelect sx={{ marginTop: '4px' }} value={service.ServiceTypeId || "-1"}
                            onChange={handleChangeServiceType}>
                            <MenuItem key={-1} disabled value="-1">
                                <em>Chọn loại dịch vụ</em>
                            </MenuItem>
                            {
                                allServiceType.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                            }
                        </HSelect>
                    </FormControl>
                </Grid>
            </Grid>
        </HDialog>
    )
}