import { HButton } from "@components/Button"
import { HDialog } from "@components/Dialog"
import { Box, FormControl, Grid, MenuItem } from "@mui/material"
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AddIcon from '@mui/icons-material/Add';
import { LabelControl, MenuItemPlaceholder } from "@shared/styledComponents";
import { HSelect } from "@components/Select";
import { useEffect, useState } from "react";
import { apiClient } from "@services/baseAxios";
import { ApiConfig } from "@config/index";
import { storeSetAllService, storeSetSpaLocations } from "@store/directoryReducer";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@hooks/useSelector/useAppSelector";
import { HAutocomplete } from "@components/Autocomplete";
import { User } from "types/user";
import { HTextField } from "@components/Input/HTextFiled";
import { ReactComponent as VNDIcon } from '@assets/icons/icon-vnd.svg';
import { currencyFormat } from "@shared/helpers/common";
import { storeOpenSnackbar, storeSetGlobalLoading } from "@store/globalReducer";
import { errorMessages } from "@shared/constants/messages";
import { systemRole } from "@shared/constants/common";

export const BuyingServiceForm = ({ isOpen, isCreateNew, data, handleOnClose, handleOnConfirm }: any) => {
    const dispatch = useDispatch();
    const { spaLocations, allService } = useAppSelector((state: any) => state.directory);
    var { tokenClaims } = useAppSelector(state => state.auth);
    const isBranchAccountant = tokenClaims.role === systemRole.BRANCH_ACCOUNTANT;
    const [selectedLocationId, setSelectedLocationId] = useState(tokenClaims.location_id || "-1");
    const [selectedService, setSelectedService] = useState({ Id: null, Code: '', Name: '' });
    const [listCustomer, setListCustomer] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState<User | null>(null);
    const [moneyInput, setMoneyInput] = useState('');

    const handleOnChangeCustomer = (e: React.SyntheticEvent, value: any) => {
        setSelectedCustomer(value);
    }

    const handleChangeLocation = (event: { target: { value: string } }) => {
        setSelectedLocationId(event.target.value);
    };

    const handleOnChangeService = (e: React.SyntheticEvent, value: any) => {
        setSelectedService(value);
    }

    const getAllService = async () => {
        if (allService?.length > 0) return;

        const res = await apiClient.get(ApiConfig.SERVICE);
        if (res?.AppCode === 200) {
            dispatch(storeSetAllService(res.Data));
        }
    }

    const getSpaLocation = async () => {
        if (spaLocations?.length > 0) return;

        const res = await apiClient.get(ApiConfig.GET_ALL_SPA_LOCATION);
        if (res?.AppCode === 200) {
            dispatch(storeSetSpaLocations(res.Data));
        }
    }

    const generateBuyingProductUserObject = () => {
        return {
            UserId: selectedCustomer?.UserId,
            ServiceId: selectedService?.Id,
            LocationId: selectedLocationId,
            Price: parseInt(moneyInput.replace(/\D/g, ''))
        };
    }

    const updateBuyingServiceUser = async () => {
        try {
            dispatch(storeSetGlobalLoading(true));
            var { AppCode } = await apiClient.put(`${ApiConfig.SERVICE_USER}/${data.Id}`, generateBuyingProductUserObject());
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                handleOnConfirm();
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: "Chỉnh sửa dịch vụ thất bại", type: "error" }));
            }
        } catch (error: any) {
            dispatch(storeSetGlobalLoading(false));
            if (error?.response?.data?.AppCode === 500) {
                dispatch(storeOpenSnackbar({ open: true, message: error?.response?.data?.Message, type: "error" }));
            }
            else if (error?.response?.data?.AppCode === 400) {
                dispatch(storeOpenSnackbar({ open: true, message: "Thông tin đẩy lên sai định dạng", type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        }
    }

    const createBuyingServiceUser = async () => {
        try {
            dispatch(storeSetGlobalLoading(true));
            var { AppCode } = await apiClient.post(`${ApiConfig.SERVICE_USER}`, generateBuyingProductUserObject());
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                handleOnConfirm();
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: "Thêm dịch vụ mới thất bại", type: "error" }));
            }
        } catch (error: any) {
            dispatch(storeSetGlobalLoading(false));
            if (error?.response?.data?.AppCode === 500) {
                dispatch(storeOpenSnackbar({ open: true, message: error?.response?.data?.Message, type: "error" }));
            }
            else if (error?.response?.data?.AppCode === 400) {
                dispatch(storeOpenSnackbar({ open: true, message: "Thông tin đẩy lên sai định dạng", type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        }
    }

    const handleSubmit = () => {
        if (!selectedCustomer?.UserId || !selectedService?.Id || !selectedLocationId || selectedLocationId === "-1" || !moneyInput) {
            dispatch(storeOpenSnackbar({ open: true, message: "Vui lòng nhập đủ thông tin", type: "warning" }));
            return;
        }

        if (isCreateNew) {
            createBuyingServiceUser();
        }
        else {
            updateBuyingServiceUser()
        }
    }

    const getCustomer = async (phoneNumber: String) => {
        var res = await apiClient.get(`${ApiConfig.GET_CUSTOMER}?phoneNumber=${phoneNumber}`);
        if (res?.AppCode === 200) {
            setListCustomer(res.Data);
        }
    }

    const handleTextInputChange = (event: any, field: string) => {
        const value = event.target.value;
        switch (field) {
            case 'money':
                var a = value.replace(/\D/g, "");
                var data = currencyFormat(parseInt(a)).toString();

                setMoneyInput(data);
                break;
        }
    }

    useEffect(() => {
        getSpaLocation();
        getAllService();
        getCustomer("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isCreateNew) {
            setMoneyInput(currencyFormat(parseInt(data.Price)).toString());
            if (spaLocations?.length > 0) {
                setSelectedLocationId(data.LocationId);
            }
            if (allService?.length > 0) {
                let service = allService.find((x: any) => x.Id === data.ServiceId);
                if (service) {
                    setSelectedService(service)
                }
            }
            if (listCustomer?.length > 0) {
                let customer = listCustomer.find((x: any) => x.UserId === data.UserId);
                if (customer) {
                    setSelectedCustomer(customer)
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [spaLocations, allService, listCustomer])

    const renderOptionCustomer = (props: any, option: any) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {option.PhoneNumber} {option.Fullname}
        </Box>
    );

    const renderOptionService = (props: any, option: any) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {option.Name}
        </Box>
    );

    return (
        <HDialog
            PaperProps={{
                sx: {
                    width: "450px",
                }
            }}
            title={isCreateNew ? "Thêm mới dịch vụ" : "Chỉnh sửa dịch vụ"}
            open={isOpen}
            handleClose={handleOnClose}
            dialogFooter={
                <>
                    <HButton startIcon={<CloseOutlinedIcon />} btnType="secondary" onClick={handleOnClose}>Hủy</HButton>
                    {
                        isCreateNew ?
                            <HButton startIcon={<AddIcon />} onClick={handleSubmit}>Thêm dịch vụ</HButton>
                            :
                            <HButton startIcon={<DoneOutlinedIcon />} onClick={handleSubmit}>Cập nhật</HButton>
                    }

                </>
            }
        >
            <Grid container>
                <Grid sx={{ pt: 0, pl: 0 }} item xs={12}>
                    <FormControl fullWidth variant="standard">
                        <LabelControl required>Khách hàng</LabelControl>
                        <HAutocomplete
                            disabled={!isCreateNew}
                            value={selectedCustomer}
                            placeholder="Nhập số điện thoại để chọn khách hàng"
                            options={listCustomer}
                            getOptionLabel={(option: any) => `${option.PhoneNumber} ${option.Fullname}`}
                            renderOption={renderOptionCustomer}
                            onChange={handleOnChangeCustomer}
                        />
                    </FormControl>
                </Grid>
                <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={12}>
                    <FormControl fullWidth variant="standard">
                        <LabelControl required>Dịch vụ</LabelControl>
                        <HAutocomplete
                            disabled={!isCreateNew}
                            placeholder="Chọn dịch vụ"
                            value={selectedService}
                            options={allService}
                            getOptionLabel={(option: any) => option.Name}
                            renderOption={renderOptionService}
                            onChange={handleOnChangeService}
                        />
                    </FormControl>
                </Grid>
                <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={12}>
                    <FormControl fullWidth variant="standard">
                        <LabelControl required>Cơ sở</LabelControl>
                        <HSelect disabled={!isCreateNew || isBranchAccountant} fullWidth value={selectedLocationId}
                            onChange={handleChangeLocation}>
                            <MenuItem key={-1} disabled value="-1">
                                <MenuItemPlaceholder>Chọn cơ sở</MenuItemPlaceholder>
                            </MenuItem>
                            {
                                spaLocations.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                            }
                        </HSelect>
                    </FormControl>
                </Grid>
                <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={12}>
                    <FormControl fullWidth variant="standard">
                        <LabelControl required>Giá bán dịch vụ</LabelControl>
                        <HTextField
                            fullWidth
                            value={moneyInput}
                            onChange={(event: any) => handleTextInputChange(event, 'money')}
                            placeholder="Nhập giá bán"
                            iconEnd={<VNDIcon />}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </HDialog>
    )
}