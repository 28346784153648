import { Provider } from 'react-redux';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { PersistGate } from 'redux-persist/integration/react';
import { AppRouter } from './routes';
import theme from './theme';
import { persistor, store } from './rootStore';
import { createBrowserHistory } from 'history';
import { GlobalLoading } from '@components/GlobalLoading';
import { GlobalSnackbar } from '@components/GlobalSnackbar';
import { connection } from '@config/signalRConfig';
import { SignalRContext } from './contexts/SignalRContext';

export const history = createBrowserHistory();

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <SignalRContext.Provider value={connection}>
          <ThemeProvider theme={theme}>
            <CssBaseline>
              <AppRouter history={history} />
              <GlobalLoading />
              <GlobalSnackbar />
            </CssBaseline>
          </ThemeProvider>
        </SignalRContext.Provider>
      </PersistGate>
    </Provider>
  );
}

export default App;

