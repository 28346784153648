import { styled } from '@mui/material/styles';
import { COLORS } from '@shared/constants/colors';
import { HDialogTitle, LabelControl, RadioLabel, Title } from '@shared/styledComponents';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Input } from '@components/Input';
import { CustomRadio } from '@components/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { apiClient } from '@services/baseAxios';
import { ApiConfig } from '@config/index';
import { ReactComponent as EditIcon } from '@assets/icons/icon-edit.svg';
import SvgIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { CUSTOMER_FEEDBACK_DATA, STATUS_MAPPING } from '@shared/constants/common';
import { Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import moment from 'moment';
import { HButton } from '@components/Button';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { PaginatedResult, Column } from 'types/common';
import { HTable } from '@components/Table';
import { HDatePicker } from '@components/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { HSelect } from '@components/Select';
import { ColumnAction } from '@components/Table/ColumnAction';
import { getCustomerRateName } from '@shared/helpers/common';

const ServiceUserProcessStatus = ({ status, ...props }: any) => {
    return (
        <span
            className='flex content-center justify-center'
            style={{
                borderRadius: '8px',
                paddingLeft: '8px',
                paddingRight: '8px',
                ...(status === "Processing" && {
                    backgroundColor: COLORS.Water,
                    color: COLORS.BrilliantAzure
                }),
                ...((status === "Updated" || status === "Completed") && {
                    backgroundColor: COLORS.Bubbles,
                    color: COLORS.Eucalyptus
                })
            }}>
            {STATUS_MAPPING[status]}
        </span>
    )
}

const columns: readonly Column[] = [
    { id: 'NoOfProcess', label: 'Buổi', align: 'right', minWidth: 50 },
    { id: 'ServiceName', label: 'Tên buổi', minWidth: 100 },
    {
        id: 'CustomerName',
        label: 'Họ tên khách hàng',
        minWidth: 160,
        align: 'left',
    },
    {
        id: 'CustomerPhone',
        label: 'Số điện thoại',
        minWidth: 120,
        align: 'left',
    },
    {
        id: 'MemberShipClass',
        label: 'Hạng',
        minWidth: 50,
        align: 'left',
        transform: (data: any) => (data.value ? <span style={{ color: COLORS.Tertiary }}>{data.value}</span> : 'Standard')
    },
    {
        id: 'TreatmentDate',
        label: 'Ngày thực hiện',
        align: 'center',
        minWidth: 130,
        transform: (data: any) => (data.value ? moment(data.value).format("DD/MM/YYYY") : '--/--/----')
    },
    { id: 'LocationName', label: 'Cơ sở', align: 'left', minWidth: 120 },
    {
        id: 'CustomerRate',
        label: 'Mức độ hài lòng',
        align: 'left',
        minWidth: 150,
        transform: (data: any) => (data.value ? getCustomerRateName(data.value) : '---')
    },
    {
        id: 'Status',
        label: 'Trạng thái',
        align: 'left',
        minWidth: 140,
        transform: (data: any) => (<ServiceUserProcessStatus status={data.value} />)
    },
    {
        id: 'Actions',
        align: 'center',
        label: 'Tác vụ',
        minWidth: 60,
        transform: (value: any) => (<ColumnAction tooltip="Cập nhật" onHandler={value.onHandler}><SvgIcon sx={{ width: 16, height: 16 }} component={EditIcon} inheritViewBox /></ColumnAction>)
    }
];

function createData(
    Id: string,
    NoOfProcess: number,
    ServiceName: string,
    CustomerName: string,
    CustomerPhone: string,
    MemberShipClass: string,
    TreatmentDate: string,
    LocationName: string,
    Status: string,
    Actions: any,
): any {
    return { Id, NoOfProcess, ServiceName, CustomerName, CustomerPhone, MemberShipClass, TreatmentDate, LocationName, Status, Actions };
}

var rows = [
    createData("1", 1, "ServiceName", "CustomerName", "089453453", "VIP", "date", "LocationName", "Updated", null)
];

const radioValueMapping: any = {
    '-1': null,
    '1': true,
    '0': false
}

const FeedbackWrapper = styled('div')(() => ({
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.GhostWhite,
    padding: '16px',
    display: 'flex',
    flexDirection: 'column'
}));

const ToolbarWrapper = styled('div')(() => ({
    padding: "16px 0px"
}));

export const Feedback = () => {
    const navigate = useNavigate();

    const [selectedCustomerRate, setSelectedCustomerFeedback] = useState("-1");
    const [selectedLocation, setSelectedLocation] = useState("-1");
    const [tableLoading, setTableLoading] = useState(false);
    const [radioValue, setRadioValue] = useState('0');
    const [keyword, setKeyword] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openDialog, setOpenDialog] = useState(false);
    const [currentSelectedItem, setCurrentSelectedItem] = useState({} as any);
    const [fromDate, setFromDate] = useState<Dayjs | null>(null);
    const [toDate, setToDate] = useState<Dayjs | null>(null);
    const [spaLocations, setSpaLocations] = useState([]);
    const [paginatedData, setPaginatedData] = useState<PaginatedResult>({
        ListOut: [],
        TotalCount: 0,
        PageStart: 1,
        PageEnd: 1,
        TotalPage: 0
    } as PaginatedResult);

    useEffect(() => {
        setPaginatedData({
            ListOut: rows as [],
            TotalCount: 1,
            PageStart: 1,
            PageEnd: 1,
            TotalPage: 1
        });

        getSpaLocation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleChangeLocation = (event: { target: { value: string } }) => {
        setSelectedLocation(event.target.value);
    };

    const getSpaLocation = async () => {
        const res = await apiClient.get(ApiConfig.GET_ALL_SPA_LOCATION);
        if (res?.AppCode === 200) {
            setSpaLocations(res.Data);
        }
        else {
            setSpaLocations([])
        }
    }

    const goToDetail = () => {
        handleCloseDialog();
        navigate(`/main/feedback/${currentSelectedItem.Id}`);
        setCurrentSelectedItem({} as any);
    }

    const getObjectFilter = () => {
        var fromDateVNTime = dayjs(fromDate).add(7, 'hours') || null;
        var toDateVNTime = dayjs(toDate).add(7, 'hours') || null;

        return {
            Keyword: keyword,
            PageIndex: page + 1,
            PageSize: rowsPerPage,
            StartTime: fromDateVNTime,
            EndTime: toDateVNTime,
            LocationId: selectedLocation === "-1" ? null : selectedLocation,
            HasFeedback: radioValueMapping[radioValue],
            CustomerRate: selectedCustomerRate === "-1" ? null : selectedCustomerRate
        };
    }

    const getData = async () => {
        setTableLoading(true);
        const res = await apiClient.post(ApiConfig.GET_SERVICE_USER_PROCESS_PAGING, getObjectFilter());
        setTableLoading(false);
        if (res?.AppCode === 200) {
            setPaginatedData((prevState: PaginatedResult) => ({
                ...prevState,
                ...res.Data
            }));
        }
    }

    const handleChangeKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(event.target.value);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRadioValue((event.target as HTMLInputElement).value);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangeCustomerRate = (event: { target: { value: any } }) => {
        setSelectedCustomerFeedback(event.target.value);
    };

    const onHandler = (item: any) => {
        setCurrentSelectedItem(item);
        if (item.CustomerFeedback) {
            handleOpenDialog();
        }
        else {
            navigate(`/main/feedback/${item.Id}`);
        }
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, radioValue, keyword, selectedLocation, fromDate, toDate, selectedCustomerRate]);

    return (
        <FeedbackWrapper>
            <Title>Cập Nhật Feedback</Title>
            <ToolbarWrapper>
                <Grid container>
                    {/* Row 1 */}
                    <Grid sx={{ pt: 0, pl: 0 }} item xs={4}>
                        <FormControl sx={{ width: '100%', paddingRight: '12px' }} variant="standard">
                            <LabelControl>Số điện thoại</LabelControl>
                            <Input onChange={handleChangeKeyword} value={keyword} sx={{ width: '100%', marginTop: '4px' }} placeholder="Tìm kiếm theo số điện thoại" />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Mức độ hài lòng</LabelControl>
                            <HSelect sx={{ marginTop: '4px' }} value={selectedCustomerRate}
                                onChange={handleChangeCustomerRate}>
                                <MenuItem key={-1} value={"-1"}>Tất cả</MenuItem>
                                {
                                    CUSTOMER_FEEDBACK_DATA.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                                }
                            </HSelect>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl sx={{ paddingLeft: '12px' }} variant="standard">
                            <LabelControl>Trạng thái cập nhật Feedback</LabelControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={radioValue}
                                onChange={handleChangeRadio}
                            >
                                <FormControlLabel value="-1" control={<CustomRadio />} label={<RadioLabel>Tất cả</RadioLabel>} />
                                <FormControlLabel value="1" control={<CustomRadio />} label={<RadioLabel>Đã cập nhật</RadioLabel>} />
                                <FormControlLabel value="0" control={<CustomRadio />} label={<RadioLabel>Chưa cập nhật</RadioLabel>} />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {/* Row 2 */}
                    <Grid sx={{ marginTop: '16px' }} item xs={4}>
                        <FormControl sx={{ width: '100%', paddingRight: '12px' }} variant="standard">
                            <LabelControl>Từ ngày</LabelControl>
                            <HDatePicker
                                value={fromDate}
                                ampm={false}
                                inputFormat="DD/MM/YYYY"
                                onChange={(newValue: any) => {
                                    setFromDate(newValue);
                                }}>
                            </HDatePicker>
                        </FormControl>
                    </Grid>
                    <Grid sx={{ marginTop: '16px' }} item xs={4}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Đến ngày</LabelControl>
                            <HDatePicker
                                value={toDate}
                                inputFormat="DD/MM/YYYY"
                                placeholder="Từ ngày"
                                ampm={false}
                                onChange={(newValue: any) => {
                                    setToDate(newValue);
                                }}>
                            </HDatePicker>
                        </FormControl>
                    </Grid>
                    <Grid sx={{ marginTop: '16px' }} item xs={4}>
                        <FormControl fullWidth sx={{ paddingLeft: '12px' }} variant="standard">
                            <LabelControl>Cơ sở</LabelControl>
                            <HSelect fullWidth value={selectedLocation}
                                onChange={handleChangeLocation}>
                                <MenuItem key={-1} value={"-1"}>Tất cả</MenuItem>
                                {
                                    spaLocations.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                                }
                            </HSelect>
                        </FormControl>
                    </Grid>
                </Grid>
            </ToolbarWrapper>
            <HTable
                columns={columns}
                pagingInfo={{
                    rowsPerPage,
                    page,
                    totalCount: paginatedData.TotalCount,
                    handleChangePage,
                    handleChangeRowsPerPage
                }}
                loading={tableLoading}
                listData={paginatedData.ListOut}
                onRowHandler={onHandler}
            ></HTable>
            <Dialog PaperProps={{
                sx: {
                    width: "450px",
                }
            }} open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>
                    <HDialogTitle>
                        Cập nhật Feedback
                    </HDialogTitle>
                </DialogTitle>
                <DialogContent>
                    <div style={{ fontSize: '14px', color: COLORS.OldSilver }}>
                        Thông tin Feedback đã có, bạn có muốn tiếp tục cập nhật?
                    </div>
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 2 }} >
                    <HButton startIcon={<CloseOutlinedIcon />} btnType="secondary" onClick={handleCloseDialog}>Hủy</HButton>
                    <HButton startIcon={<DoneOutlinedIcon />} onClick={goToDetail}>Tiếp tục</HButton>
                </DialogActions>
            </Dialog>
        </FeedbackWrapper>
    );
}