import { Suspense, useEffect, useLayoutEffect, useState } from "react";
import { Route, Router, Routes, Navigate, Outlet } from "react-router-dom";
import { Main } from '@pages/main'
import NotFound from "@pages/notFound";
import Forbidden from "@pages/forbidden";
import { Booking } from "@pages/booking";
import { Feedback } from "@pages/feedback";
import { Login } from "@pages/auth/Login";
import { UserInfo } from "@pages/user/userInfo";
import { routes } from "@shared/constants/routeNames"
import { useAppSelector } from "@hooks/useSelector/useAppSelector";
import { TreatmentSessionDetail } from "@pages/feedback/treatmentSessionDetail";
import { BookingDetail } from "@pages/booking/bookingDetail";
import { systemRole } from "@shared/constants/common";
import { BookingManagement } from "@pages/booking/bookingManagement";
import { TreatmentManagement } from "@pages/treatment/treatmentManagement";
import { BookingCreation } from "@pages/booking/bookingCreation";
import { PaymentUpdate } from "@pages/payment/paymentUpdate";
import { PaymentList } from "@pages/payment/paymentList";
import { PaymentServiceDetail } from "@pages/payment/paymentServiceDetail";
import { AllUserAdministration } from "@pages/administration/account/allUser";
import { CustomerAdministration } from "@pages/administration/account/customer";
import { CustomerDetailAdministration } from "@pages/administration/account/customerDetail";
import { LocationAdministration } from "@pages/administration/masterData/location";
import { ServiceAdministration } from "@pages/administration/masterData/service";
import { ServiceTypeAdministration } from "@pages/administration/masterData/serviceType";
import { ProductAdministration } from "@pages/administration/masterData/product";
import { ProductTypeAdministration } from "@pages/administration/masterData/productType";
import { BuyingProductAdministration } from "@pages/administration/business/buyingProduct";
import { BuyingServiceAdministration } from "@pages/administration/business/buyingService";
import { PaymentAdministration } from "@pages/administration/business/payment";
import { ComplainAdministration } from "@pages/administration/feedback/complain";
import { CustomerFeedbackAdministration } from "@pages/administration/feedback/customerFeedback";
import { EmailAdministration } from "@pages/administration/postAndNotification/emailAdministration";
import { ArticleAdministration } from "@pages/administration/postAndNotification/article";
import { BuyingProductPayment as PaymentBuyingProduct } from "@pages/payment/product";
import { PaymentBuyingService } from "@pages/payment/paymentBuyingService";
import { SmsAdministration } from "@pages/administration/postAndNotification/sms";
import { NotificationAdministration } from "@pages/administration/postAndNotification/notification";
import { CustomerAccountCreation } from "@pages/user/customerAccountCreation";
import { SwtichServiceUser } from "@pages/payment/switchServiceUser";
import { RevenueDashboardAdministration } from "@pages/administration/dashboard/revenue";
import { DebtDashboardAdministration } from "@pages/administration/dashboard/debt";
import { UserStatisticDashboardAdministration } from "@pages/administration/dashboard/userStatistic";
import { RestoreAccountAdministration } from "@pages/administration/account/restoreAccount";
import { BillCreation } from "@pages/payment/billCreation";

const ProtectedRoute = ({
    token,
    redirectPath = routes.LOGIN,
    children,
}: any) => {
    if (!token) {
        return <Navigate to={redirectPath} replace />;
    }

    return children ? children : <Outlet />;
};

export const AppRouter = ({ history, ...props }: any) => {
    var { accessToken, tokenClaims } = useAppSelector(state => state.auth);
    const [defaultRoute, setDefaultRoute] = useState('/');

    useEffect(() => {
        switch (tokenClaims.role) {
            case systemRole.CUSTOMER_CARE:
                setDefaultRoute(routes.FEEDBACK);
                break;
            case systemRole.RECEPTIONIST:
                setDefaultRoute(routes.BOOKING_MANAGEMENT);
                break;
            case systemRole.ACCOUNTANT:
                setDefaultRoute(routes.BOOKING_CREATION);
                break;
            case systemRole.BRANCH_ACCOUNTANT:
                setDefaultRoute(routes.PAYMENT_BILL_CREATION);
                break;
            case systemRole.MARKETING:
                setDefaultRoute(routes.SMS_ADMINISTRATION);
                break;
            case systemRole.BRANCH_MANAGER:
            case systemRole.ADMIN:
                setDefaultRoute(routes.REVENUE_DASHBOARD_ADMINISTRATION);
                break;
            default:
                if (!accessToken) {
                    setDefaultRoute(routes.LOGIN);
                }
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenClaims])

    return (
        <CustomRouter history={history}>
            <Suspense fallback={null}>
                <Routes>
                    <Route path={routes.LOGIN} element={<Login />}></Route>
                    <Route path='/' element={<Navigate to={defaultRoute} />}></Route>
                    {/* Route cho chăm sóc khách hàng */}
                    {
                        tokenClaims.role === systemRole.CUSTOMER_CARE &&
                        <Route element={<ProtectedRoute token={accessToken} children={<Main />}></ProtectedRoute>}>
                            <Route path={routes.FEEDBACK} element={<Feedback />}></Route>
                            <Route path={routes.TREATMENT_SESSION} element={<TreatmentSessionDetail />}></Route>
                            <Route path={routes.BOOKING} element={<Booking />}></Route>
                            <Route path={routes.COMPLAIN} element={<ComplainAdministration />}></Route>
                            <Route path={routes.BOOKING_DETAIL} element={<BookingDetail />}></Route>
                        </Route>
                    }
                    {/* Route chung khi không có role nào */}
                    {
                        (!tokenClaims.role) &&
                        // <Route element={<ProtectedRoute token={accessToken} children={<Main />}></ProtectedRoute>}></Route>
                        <Route path={routes.LOGIN} element={<Login />}></Route>
                    }
                    {/* Route cho role Lễ Tân */}
                    {
                        tokenClaims.role === systemRole.RECEPTIONIST &&
                        <Route element={<ProtectedRoute token={accessToken} children={<Main />}></ProtectedRoute>}>
                            <Route path={routes.BOOKING_CREATION} element={<BookingCreation />}></Route>
                            <Route path={routes.BOOKING_MANAGEMENT} element={<BookingManagement />}></Route>
                            <Route path={routes.TREATMENT_MANAGEMENT} element={<TreatmentManagement />}></Route>
                        </Route>
                    }
                    {/* Route cho role Kế toán tổng */}
                    {/* Route cho role Kế toán cơ sở */}
                    {
                        (tokenClaims.role === systemRole.BRANCH_ACCOUNTANT || tokenClaims.role === systemRole.ACCOUNTANT) &&
                        <Route element={<ProtectedRoute token={accessToken} children={<Main />}></ProtectedRoute>}>
                            <Route path={routes.PAYMENT_BILL_CREATION} element={<BillCreation />}></Route>
                            <Route path={routes.BOOKING_CREATION} element={<BookingCreation />}></Route>
                            <Route path={routes.BOOKING_MANAGEMENT} element={<BookingManagement />}></Route>
                            <Route path={routes.TREATMENT_MANAGEMENT} element={<TreatmentManagement />}></Route>
                            <Route path={routes.PAYMENT_DETAIL} element={<PaymentServiceDetail />}></Route>
                            <Route path={routes.PAYMENT_UPDATE} element={<PaymentUpdate />}></Route>
                            <Route path={routes.PAYMENT_LIST} element={<PaymentList />}></Route>
                            <Route path={routes.PAYMENT_BUYING_PRODUCT} element={<PaymentBuyingProduct />}></Route>
                            <Route path={routes.PAYMENT_BUYING_SERVICE} element={<PaymentBuyingService />}></Route>
                            <Route path={routes.CUSTOMER_ACCOUNT_CREATION} element={<CustomerAccountCreation />}></Route>
                            <Route path={routes.PAYMENT_SWITCH_BUYING_SERVICE_USER} element={<SwtichServiceUser />}></Route>
                        </Route>
                    }
                    {/* Route cho role Giám đốc cơ sở */}
                    {
                        tokenClaims.role === systemRole.BRANCH_MANAGER &&
                        <Route element={<ProtectedRoute token={accessToken} children={<Main />}></ProtectedRoute>}>
                            <Route path={routes.REVENUE_DASHBOARD_ADMINISTRATION} element={<RevenueDashboardAdministration />}></Route>
                            <Route path={routes.DEBT_DASHBOARD_ADMINISTRATION} element={<DebtDashboardAdministration />}></Route>
                            <Route path={routes.USER_STATISTIC_DASHBOARD_ADMINISTRATION} element={<UserStatisticDashboardAdministration />}></Route>
                            <Route path={routes.ALL_USER_ADMINISTRATION} element={<AllUserAdministration />}></Route>
                            <Route path={routes.CUSTOMER_ADMINISTRATION} element={<CustomerAdministration />}></Route>
                            <Route path={routes.CUSTOMER_DETAIL_ADMINISTRATION} element={<CustomerDetailAdministration />}></Route>
                            <Route path={routes.RESTORE_ACCOUNT_ADMINISTRATION} element={<RestoreAccountAdministration />}></Route>
                            <Route path={routes.SERVICE_ADMINISTRATION} element={<ServiceAdministration />}></Route>
                            <Route path={routes.SERVICE_TYPE_ADMINISTRATION} element={<ServiceTypeAdministration />}></Route>
                            <Route path={routes.PRODUCT_ADMINISTRATION} element={<ProductAdministration />}></Route>
                            <Route path={routes.PRODUCT_TYPE_ADMINISTRATION} element={<ProductTypeAdministration />}></Route>
                            <Route path={routes.BUYING_PRODUCT_ADMINISTRATION} element={<BuyingProductAdministration />}></Route>
                            <Route path={routes.BUYING_SERVICE_ADMINISTRATION} element={<BuyingServiceAdministration />}></Route>
                            <Route path={routes.PAYMENT_ADMINISTRATION} element={<PaymentAdministration />}></Route>
                            <Route path={routes.COMPLAIN_ADMINISTRATION} element={<ComplainAdministration />}></Route>
                            <Route path={routes.FEEDBACK_ADMINISTRATION} element={<CustomerFeedbackAdministration />}></Route>
                        </Route>
                    }
                    {/* Route cho role Admin */}
                    {
                        tokenClaims.role === systemRole.ADMIN &&
                        <Route element={<ProtectedRoute token={accessToken} children={<Main />}></ProtectedRoute>}>
                            <Route path={routes.REVENUE_DASHBOARD_ADMINISTRATION} element={<RevenueDashboardAdministration />}></Route>
                            <Route path={routes.DEBT_DASHBOARD_ADMINISTRATION} element={<DebtDashboardAdministration />}></Route>
                            <Route path={routes.USER_STATISTIC_DASHBOARD_ADMINISTRATION} element={<UserStatisticDashboardAdministration />}></Route>
                            <Route path={routes.ALL_USER_ADMINISTRATION} element={<AllUserAdministration />}></Route>
                            <Route path={routes.CUSTOMER_ADMINISTRATION} element={<CustomerAdministration />}></Route>
                            <Route path={routes.RESTORE_ACCOUNT_ADMINISTRATION} element={<RestoreAccountAdministration />}></Route>
                            <Route path={routes.CUSTOMER_DETAIL_ADMINISTRATION} element={<CustomerDetailAdministration />}></Route>
                            <Route path={routes.LOCATION_ADMINISTRATION} element={<LocationAdministration />}></Route>
                            <Route path={routes.SERVICE_ADMINISTRATION} element={<ServiceAdministration />}></Route>
                            <Route path={routes.SERVICE_TYPE_ADMINISTRATION} element={<ServiceTypeAdministration />}></Route>
                            <Route path={routes.PRODUCT_ADMINISTRATION} element={<ProductAdministration />}></Route>
                            <Route path={routes.PRODUCT_TYPE_ADMINISTRATION} element={<ProductTypeAdministration />}></Route>
                            <Route path={routes.BUYING_PRODUCT_ADMINISTRATION} element={<BuyingProductAdministration />}></Route>
                            <Route path={routes.BUYING_SERVICE_ADMINISTRATION} element={<BuyingServiceAdministration />}></Route>
                            <Route path={routes.PAYMENT_ADMINISTRATION} element={<PaymentAdministration />}></Route>
                            <Route path={routes.COMPLAIN_ADMINISTRATION} element={<ComplainAdministration />}></Route>
                            <Route path={routes.FEEDBACK_ADMINISTRATION} element={<CustomerFeedbackAdministration />}></Route>
                            <Route path={routes.EMAIL_ADMINISTRATION} element={<EmailAdministration />}></Route>
                            <Route path={routes.SMS_ADMINISTRATION} element={<SmsAdministration />}></Route>
                            <Route path={routes.NOTIFICATION_ADMINISTRATION} element={<NotificationAdministration />}></Route>
                            <Route path={routes.ARTICLE_ADMINISTRATION} element={<ArticleAdministration />}></Route>
                        </Route>
                    }
                    {/* Route cho role Marketing */}
                    {
                        (tokenClaims.role === systemRole.MARKETING) &&
                        <Route element={<ProtectedRoute token={accessToken} children={<Main />}></ProtectedRoute>}>
                            <Route path={routes.SMS_ADMINISTRATION} element={<SmsAdministration />}></Route>
                            <Route path={routes.NOTIFICATION_ADMINISTRATION} element={<NotificationAdministration />}></Route>
                            <Route path={routes.ARTICLE_ADMINISTRATION} element={<ArticleAdministration />}></Route>
                        </Route>
                    }
                    <Route path="/user-info" element={<ProtectedRoute token={accessToken} children={<UserInfo />}></ProtectedRoute>}></Route>
                    <Route path='*' element={<NotFound />} />
                    <Route path={routes.FORBIDDEN} element={<Forbidden />} />
                </Routes>
            </Suspense>
        </CustomRouter>
    )
}

const CustomRouter = ({ history, ...props }: any) => {
    const [state, setState] = useState({
        action: history.action,
        location: history.location,
    });

    useLayoutEffect(() => history.listen(setState), [history]);

    return <Router {...props} location={state.location} navigationType={state.action} navigator={history} />;
};