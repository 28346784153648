import { HButton } from "@components/Button";
import { HDatePicker } from "@components/DateTimePicker";
import { HSelect } from "@components/Select";
import { ApiConfig } from "@config/index";
import { FormControl, Grid, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { apiClient } from "@services/baseAxios";
import { COLORS } from "@shared/constants/colors";
import { MONTH_DATA } from "@shared/constants/common";
import { LabelControl, Title } from "@shared/styledComponents";
import { storeSetAllRole } from "@store/directoryReducer";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RefreshIcon from '@mui/icons-material/Refresh';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { HTextArea } from "@components/Input/HTextArea";
import { Input } from "@components/Input";
import { storeOpenSnackbar, storeSetGlobalLoading } from "@store/globalReducer";
import { errorMessages } from "@shared/constants/messages";
import { HDialog } from "@components/Dialog";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';

const TopSection = styled('div')(() => ({
    width: '100%',
    backgroundColor: COLORS.GhostWhite,
    padding: '16px',
    display: 'flex',
    flexDirection: 'column'
}));

const BottomSection = styled('div')(() => ({
    width: '100%',
    backgroundColor: COLORS.White,
    padding: '16px',
    paddingTop: '32px',
    display: 'flex',
    flexDirection: 'column'
}));

const ToolbarWrapper = styled('div')(() => ({
    paddingTop: "16px"
}));

export const NotificationAdministration = () => {
    const dispatch = useDispatch();
    const { allRole } = useSelector((state: any) => state.directory);

    const [content, setContent] = useState('');
    const [fromDate, setFromDate] = useState<Dayjs | null>(null);
    const [toDate, setToDate] = useState<Dayjs | null>(null);
    const [articleId, setArticleId] = useState("");
    const [selectedBirthMonth, setSelectedBirthMonth] = useState('-1');
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [numberSendedCustomer, setNumberSendedCustomer] = useState(0);

    const getAllRole = async () => {
        if (allRole?.length > 0) return;

        const { AppCode, Data } = await apiClient.get(ApiConfig.GET_ALL_ROLE);
        if (AppCode === 200) {
            dispatch(storeSetAllRole(Data));
        }
        else {
            dispatch(storeSetAllRole([]));
        }
    }

    const handleChangeBirthMonth = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedBirthMonth(event.target.value);
    };

    const handleOnRefresh = () => {
        setContent('');
        setArticleId('');
    }

    const getCustomerFilterObject = () => {
        var fromDateVNTime = dayjs(fromDate).add(7, 'hours') || null;
        var toDateVNTime = dayjs(toDate).add(7, 'hours') || null;

        return {
            MonthOfBirth: selectedBirthMonth,
            FromDate: fromDateVNTime,
            ToDate: toDateVNTime,
        }
    }

    const checkCustomerNumberBeforeSending = async () => {
        if (!articleId || !content) {
            dispatch(storeOpenSnackbar({ open: true, message: "Vui lòng nhập đủ thông tin", type: "warning" }));
            return;
        }

        var customerFilterObj = getCustomerFilterObject();

        try {
            dispatch(storeSetGlobalLoading(true));
            var { AppCode, Data } = await apiClient.post(`${ApiConfig.NOTIFICATION_ADMINISTRATION}/customer-count-by-filter`, customerFilterObj);
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                setNumberSendedCustomer(Data);
                openDialog();
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        } catch (error) {
            dispatch(storeSetGlobalLoading(false));
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    const handleSend = async () => {
        if (!articleId || !content) {
            dispatch(storeOpenSnackbar({ open: true, message: "Vui lòng nhập đủ thông tin", type: "warning" }));
            return;
        }

        var obj = {
            CustomerFilter: getCustomerFilterObject(),
            ArticleId: parseInt(articleId),
            NotificationContent: content
        }

        try {
            dispatch(storeSetGlobalLoading(true));
            var { AppCode } = await apiClient.post(`${ApiConfig.NOTIFICATION_ADMINISTRATION}/article`, obj);
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                dispatch(storeOpenSnackbar({ open: true, message: "Gửi thông báo thành công", type: "success" }));
                closeDialog();
            }
            else if (AppCode === 404) {
                dispatch(storeOpenSnackbar({ open: true, message: "Id của bài viết không chính xác", type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: "Gửi thông báo thất bại", type: "error" }));
            }
        } catch (error) {
            dispatch(storeSetGlobalLoading(false));
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    const changeContent = (event: any) => {
        setContent(event.target.value);
    }

    const changeArticleId = (event: any) => {
        setArticleId(event.target.value);
    }

    const openDialog = () => {
        setIsOpenDialog(true);
    }

    const closeDialog = () => {
        setIsOpenDialog(false);
    }

    useEffect(() => {
        getAllRole();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <TopSection>
                <Title>Tạo thông báo khuyến mãi cho khách hàng</Title>
                <ToolbarWrapper>
                    <Grid container>
                        <Grid sx={{ paddingRight: '8px' }} item xs={4}>
                            <FormControl sx={{ width: '100%' }} variant="standard">
                                <LabelControl>Tháng sinh nhật</LabelControl>
                                <HSelect value={selectedBirthMonth || "-1"}
                                    onChange={handleChangeBirthMonth}>
                                    <MenuItem key={-1} value={"-1"}>Tất cả</MenuItem>
                                    {
                                        MONTH_DATA.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                                    }
                                </HSelect>
                            </FormControl>
                        </Grid>
                        <Grid sx={{ paddingRight: '8px' }} item xs={4}>
                            <FormControl sx={{ width: '100%' }} variant="standard">
                                <LabelControl>Từ ngày</LabelControl>
                                <HDatePicker
                                    value={fromDate}
                                    ampm={false}
                                    inputFormat="DD/MM/YYYY"
                                    onChange={(newValue: any) => {
                                        setFromDate(newValue);
                                    }}>
                                </HDatePicker>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl sx={{ width: '100%' }} variant="standard">
                                <LabelControl>Đến ngày</LabelControl>
                                <HDatePicker
                                    value={toDate}
                                    minDate={fromDate}
                                    placeholder="Từ ngày"
                                    inputFormat="DD/MM/YYYY"
                                    ampm={false}
                                    onChange={(newValue: any) => {
                                        setToDate(newValue);
                                    }}>
                                </HDatePicker>
                            </FormControl>
                        </Grid>
                    </Grid>
                </ToolbarWrapper>
            </TopSection>
            <BottomSection>
                <Grid container>
                    <Grid sx={{ pt: 0, pl: 0, marginBottom: '16px' }} item xs={12}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl required>Nhập Id bài viết</LabelControl>
                            <Input onChange={changeArticleId} type="number" value={articleId || ""} sx={{ width: '100%', marginTop: '4px' }} placeholder="Nhập Id bài viết" />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <LabelControl required>Nội dung</LabelControl>
                        <HTextArea
                            sx={{ width: '100%' }}
                            value={content || ''}
                            onChange={changeContent}
                            // maxRows={3}
                            aria-label="maximum height"
                            placeholder="Nhập nội dung"
                        />
                    </Grid>
                    <Grid sx={{ marginTop: '16px' }} item xs={12}>
                        <div className="flex justify-end">
                            <HButton startIcon={<RefreshIcon />} btnType="secondary" onClick={handleOnRefresh}>Làm mới</HButton>
                            <HButton sx={{ marginLeft: '16px' }} startIcon={<SendOutlinedIcon />} onClick={checkCustomerNumberBeforeSending}>Gửi</HButton>
                        </div>
                    </Grid>
                </Grid>
            </BottomSection>
            {/* Dialog */}
            <HDialog
                PaperProps={{
                    sx: {
                        width: "450px",
                    }
                }}
                title="Thông báo"
                open={isOpenDialog}
                handleClose={closeDialog}
                dialogFooter={
                    <>
                        <HButton startIcon={<CloseOutlinedIcon />} btnType="secondary" onClick={closeDialog}>Hủy</HButton>
                        <HButton startIcon={<DoneOutlinedIcon />} onClick={handleSend}>Xác nhận</HButton>
                    </>
                }
            >
                {
                    <div>Số lượng người nhận đang được chọn là <b>{numberSendedCustomer}</b>,&nbsp; xác nhận gửi?</div>
                }
            </HDialog>
        </>
    )
}